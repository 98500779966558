import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles/';

import AboutPage from './components/pages/AboutPage';
import AuthPage from './components/pages/AuthPage';
import CallbackPage from './components/pages/CallbackPage';
import ContactPage from './components/pages/ContactPage';
import DeviceHistoryPage from './components/pages/devices/DeviceHistoryPage';
import DeliveryBoxHistoryPage from './components/pages/devices/DeliveryBoxHistoryPage';
import DeliveryBoxAddPage from './components/pages/devices/DeliveryBoxAddPage';
import DeliveryBoxAddressAddPage from './components/pages/devices/DeliveryBoxAddressAddPage';
import DeliveryBoxAddressEditPage from './components/pages/devices/DeliveryBoxAddressEditPage';
import DeliveryBoxDetailPage from './components/pages/devices/DeliveryBoxDetailPage';
import DeliveryBoxEditPage from './components/pages/devices/DeliveryBoxEditPage';
import DevicePage from './components/pages/devices/DevicePage';
import FaqPage from './components/pages/FaqPage';
import LoginPage from './components/pages/LoginPage';
import LogoutPage from './components/pages/LogoutPage';
import NotFoundPage from './components/pages/NotFoundPage';
import NoticePage from './components/pages/NoticePage';
import PrivacyPage from './components/pages/PrivacyPage';
import DeliveryBoxShareDetailPage from './components/pages/services/DeliveryBoxShareDetailPage';
import DeliveryBoxShareHistoryPage from './components/pages/services/DeliveryBoxShareHistoryPage';
import DeliveryBoxSharePage from './components/pages/services/DeliveryBoxSharePage';
import DeliveryBoxShareUnlock from './components/pages/services/DeliveryBoxShareUnlockPage';
import DeliveryReservationDetailPage from './components/pages/services/DeliveryReservationDetailPage';
import DeliveryReservationHistoryPage from './components/pages/services/DeliveryReservationHistoryPage';
import DeliveryReservationPage from './components/pages/services/DeliveryReservationPage';
import DeliveryReservationSettingPage from './components/pages/services/DeliveryReservationSettingPage';
import PickupRequestDetailPage from './components/pages/services/PickupRequestDetailPage';
import PickupRequestHistoryPage from './components/pages/services/PickupRequestHistoryPage';
import PickupRequestIntroPage from './components/pages/services/PickupRequestIntroPage';
import PickupRequestPage from './components/pages/services/PickupRequestPage';
import DemoRentalServicePage from './components/pages/services/DemoRentalServicePage';
import DemoRentalServiceHistoryPage from './components/pages/services/DemoRentalServiceHistoryPage';
import ServicePage from './components/pages/services/ServicePage';
import NotificationSettingsPage from './components/pages/settings/NotificationSettingsPage';
import SettingPage from './components/pages/settings/SettingPage';
import TermsPage from './components/pages/TermsPage';
import TopPage from './components/pages/TopPage';
import theme from './theme';

const App: React.FC = () => {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Switch>
        <Route exact path="/login" component={LoginPage} />
        <Route exact path="/callback" component={CallbackPage} />
        <Route exact path="/logout" component={LogoutPage} />
        <Route exact path="/faq" component={FaqPage} />
        <Route exact path="/contact" component={ContactPage} />
        <Route exact path="/terms" component={TermsPage} />
        <Route exact path="/privacy" component={PrivacyPage} />
        <Route exact path="/notices" component={NoticePage} />
        <Route exact path="/settings" component={SettingPage} />
        <Route exact path="/about" component={AboutPage} />
        <Route
          exact
          path="/services/delivery-box-shares/:deliveryBoxShareId/unlock"
          component={DeliveryBoxShareUnlock}
        />
        <AuthPage>
          <Switch>
            <Route exact path="/services" component={ServicePage} />
            <Route
              exact
              path="/services/delivery-box-shares"
              component={DeliveryBoxSharePage}
            />
            <Route
              exact
              path="/services/delivery-box-shares/:deliveryBoxShareId/view"
              component={DeliveryBoxShareDetailPage}
            />
            <Route
              exact
              path="/services/delivery-box-shares/histories"
              component={DeliveryBoxShareHistoryPage}
            />
            <Route
              exact
              path="/services/pickup-requests-intro"
              component={PickupRequestIntroPage}
            />
            <Route
              exact
              path="/services/pickup-requests"
              component={PickupRequestPage}
            />
            <Route
              exact
              path="/services/pickup-requests/histories"
              component={PickupRequestHistoryPage}
            />
            <Route
              exact
              path="/services/pickup-requests/:pickupRequestId/view"
              component={PickupRequestDetailPage}
            />
            <Route
              exact
              path="/services/delivery-reservations"
              component={DeliveryReservationPage}
            />
            <Route
              exact
              path="/services/delivery-reservations/setting"
              component={DeliveryReservationSettingPage}
            />
            <Route
              exact
              path="/services/delivery-reservations/histories"
              component={DeliveryReservationHistoryPage}
            />
            <Route
              exact
              path="/services/delivery-reservations/:deliveryId/view"
              component={DeliveryReservationDetailPage}
            />
            <Route
              exact
              path="/services/demo-rental-service"
              component={DemoRentalServicePage}
            />
            <Route
              exact
              path="/services/demo-rental-service/histories"
              component={DemoRentalServiceHistoryPage}
            />
            <Route exact path="/devices" component={DevicePage} />
            <Route
              exact
              path={['/devices/delivery-boxes/add', '/signup']}
              component={DeliveryBoxAddPage}
            />
            <Route
              exact
              path="/devices/delivery-boxes/:deviceId"
              component={DeliveryBoxDetailPage}
            />
            <Route
              exact
              path="/devices/delivery-boxes/:deviceId/edit"
              component={DeliveryBoxEditPage}
            />
            <Route
              exact
              path="/devices/delivery-boxes/:deviceId/address/add"
              component={DeliveryBoxAddressAddPage}
            />
            <Route
              exact
              path="/devices/delivery-boxes/:deviceId/address/edit"
              component={DeliveryBoxAddressEditPage}
            />
            <Route
              exact
              path="/devices/histories"
              component={DeviceHistoryPage}
            />
            <Route
              exact
              path="/devices/histories/delivery-boxes/:deviceId"
              component={DeliveryBoxHistoryPage}
            />
            <Route
              exact
              path="/settings/notifications"
              component={NotificationSettingsPage}
            />
            <Route exact path="/" component={TopPage} />
            <Route path="*" component={NotFoundPage} />
          </Switch>
        </AuthPage>
      </Switch>
    </ThemeProvider>
  );
};

const WrapApp: React.FC = () => {
  return (
    <Router>
      <App />
    </Router>
  );
};

export default WrapApp;
