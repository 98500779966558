import { useState, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 集荷依頼の状態
 *
 * @returns requested: 申し込み済み
 * @returns requestCanceled: 申し込みキャンセル
 * @returns approved: 受付済み
 * @returns approveRejected: 受付キャンセル
 * @returns pickedUp: 集荷済み
 * @returns delivering: 配達中
 * @returns delivered: 配達済み
 */
export type PickupRequestStatus =
  | 'requested'
  | 'requestCanceled'
  | 'approved'
  | 'approveRejected'
  | 'pickedup'
  | 'delivering'
  | 'delivered'
  | 'unknown';

export interface PickupRequest {
  pickupRequestId: string;
  deviceId: string;
  fullName: string;
  postalCode: string;
  state: string;
  address1: string;
  address2: string;
  building: string;
  phoneNumber: string;
  preferredDate: string;
  preferredTimeZone: string;
  memo: string;
  status: PickupRequestStatus;
  cancelAt: number;
  updateAt: number;
  createAt: number;
}

export const useGetPickupRequest = (): {
  getData: (pickupRequestId: string) => Promise<void>;
  data: PickupRequest;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [data, setData] = useState<PickupRequest>({
    pickupRequestId: '',
    deviceId: '',
    fullName: '',
    postalCode: '',
    state: '',
    address1: '',
    address2: '',
    building: '',
    phoneNumber: '',
    preferredDate: '',
    preferredTimeZone: '',
    memo: '',
    status: 'unknown',
    cancelAt: 0,
    updateAt: 0,
    createAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getData = useCallback(async (pickupRequestId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/pickup-requests/${pickupRequestId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);

      if (e.response.status === 404) {
        setErrorMessage('お申し込み詳細情報が取得出来ませんでした。');
      } else {
        setErrorMessage('原因不明のエラーが発生しました。');
      }
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError, errorMessage };
};
