import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';

interface DeliveryBoxDeleteDialogProps {
  handleCloseDeleteDialog: () => void;
  handleDelete: (deviceId: string) => void;
  deviceId: string;
  openDeleteDialog: boolean;
}

const DeliveryBoxDeleteDialog: React.FC<DeliveryBoxDeleteDialogProps> = ({
  handleCloseDeleteDialog,
  handleDelete,
  deviceId,
  openDeleteDialog,
}) => {
  return (
    <Dialog
      onClose={handleCloseDeleteDialog}
      fullWidth
      aria-labelledby="dialog-title"
      open={openDeleteDialog}
    >
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" component="p" color="primary">
          宅配ボックスを削除します
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography>現在ご登録の宅配ボックスはすぐに削除されます。</Typography>
        <Typography gutterBottom>
          この操作を取り消すことはできません。
          宅配ボックスの削除に成功すると、ホーム画面を表示します。
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => handleDelete(deviceId)}>
          <Typography color="secondary">削除する</Typography>
        </Button>
      </DialogActions>
      <DialogActions>
        <Button onClick={handleCloseDeleteDialog}>
          <Typography>キャンセル</Typography>
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryBoxDeleteDialog;
