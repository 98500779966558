import { useState } from 'react';
import Axios, { AxiosResponse } from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

interface DeliveryBoxAuthPasswordResponse {
  password: string;
}

export const useDeliveryBoxAuthPassword = (): {
  getAuthPassword: (hubMacAddress: string) => void;
  data: DeliveryBoxAuthPasswordResponse;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<DeliveryBoxAuthPasswordResponse>({
    password: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  const getAuthPassword = (hubMacAddress: string) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();
        const response: AxiosResponse<DeliveryBoxAuthPasswordResponse> =
          await Axios.post(
            `${REACT_APP_API_ENDPOINT}/delivery-box-auth-password`,
            {
              hubMacAddress: hubMacAddress,
            },
            {
              headers: {
                'Content-Type': 'application/json',
                Authorization: `Bearer ${idToken}`,
              },
            },
          );

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { getAuthPassword, data, isCompleted, isLoading, isError };
};
