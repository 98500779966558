import Axios from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

interface InputValues {
  deviceId: string;
  sendAddress: string;
  reserved: boolean;
}

export const useUpdateSagawa = (): {
  handleSubmit: (values: InputValues) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = useCallback(async (values: InputValues) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      await Axios.patch(
        `${REACT_APP_API_ENDPOINT}/sagawa/${values.deviceId}`,
        {
          sendAddress: values.sendAddress,
          reserved: values.reserved,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setIsCompleted(true);
    } catch (e) {
      if (e.response && e.response.status === 400) {
        setErrorMessage('不正なリクエストです。');
      } else {
        setErrorMessage('原因不明のエラーが発生しました。');
      }

      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { handleSubmit, isCompleted, isLoading, isError, errorMessage };
};
