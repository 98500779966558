import { useFormik, FormikProps } from 'formik';
import { DateTime } from 'luxon';
import React, { useState, useEffect } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import AccessTime from '@material-ui/icons/AccessTime';
import CalendarToday from '@material-ui/icons/CalendarToday';
import LaunchIcon from '@material-ui/icons/Launch';
import MuiAlert from '@material-ui/lab/Alert';

import {
  PickupRequest,
  useCreatePickupRequest,
} from '../../../hooks/useCreatePickupRequest';
import { useGetUser } from '../../../hooks/useGetUser';
import { useListDevice } from '../../../hooks/useListDevice';
import FormTextField from '../../molecules/FormTextField';
import MessageBox from '../../molecules/MessageBox';
import PickupRequestServiceHeader from '../../organisms/PickupRequestServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    bold: {
      fontWeight: 'bold',
    },
    storeInformation: {
      marginBottom: theme.spacing(2),
    },
    inputCalendarItemText: {
      color: theme.palette.text.secondary,
      textAlign: 'center',
      borderRadius: '5px',
      border: `1px solid ${theme.palette.text.secondary}`,
      cursor: 'pointer',
    },
    inputCalendarItem: {
      display: 'none',
      '&:checked + $inputCalendarItemText': {
        color: theme.palette.primary.main,
        border: `1px solid ${theme.palette.primary.main}`,
      },
      '&:disabled + $inputCalendarItemText': {
        cursor: 'default',
        color: theme.palette.text.disabled,
        border: `1px dashed ${theme.palette.text.disabled}`,
      },
    },
    preferredDateTimeIcon: {
      color: theme.palette.text.secondary,
      fontSize: theme.typography.h3.fontSize,
      marginRight: theme.spacing(3.5),
    },
    preferredDateTimeLabel: {
      marginTop: theme.spacing(2),
    },
    preferredTimeZoneItem: {
      width: '33.33333%',
      '& $inputCalendarItemText': {
        padding: theme.spacing(0.5, 0),
      },
    },
    button: {
      marginTop: theme.spacing(1),
    },
    icon: {
      verticalAlign: 'bottom',
      marginLeft: '2px',
    },
  }),
);

const validateRules = (values: PickupRequest) => {
  const errors: {
    [key: string]: string;
  } = {};

  if (!values.fullName) {
    errors.fullName = '必須項目です。';
  }

  return errors;
};

interface CalendarListItemProps {
  name: string;
  value: string;
  onChange: (
    eventOrPath: string | React.ChangeEvent<HTMLInputElement>,
  ) =>
    | void
    | ((
        eventOrTextValue: string | React.ChangeEvent<HTMLInputElement>,
      ) => void);
  checked: boolean;
  disabled?: boolean;
  text: React.ReactElement;
  className?: string;
}

const CalendarListItem: React.FC<CalendarListItemProps> = ({
  name,
  value,
  onChange,
  checked,
  disabled,
  text,
  className,
}) => {
  const classes = useStyles();

  return (
    <Grid item component="label" className={className}>
      <input
        type="radio"
        name={name}
        value={value}
        onChange={onChange}
        checked={checked}
        disabled={disabled}
        className={classes.inputCalendarItem}
      />
      <Box px={1.5} py={0.5} className={classes.inputCalendarItemText}>
        {text}
      </Box>
    </Grid>
  );
};

interface Form {
  formikProps: FormikProps<PickupRequest>;
  preferredDates: DateTime[];
  preferredTimeZone: { [key: string]: string };
  canUseCleaning: boolean;
}

const Form: React.FC<Form> = ({
  formikProps,
  preferredDates,
  preferredTimeZone,
  canUseCleaning,
}) => {
  const classes = useStyles();

  return (
    <form onSubmit={formikProps.handleSubmit}>
      <Typography
        component="h3"
        variant="subtitle1"
        color="textSecondary"
        className={classes.bold}
      >
        店舗情報
      </Typography>
      <List className={classes.storeInformation}>
        <ListItem disableGutters dense>
          店名：あずけるBOXクリーニング店
        </ListItem>
        <ListItem disableGutters dense>
          住所：東京都江東区大島2-1-1
        </ListItem>
        <ListItem disableGutters dense>
          電話：000-0000-0000
        </ListItem>
        <ListItem disableGutters dense>
          問合時間：10:00～17:00
        </ListItem>
      </List>
      <FormTextField
        value={formikProps.values.fullName}
        error={!!formikProps.errors.fullName}
        label="ご注文者のお名前"
        name="fullName"
        onBlur={formikProps.handleBlur}
        onChange={formikProps.handleChange}
        placeholder="お名前を入力してください"
        helperText={formikProps.errors.fullName}
      />
      <Typography
        component="p"
        variant="subtitle2"
        color="textSecondary"
        gutterBottom
        className={`${classes.bold} ${classes.preferredDateTimeLabel}`}
      >
        回収希望日時
      </Typography>
      <Box mt={2} display="flex" alignItems="center">
        <CalendarToday className={classes.preferredDateTimeIcon} />
        <Grid container direction="row" alignItems="center" spacing={2}>
          {preferredDates.map((preferredDate, index) => {
            const value = preferredDate.toFormat('yyyy-MM-dd');

            return (
              <CalendarListItem
                key={index}
                name="preferredDate"
                value={value}
                onChange={formikProps.handleChange}
                checked={formikProps.values.preferredDate === value}
                disabled={
                  preferredDate.weekdayShort === 'Wed' ||
                  preferredDate.weekdayShort === 'Thu'
                }
                text={
                  <>
                    {preferredDate.weekdayShort}
                    <br />
                    {preferredDate.day}
                    <br />
                    {preferredDate.monthShort}
                  </>
                }
              />
            );
          })}
        </Grid>
      </Box>
      <Box mt={2} display="flex" alignItems="center">
        <AccessTime className={classes.preferredDateTimeIcon} />
        <Grid container direction="row" alignItems="center" spacing={1}>
          {Object.keys(preferredTimeZone).map((key, index) => {
            return (
              <CalendarListItem
                key={index}
                name="preferredTimeZone"
                value={preferredTimeZone[key]}
                onChange={formikProps.handleChange}
                checked={
                  formikProps.values.preferredTimeZone ===
                  preferredTimeZone[key]
                }
                text={
                  <>
                    {key.charAt(0).toUpperCase() +
                      key.substring(1).toLowerCase()}
                    <br />
                    {preferredTimeZone[key]}
                  </>
                }
                className={classes.preferredTimeZoneItem}
              />
            );
          })}
        </Grid>
      </Box>
      <Box mt={2}>
        <FormTextField
          defaultValue={formikProps.values.memo}
          error={!!formikProps.errors.memo && !!formikProps.touched.memo}
          label="お申し込み内容"
          name="memo"
          onBlur={formikProps.handleBlur}
          onChange={formikProps.handleChange}
          placeholder="未設定"
          readOnly
          multiline
        />
      </Box>
      <Button
        className={`${classes.bold} ${classes.button}`}
        color="primary"
        fullWidth
        disabled={!canUseCleaning}
        variant="contained"
        type="submit"
      >
        お申し込み
      </Button>
      <Button
        className={`${classes.bold} ${classes.button}`}
        fullWidth
        variant="contained"
        component={RouterLink}
        to="/services/pickup-requests-intro"
      >
        キャンセル
      </Button>
    </form>
  );
};

const PickupRequestPage: React.FC = () => {
  const classes = useStyles();

  const listDevice = useListDevice();
  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const [canUseCleaning, setCanUseCleaning] = useState(false);

  useEffect(() => {
    if (getUser.data.scopes.includes('members_site_use_cleaning')) {
      setCanUseCleaning(true);
    }
  }, [getUser]);

  const preferredDates = [...Array(7)].map((_, i) => {
    const now = DateTime.local().setLocale('en-GB');
    return now.plus({ days: i });
  });

  const preferredTimeZone: { [key: string]: string } = {
    morning: '9-12',
    afternoon: '12-16',
    evening: '16-20',
  };

  const createPickupRequest = useCreatePickupRequest();

  const formik = useFormik<PickupRequest>({
    initialValues: {
      deviceId: listDevice.data.length > 0 ? listDevice.data[0].deviceId : '',
      fullName:
        listDevice.data.length > 0 &&
        listDevice.data[0].lastName &&
        listDevice.data[0].firstName
          ? listDevice.data[0].lastName + listDevice.data[0].firstName
          : '',
      preferredDate: preferredDates
        .find(
          (el) => !(el.weekdayShort === 'Wed' || el.weekdayShort === 'Thu'),
        )!
        .toFormat('yyyy-MM-dd'),
      preferredTimeZone: preferredTimeZone.morning,
      memo: '3点 ¥1,500（税別途）になります。\n初回においては、3点 ¥990（税別途）のサービス価格になります。\nYシャツは、2枚1点の扱いになります。\n※一部対象外商品（例：着物類、ドレスなど）があります。',
    },
    validate: (values) => validateRules(values),
    onSubmit: (values) => {
      createPickupRequest.handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      !listDevice.isLoading &&
      !listDevice.isCompleted &&
      !listDevice.isError
    ) {
      listDevice.getData();
    }
  }, [listDevice]);

  const [isExistDeliveryBox, setIsExistDeliveryBox] = useState(false);
  const [isAvailableDeliveryBox, setIsAvailableDeliveryBox] = useState(false);
  const [isAvailableService, setIsAvailableService] = useState(false);
  const [isExistAddress, setIsExistAddress] = useState(false);

  useEffect(() => {
    if (formik.values.deviceId) {
      setIsExistDeliveryBox(true);

      const deliveryBox = listDevice.data.find((item) => {
        return item.deviceId === formik.values.deviceId;
      });

      if (!deliveryBox) {
        setIsAvailableDeliveryBox(false);
        setIsAvailableService(false);
        setIsExistAddress(false);
      }

      if (deliveryBox && deliveryBox.isHubConnectAvailable) {
        setIsAvailableDeliveryBox(true);
      } else {
        setIsAvailableDeliveryBox(false);
      }

      if (deliveryBox && deliveryBox.service) {
        setIsAvailableService(false);
      } else {
        setIsAvailableService(true);
      }

      if (deliveryBox && deliveryBox.zip) {
        setIsExistAddress(true);
      } else {
        setIsExistAddress(false);
      }
    } else {
      setIsAvailableService(false);
      setIsExistAddress(false);
    }
  }, [formik.values.deviceId, listDevice.data]);

  const history = useHistory();

  useEffect(() => {
    if (createPickupRequest.isCompleted) {
      history.push(
        `/services/pickup-requests/${createPickupRequest.data.pickupRequestId}/view`,
      );
    }
  }, [
    createPickupRequest.isCompleted,
    createPickupRequest.data.pickupRequestId,
    history,
  ]);

  return (
    <GenericTemplate
      loading={
        getUser.isLoading ||
        listDevice.isLoading ||
        createPickupRequest.isLoading
      }
    >
      <PickupRequestServiceHeader showMenu={canUseCleaning} />
      <Box mb={3}>
        <MessageBox color="inherit">
          現在、サービス開発中につき、実際のご利用イメージをご紹介しています。
          <br />
          ※昨年、江東区江戸川区にお住まいの方を対象に実証プロジェクトを実施しました。
          <br />
          <Link
            href="https://newsrelease.lixil.co.jp/news/pdf/2020062401.pdf"
            target="_blank"
            color="primary"
          >
            実証プロジェクトの内容はこちら
            <LaunchIcon fontSize="small" className={classes.icon} />
          </Link>
          <br />
          <br />
          本サービスにご協力いただけるクリーニング店様を募集しております。
          <br />
          ご興味のある企業様は、ぜひ
          <Link component={RouterLink} to={`/contact`}>
            お問い合わせ
          </Link>
          よりご連絡ください。
        </MessageBox>
      </Box>
      {createPickupRequest.isError && (
        <Box mb={2}>
          <MuiAlert severity="error" icon={false}>
            {createPickupRequest.errorMessage}
          </MuiAlert>
        </Box>
      )}
      {!canUseCleaning ? (
        <Form
          formikProps={formik}
          preferredDates={preferredDates}
          preferredTimeZone={preferredTimeZone}
          canUseCleaning={canUseCleaning}
        />
      ) : !isExistDeliveryBox ? (
        <>
          <Box mb={2}>
            <MuiAlert severity="error" icon={false}>
              サービスのご利用には宅配ボックスの登録が必要です。
            </MuiAlert>
          </Box>
          <Button
            component={RouterLink}
            to="/devices/delivery-boxes/add"
            color="primary"
            variant="contained"
            fullWidth
          >
            宅配ボックスを登録する
          </Button>
        </>
      ) : !isAvailableDeliveryBox ? (
        <>
          <Box mb={2}>
            <MuiAlert severity="error" icon={false}>
              宅配ボックスが現在ご利用できません。ホームユニットがインターネットに接続できているかご確認ください。
            </MuiAlert>
          </Box>
          <Button
            component={RouterLink}
            to="/contact"
            color="primary"
            variant="contained"
            fullWidth
          >
            お問い合わせ
          </Button>
        </>
      ) : !isAvailableService ? (
        <Box mb={2}>
          <MuiAlert severity="error" icon={false}>
            宅配ボックスは他のサービスで使用中です。
          </MuiAlert>
        </Box>
      ) : !isExistAddress ? (
        <>
          <Box mb={2}>
            <MuiAlert severity="error" icon={false}>
              サービスのご利用には住所の登録が必要です。
            </MuiAlert>
          </Box>
          <Button
            component={RouterLink}
            to={`/devices/delivery-boxes/${formik.values.deviceId}/address/add?redirect=/services/delivery-box-shares`}
            color="primary"
            variant="contained"
            fullWidth
          >
            住所を登録する
          </Button>
        </>
      ) : (
        <Form
          formikProps={formik}
          preferredDates={preferredDates}
          preferredTimeZone={preferredTimeZone}
          canUseCleaning={canUseCleaning}
        />
      )}
    </GenericTemplate>
  );
};

export default PickupRequestPage;
