import Axios from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export const useCancelDeliveryReservation = (): {
  handleSubmit: (deliveryId: string) => Promise<void>;
  isLoading: boolean;
  isError: boolean;
  isCompleted: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async (deliveryId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      await Axios.put(
        `${REACT_APP_API_ENDPOINT}/deliveries/${deliveryId}/cancel`,
        {},
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setIsCompleted(true);
      setIsError(false);
    } catch (e) {
      setIsCompleted(false);
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { handleSubmit, isLoading, isError, isCompleted };
};
