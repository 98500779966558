import Cookies from 'js-cookie';
import React, { useEffect } from 'react';

import AuthService from '../../utils/AuthService';
import LoadingIndicator from '../atoms/LoadingIndicator';
import GenericTemplate from '../templates/GenericTemplate';

const AFTER_LOGIN_REDIRECT_URL = 'redirect_url';

const CallbackPage: React.FC = () => {
  useEffect(() => {
    (async () => {
      await AuthService.handleAuthentication({
        redirectUri: `${window.location.origin}/callback`,
      });

      if (AuthService.isAuthenticated()) {
        if (await AuthService.checkAdditionalProcess()) {
          const accountLink = AuthService.getAccountLink(
            window.location.origin,
          );

          window.location.assign(accountLink);

          return;
        }

        const redirectUrl = Cookies.get(AFTER_LOGIN_REDIRECT_URL);

        Cookies.remove(AFTER_LOGIN_REDIRECT_URL);

        if (redirectUrl) {
          window.location.assign(redirectUrl);

          return;
        }
      }

      window.location.assign('/');
    })();
  });

  return (
    <GenericTemplate>
      <LoadingIndicator />
    </GenericTemplate>
  );
};

export default CallbackPage;
