import Axios, { AxiosResponse } from 'axios';
import { useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface PickupRequest {
  deviceId: string;
  fullName: string;
  preferredDate: string;
  preferredTimeZone: string;
  memo: string;
}

interface PickupRequestResponse {
  pickupRequestId: string;
}

export const useCreatePickupRequest = (): {
  data: PickupRequestResponse;
  handleSubmit: (values: PickupRequest) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [data, setData] = useState<PickupRequestResponse>({
    pickupRequestId: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (values: PickupRequest) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        const response: AxiosResponse<PickupRequestResponse> = await Axios.post(
          `${REACT_APP_API_ENDPOINT}/pickup-requests`,
          {
            fullName: values.fullName,
            preferredDate: values.preferredDate,
            preferredTimeZone: values.preferredTimeZone,
            memo: values.memo,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);

        if (
          e.response.data &&
          e.response.data.error &&
          e.response.data.error.type &&
          e.response.status &&
          e.response.status === 400
        ) {
          if (e.response.data.error.type === 'validation_error') {
            setErrorMessage('入力内容が正しくありません。');
          } else if (e.response.data.error.type === 'exists_error') {
            setErrorMessage('複数の申し込みは出来ません。');
          } else if (e.response.data.error.type === 'collection_mode_error') {
            setErrorMessage('宅配ボックスが「集荷待ち」状態ではありません。');
          } else {
            setErrorMessage('原因不明のエラーが発生しました。');
          }
        } else {
          setErrorMessage('原因不明のエラーが発生しました。');
        }
      }

      setIsLoading(false);
    })();
  };

  return { data, handleSubmit, isCompleted, isLoading, isError, errorMessage };
};
