import React, { forwardRef } from 'react';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    appBar: {
      position: 'relative',
    },
    title: {
      marginLeft: theme.spacing(2),
      flex: 1,
    },
    cardActions: {
      paddingLeft: theme.spacing(2),
      paddingTop: 0,
    },
    body: {
      padding: theme.spacing(2),
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export interface Address {
  label: string;
  zip: string;
  state: string;
  address1: string;
  address2: string;
  building: string;
  phoneNumber: string;
  lastName: string;
  firstName: string;
  lastNameKana: string;
  firstNameKana: string;
}

const SelectAddressDialog: React.FC<{
  onClose: () => void;
  onSelect: (address: Address) => void;
  open: boolean;
  addresses: Address[];
}> = ({ onClose, onSelect, open, addresses }) => {
  const classes = useStyles();

  const handleSelect = (address: Address) => {
    onSelect(address);
    onClose();
  };

  return (
    <Dialog
      onClose={onClose}
      open={open}
      TransitionComponent={Transition}
      fullScreen
    >
      <AppBar className={classes.appBar}>
        <Toolbar>
          <IconButton
            onClick={onClose}
            edge="start"
            color="inherit"
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            住所を選択
          </Typography>
        </Toolbar>
      </AppBar>
      <Grid container spacing={2} className={classes.body}>
        <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
          <Box mb={3}>
            {addresses.map((address, i) => (
              <Box mb={2} key={i}>
                <Card variant="outlined">
                  <CardContent>
                    <Typography variant="h6" component="h4">
                      {address.label}
                    </Typography>
                    <Typography variant="body2" component="p">
                      〒{address.zip}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {address.state} {address.address1} {address.address2}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {address.building}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {address.phoneNumber}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {address.lastName}
                      {address.firstName}
                    </Typography>
                    <Typography variant="body2" component="p">
                      {address.lastNameKana}
                      {address.firstNameKana}
                    </Typography>
                  </CardContent>
                  <CardActions className={classes.cardActions}>
                    <Button
                      color="primary"
                      size="small"
                      variant="contained"
                      onClick={() => handleSelect(address)}
                    >
                      選択する
                    </Button>
                  </CardActions>
                </Card>
              </Box>
            ))}
          </Box>
          <Button color="default" variant="contained" onClick={onClose}>
            キャンセル
          </Button>
        </Grid>
      </Grid>
    </Dialog>
  );
};

export default SelectAddressDialog;
