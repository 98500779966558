import Axios, { AxiosResponse } from 'axios';
import { useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface Device {
  collectionPassword: number;
  deliveryPassword: number;
  hubMacAddress: string;
  unlockMethodFirst: string;
  unlockMethodSecondOnward: string;
  yourPassword: number;
  nickName?: string;
}

export const useCreateDevice = (): {
  handleSubmit: (values: Device) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  data: {
    deviceId: string;
    hubMacAddress: string;
  };
} => {
  const [data, setData] = useState({
    deviceId: '',
    hubMacAddress: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (values: Device) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        const response: AxiosResponse = await Axios.post(
          `${REACT_APP_API_ENDPOINT}/devices`,
          {
            collectionPassword: values.collectionPassword,
            deliveryPassword: values.deliveryPassword,
            hubMacAddress: values.hubMacAddress,
            unlockMethodFirst: values.unlockMethodFirst,
            unlockMethodSecondOnward: values.unlockMethodSecondOnward,
            yourPassword: values.yourPassword,
            nickName: values.nickName,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { handleSubmit, isCompleted, isLoading, isError, data };
};
