import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import WarningIcon from '@material-ui/icons/WarningRounded';

import { useGetSagawa } from '../../hooks/useGetSagawaService';
import PageTitleBar from '../atoms/PageTitleBar';
import { ReactComponent as IconDelivery } from '../../assets/icon-delivery.svg';
import { ReactComponent as Logo } from '../../assets/logo-green.svg';
import { Device } from '../../hooks/useListDevice';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      width: '35px',
      height: '35px',
      color: theme.palette.primary.main,
    },
    item: {
      textAlign: 'center',
      textDecoration: 'none',
    },
    label: {
      fontWeight: 'bold',
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
      margin: theme.spacing(1, 0, 0),
    },
    customPopper: {
      zIndex: 'auto',
    },
    customTooltip: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.primary.main,
      margin: '14px 0',
    },
    customArrow: {
      color: theme.palette.primary.main,
    },
    badge: {
      verticalAlign: 'baseline',
      '& .MuiBadge-badge': {
        top: '0',
        right: '0',
      },
    },
  }),
);

interface ServiceItemProps {
  isUsingService: boolean;
  usingServiceName?: string;
  icon: React.ReactElement;
  label: string | React.ReactNode;
  to: string;
}

const ServiceItem: React.FC<ServiceItemProps> = ({
  isUsingService,
  usingServiceName,
  icon,
  label,
  to,
}) => {
  const classes = useStyles();

  return (
    <Grid
      item
      xs={4}
      md={2}
      component={Link}
      to={to}
      className={classes.item}
      style={{
        marginTop: usingServiceName ? '3rem' : '1rem',
      }}
    >
      <Tooltip
        arrow
        open={isUsingService}
        placement="top"
        title={usingServiceName || ''}
        PopperProps={{
          disablePortal: true,
          modifiers: {
            flip: {
              enabled: false,
            },
          },
        }}
        classes={{
          popper: classes.customPopper,
          tooltip: classes.customTooltip,
          arrow: classes.customArrow,
        }}
      >
        {icon}
      </Tooltip>
      <p className={classes.label}>{label}</p>
    </Grid>
  );
};

interface ServiceListProps {
  devices: Device[];
}

const ServiceList: React.FC<ServiceListProps> = ({ devices }) => {
  const classes = useStyles();

  const serviceNameTable: { [key: string]: string } = {
    deliveryBoxShareDelivery: 'もってきて',
    deliveryBoxSharePickup: 'もってって',
    deliveryReserve: '配達予約中',
    pickupRequest: '利用中',
  };
  const serviceName =
    devices.length > 0 && devices[0].service ? devices[0].service : '';
  const getSagawa = useGetSagawa();

  useEffect(() => {
    if (
      devices.length > 0 &&
      !getSagawa.isCompleted &&
      !getSagawa.isError &&
      !getSagawa.isLoading
    ) {
      getSagawa.getData(devices[0].deviceId);
    }
  }, [getSagawa]);

  const [isSagawa, setIsSagwa] = useState(false);
  useEffect(() => {
    if (getSagawa.data.status == 'complete') {
      setIsSagwa(true);
    }
  }, [getSagawa]);

  return (
    <>
      <PageTitleBar>サービス</PageTitleBar>
      <Box mb={4}>
        <Grid container spacing={3}>
          <ServiceItem
            isUsingService={
              serviceName === 'deliveryBoxShareDelivery' ||
              serviceName === 'deliveryBoxSharePickup'
            }
            usingServiceName={serviceNameTable[serviceName]}
            icon={
              <Badge
                overlap="circular"
                invisible={
                  !(
                    serviceName === 'deliveryBoxSharePickup' &&
                    devices[0].status === 'empty'
                  )
                }
                badgeContent={<WarningIcon style={{ color: '#ff9800' }} />}
                className={classes.badge}
              >
                <Logo className={classes.icon} />
              </Badge>
            }
            label="あずけるボックス"
            to={`/services/delivery-box-shares`}
          />
          {isSagawa ? (
            <ServiceItem
              isUsingService={serviceName === 'deliveryReserve'}
              usingServiceName={serviceNameTable[serviceName]}
              icon={<IconDelivery className={classes.icon} />}
              label="佐川急便サービス"
              to={`/services/delivery-reservations`}
            />
          ) : (
            <></>
          )}
        </Grid>
      </Box>
    </>
  );
};

export default ServiceList;
