import Axios from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface DeviceHistory {
  deviceId: string;
  userId: string;
  oidcClientId: string;
  type: string;
  reason: string;
  scheduledDate: string;
  createAt: number;
}

export const useGetDeviceHistory = (): {
  getData: (
    deviceId?: string,
    clientId?: string,
    limit?: number,
  ) => Promise<void>;
  data: { deviceHistories: DeviceHistory[] };
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<{ deviceHistories: DeviceHistory[] }>({
    deviceHistories: [
      {
        deviceId: '',
        userId: '',
        oidcClientId: '',
        type: '',
        reason: '',
        scheduledDate: '',
        createAt: 0,
      },
    ],
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(
    async (deviceId?: string, oidcClientId?: string, limit = 30) => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      const idToken = AuthService.getIdToken();

      try {
        const response = await Axios.get(
          `${REACT_APP_API_ENDPOINT}/device-histories`,
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
            params: {
              deviceId: deviceId,
              oidcClientId: oidcClientId,
              limit: limit,
            },
          },
        );

        setData(response.data);
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    },
    [],
  );

  return { getData, data, isCompleted, isLoading, isError };
};
