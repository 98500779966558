import React, { useState } from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface FormRadioGroupItem {
  label: string;
  value: string;
  description?: string;
}

interface FormRadioGroupProps {
  items: FormRadioGroupItem[];
  label: string;
  attention?: string;
  name: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  defaultValue?: string;
  fullWidth?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    formControl: {
      marginBottom: '0.2rem',
      marginTop: '0.8rem',
    },
    formLabel: {
      fontSize: '1rem',
      fontWeight: 'bold',
      marginBottom: '0.4rem',
    },
    radioGroup: {
      margin: '0',
      '& .MuiFormControlLabel-root': {
        alignItems: 'flex-start',
      },
      '& .MuiFormControlLabel-label': {
        paddingTop: '9px',
      },
    },
    label: {
      float: 'left',
      marginRight: '0.6rem',
      minWidth: '96px',
    },
    description: {
      float: 'left',
      fontSize: '0.7rem',
      paddingTop: '0.3rem',
    },
    attention: {
      color: theme.palette.grey[700],
      fontSize: '0.7rem',
      marginLeft: '0.5rem',
    },
  }),
);

const FormRadioGroup: React.FC<FormRadioGroupProps> = ({
  items,
  label,
  attention,
  name,
  defaultValue,
  onChange,
  fullWidth,
}) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue((event.target as HTMLInputElement).value);

    if (typeof onChange !== 'undefined') {
      onChange(event);
    }
  };

  return (
    <FormControl
      className={classes.formControl}
      component="fieldset"
      fullWidth={fullWidth}
    >
      <FormLabel className={classes.formLabel} component="legend">
        {label}
        {attention && <span className={classes.attention}>{attention}</span>}
      </FormLabel>
      <RadioGroup
        className={classes.radioGroup}
        name={name}
        onChange={handleChange}
        value={value}
      >
        {items.map((item) => (
          <FormControlLabel
            control={<Radio color="default" />}
            key={`${name}-${item.value}`}
            label={
              <>
                <Typography className={classes.label} component="div">
                  {item.label}
                </Typography>
                <Typography className={classes.description} component="div">
                  {item.description}
                </Typography>
              </>
            }
            value={item.value}
          />
        ))}
      </RadioGroup>
    </FormControl>
  );
};

export default FormRadioGroup;
