import React from 'react';
import Box from '@material-ui/core/Box';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';

import { ReactComponent as DeliveryBoxIcon } from '../../assets/icon-delivery-box.svg';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      width: '50px',
      height: '50px',
      borderRadius: '50%',
      border: `1px solid ${theme.palette.primary.main}`,
      padding: '6px',
    },
  }),
);

const DeliveryBoxDeviceHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <Box textAlign="center">
      <DeliveryBoxIcon className={classes.icon} />
    </Box>
  );
};

export default DeliveryBoxDeviceHeader;
