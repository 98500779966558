import React, { useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import MobileStepper from '@material-ui/core/MobileStepper';
import { makeStyles, StyleRules, useTheme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';

import { ReactComponent as Logo } from '../../assets/logo-green.svg';
import tutorial01 from '../../assets/tutorial-01.png';
import tutorial02 from '../../assets/tutorial-02.png';

const useStyles = makeStyles(
  (): StyleRules => ({
    cancelButton: {
      paddingTop: '0',
    },
    logo: {
      width: '35px',
    },
  }),
);

const TutorialSteps: React.FC<{ activeStep: number }> = ({ activeStep }) => {
  const classes = useStyles();

  return (
    <>
      {activeStep === 0 && (
        <Box>
          <Box textAlign="center" mb={2}>
            <Logo className={classes.logo} />
          </Box>
          <Typography variant="body2">
            LIXILが提供しているサービスです。
            <br />
            お使いのスマート宅配ポストをIoT製品として、スマホやパソコンで操作し、様々なサービスをご利用することができます。
          </Typography>
        </Box>
      )}
      {activeStep === 1 && (
        <Box>
          <Typography variant="body2">
            デバイスの追加アイコンをタップして、ホームユニットとスマート宅配ポストの情報を登録してください。
          </Typography>
          <Box mt={2} textAlign="center">
            <span>&lt;登録方法の確認方法&gt;</span>
            <img alt="" src={tutorial01} width="100%" />
          </Box>
        </Box>
      )}
      {activeStep === 2 && (
        <Box>
          <Typography variant="body2">
            宅配ボックスを使って、お知り合いの方ともののやり取りや、各業者さんのサービスのお申し込みができます。
          </Typography>
          <Box mt={2} textAlign="center">
            <span>&lt;サービス内容の確認方法&gt;</span>
            <img alt="" src={tutorial02} width="100%" />
          </Box>
        </Box>
      )}
    </>
  );
};

type Props = {
  onClick: () => void;
  open: boolean;
};

const TutorialDialog: React.FC<Props> = ({ open, onClick }) => {
  const classes = useStyles();
  const theme = useTheme();

  const maxSteps = 3;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return (
    <Dialog fullWidth aria-labelledby="dialog-title" open={open}>
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" color="primary" style={{ fontWeight: 'bold' }}>
          {activeStep === 0 && 'あずけるBOXとは？'}
          {activeStep === 1 && 'はじめに'}
          {activeStep === 2 && 'あずけるBOXでできること'}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TutorialSteps activeStep={activeStep} />
      </DialogContent>
      <DialogActions>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
          style={{ width: '100%' }}
        />
      </DialogActions>
      <DialogActions className={classes.cancelButton}>
        <Button onClick={onClick}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

export default TutorialDialog;
