import Axios from 'axios';
import { useCallback, useState } from 'react';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 「あずけるボックス」サービスの状態
 *
 * @returns canceled: キャンセル済
 * @returns delivered: 配達完了
 * @returns collected: 集荷完了
 * @returns expireCanceled: 有効期限切れ
 * @returns reserved: 予約完了
 */
export type DeliveryboxShareStatus =
  | 'canceled'
  | 'delivered'
  | 'collected'
  | 'reserved'
  | 'expireCanceled'
  | 'unknown';

/**
 * 「あずけるボックス」サービスの期限
 *
 * @returns today: 当日中
 * @returns tomorrow: 翌日中
 */
export type DeliveryboxShareDuration = 'today' | 'tomorrow' | 'unknown';

export interface DeliveryBoxShare {
  deliveryBoxShareId: string;
  deviceId: string;
  type: string;
  sender: string;
  status: DeliveryboxShareStatus;
  destination: string;
  duration: DeliveryboxShareDuration;
  expireDate: string;
  expireAt: number;
  zip: string;
  state: string;
  address1: string;
  address2: string;
  building?: string;
  createAt: number;
  updateAt: number;
}

export const useGetDeliveryBoxShare = (): {
  getData: (deliveryBoxShareId: string) => Promise<void>;
  data: DeliveryBoxShare;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<DeliveryBoxShare>({
    deliveryBoxShareId: '',
    deviceId: '',
    type: '',
    sender: '',
    status: 'unknown',
    destination: '',
    duration: 'unknown',
    expireDate: '',
    expireAt: 0,
    zip: '',
    state: '',
    address1: '',
    address2: '',
    building: '',
    createAt: 0,
    updateAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (deliveryBoxShareId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const response = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/shares/${deliveryBoxShareId}`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError };
};
