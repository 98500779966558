import { useState, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 状態
 *
 * @returns unrequested: 未申請
 * @returns request: 登録中
 * @returns complete: 登録完了
 * @returns reject: 登録失敗
 */
export type Status = 'unrequested' | 'request' | 'complete' | 'reject';

export interface Delivery {
  hubMacAddress: string;
  userId: string;
  sendAddress: string;
  receiveAddress: string;
  status: Status;
  reserved: boolean;
  createAt: number;
  updateAt: number;
}

export const useGetSagawa = (): {
  getData: (deviceId: string) => Promise<void>;
  data: Delivery;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [data, setData] = useState<Delivery>({
    hubMacAddress: '',
    userId: '',
    sendAddress: '',
    receiveAddress: '',
    status: 'unrequested',
    reserved: false,
    createAt: 0,
    updateAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getData = useCallback(async (deviceId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/sagawa/${deviceId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      if (e.response.status === 404) {
        setData({
          hubMacAddress: '',
          userId: '',
          sendAddress: '',
          receiveAddress: '',
          status: 'unrequested',
          reserved: false,
          createAt: 0,
          updateAt: 0,
        });
        setIsCompleted(true);
      } else {
        setIsError(true);
        setErrorMessage('原因不明のエラーが発生しました。');
      }
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError, errorMessage };
};
