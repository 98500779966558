import React, { useCallback } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Paper from '@material-ui/core/Paper';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import LaunchIcon from '@material-ui/icons/Launch';
import VpnKeyIcon from '@material-ui/icons/VpnKey';

import { ReactComponent as Logo } from '../../../assets/logo-lixil.svg';
import AuthService from '../../../utils/AuthService';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    paperWithMargin: {
      marginBottom: theme.spacing(3),
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'calc(((100vw - 100%) / 2) * -1)',
        marginRight: 'calc(((100vw - 100%) / 2) * -1)',
        borderRadius: 0,
      },
    },
    paper: {
      [theme.breakpoints.down('xs')]: {
        marginLeft: 'calc(((100vw - 100%) / 2) * -1)',
        marginRight: 'calc(((100vw - 100%) / 2) * -1)',
        borderRadius: 0,
      },
    },
    subTitle: {
      fontWeight: 'bold',
      fontSize: '0.9rem',
      padding: theme.spacing(2),
    },
    list: {
      padding: 0,
      '& .MuiListItemIcon-root': {
        minWidth: '1.6rem',
      },
      '& .MuiSvgIcon-root': {
        color: theme.palette.grey[500],
        width: '1.2rem',
        height: '1.2rem',
      },
    },
    link: {
      color: theme.palette.text.primary,
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'none',
      },
    },
    logo: {
      height: '32px',
    },
  }),
);

const ListItemRouterLink = (props: {
  children: React.ReactNode;
  to: string;
}) => {
  return (
    <ListItem component={RouterLink} to={props.to} button>
      {props.children}
    </ListItem>
  );
};

const ListItemLink = (props: {
  // eslint-disable-next-line
  children: any;
  href: string;
  target?: string;
}) => {
  const classes = useStyles();

  return (
    <ListItem
      component={Link}
      href={props.href}
      className={classes.link}
      button
      target={props.target}
    >
      {props.children}
    </ListItem>
  );
};

const SettingPage: React.FC = () => {
  const classes = useStyles();

  const unSubscribeLink = AuthService.getUnSubscribeLink();
  const authenticated = AuthService.isAuthenticated();

  const openMylixilAccountSettings = () => {
    window.open(AuthService.getLoginSettingsLink(), '_blank');
  };
  const handleLogin = useCallback(() => {
    AuthService.login({
      redirectUri: `${window.location.origin}/callback`,
      prompt: 'login',
    });
  }, []);

  const handleLogout = useCallback(() => {
    AuthService.logout({
      redirectTo: `${window.location.origin}/callback`,
    });
  }, []);

  return (
    <GenericTemplate title="その他">
      <Grid container spacing={3}>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paperWithMargin}>
            <Typography component="h3" className={classes.subTitle}>
              ユーザー情報
            </Typography>
            <List component="nav" className={classes.list}>
              {authenticated && (
                <>
                  <Divider />
                  <ListItem onClick={openMylixilAccountSettings}>
                    <ListItemText primary="アカウント設定 (MyLIXIL)" />
                    <ListItemIcon>
                      <LaunchIcon />
                    </ListItemIcon>
                  </ListItem>
                </>
              )}
              <Divider />
              <ListItemRouterLink to="/settings/notifications">
                <ListItemText primary="通知設定" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
            </List>
          </Paper>
          <Paper className={classes.paper}>
            <Typography component="h3" className={classes.subTitle}>
              サービス情報
            </Typography>
            <List component="nav" className={classes.list}>
              {/*
              <Divider />
              <ListItemLink href="https://manual.smartexterior.net/">
                <ListItemText primary="オンラインヘルプ" />
                <ListItemIcon>
                  <LaunchIcon />
                </ListItemIcon>
              </ListItemLink>
              */}
              <Divider />
              <ListItemRouterLink to="/about">
                <ListItemText primary="あずけるBOXについて" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
              <Divider />
              <ListItemRouterLink to="/faq">
                <ListItemText primary="よくあるご質問" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
              <Divider />
              <ListItemRouterLink to="/contact">
                <ListItemText primary="サポートセンター・お問合せ" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
              <Divider />
              <ListItemRouterLink to="/terms">
                <ListItemText primary="利用規約" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
              <Divider />
              <ListItemRouterLink to="/privacy">
                <ListItemText primary="プライバシーポリシー" />
                <ListItemIcon>
                  <ArrowForwardIosIcon />
                </ListItemIcon>
              </ListItemRouterLink>
            </List>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper className={classes.paper}>
            <Typography component="h3" className={classes.subTitle}>
              その他
            </Typography>
            <List component="nav" className={classes.list}>
              <Divider />
              {authenticated ? (
                <>
                  <ListItem button onClick={handleLogout}>
                    <ListItemText primary="ログアウト" />
                    <ListItemIcon>
                      <ExitToAppIcon />
                    </ListItemIcon>
                  </ListItem>
                  <Divider />
                  <ListItemLink href={unSubscribeLink}>
                    <ListItemText primary="退会 (MyLIXIL)" />
                    <ListItemIcon>
                      <LaunchIcon />
                    </ListItemIcon>
                  </ListItemLink>
                </>
              ) : (
                <ListItem button onClick={handleLogin}>
                  <ListItemText primary="ログイン" />
                  <ListItemIcon>
                    <VpnKeyIcon />
                  </ListItemIcon>
                </ListItem>
              )}
            </List>
          </Paper>
        </Grid>
      </Grid>
      <Box pt={6} textAlign="center">
        <Logo className={classes.logo} />
      </Box>
    </GenericTemplate>
  );
};

export default SettingPage;
