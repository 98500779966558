import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LaunchIcon from '@material-ui/icons/Launch';

import MessageBox from '../../molecules/MessageBox';
import DemoServiceHeader from '../../organisms/DemoRentalServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (): StyleRules => ({
    demoWrapper: {
      fontSize: 0,
      margin: '0 auto',
    },
    icon: {
      verticalAlign: 'bottom',
      marginLeft: '2px',
    },
  }),
);

const DemoServicePage: React.FC = () => {
  const classes = useStyles();

  return (
    <GenericTemplate>
      <DemoServiceHeader />
      <Box mb={3}>
        <MessageBox color="inherit">
          実際のご利用イメージをご紹介しています。
          <br />
          <Link
            href="https://demo.smartexterior.net/"
            target="_blank"
            color="primary"
          >
            レンタルサービスアプリはこちら
            <LaunchIcon fontSize="small" className={classes.icon} />
          </Link>
          <br />
          <br />
          お問い合わせにつきましては、
          <Link component={RouterLink} to={`/contact`}>
            こちら
          </Link>
          よりご連絡ください。
        </MessageBox>
      </Box>
    </GenericTemplate>
  );
};

export default DemoServicePage;
