import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    faqPanel: {
      width: '100%',
      boxShadow: 'none',
      padding: 0,
    },
    faqPanelSummary: {
      padding: 0,
    },
    faqPanelDetails: {
      color: theme.palette.text.secondary,
      paddingLeft: 0,
      '& ul': {
        paddingLeft: theme.spacing(3),
      },
    },
  }),
);

interface FaqListItemProps {
  title: string;
  body: string;
}

const FaqListItem: React.FC<FaqListItemProps> = ({ title, body }) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters divider>
      <Accordion className={classes.faqPanel}>
        <AccordionSummary
          className={classes.faqPanelSummary}
          expandIcon={<ExpandMoreIcon />}
        >
          {title}
        </AccordionSummary>
        <AccordionDetails className={classes.faqPanelDetails}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </AccordionDetails>
      </Accordion>
    </ListItem>
  );
};

export default FaqListItem;
