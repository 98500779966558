import GoogleMapReact, { Coords } from 'google-map-react';
import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link as RouterLink, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import operation01 from '../../../assets/delivery-box-operation-01.png';
import operation02 from '../../../assets/delivery-box-operation-02.png';
import { useDialog } from '../../../hooks/useDialog';
import { useGeolocation } from '../../../hooks/useGeolocation';
import { useGetDeliveryBoxShare } from '../../../hooks/useGetDeliveryBoxShare';
import {
  DeliveryBoxSharePassword,
  useGetDeliveryBoxSharePassword,
} from '../../../hooks/useGetDeliveryBoxSharePassword';
import { useInterval } from '../../../hooks/useInterval';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import PageTitleBar from '../../atoms/PageTitleBar';
import DeliveryBoxShareServiceHeader from '../../organisms/DeliveryBoxShareServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const REACT_APP_GOOGLE_MAP_API_KEY = process.env.REACT_APP_GOOGLE_MAP_API_KEY!;

const handleApiLoaded = (
  // eslint-disable-next-line
  map: any,
  // eslint-disable-next-line
  maps: any,
  coords: Coords,
  address: string,
) => {
  const directionsService: google.maps.DirectionsService =
    new maps.DirectionsService();
  const directionsRenderer: google.maps.DirectionsRenderer =
    new maps.DirectionsRenderer({
      suppressMarkers: true,
    });

  const request: google.maps.DirectionsRequest = {
    origin: {
      lat: coords.lat,
      lng: coords.lng,
    },
    destination: address,
    travelMode: google.maps.TravelMode.DRIVING,
  };

  directionsService.route(request, (response, status) => {
    if (status === google.maps.DirectionsStatus.OK) {
      directionsRenderer.setDirections(response);
      directionsRenderer.setMap(map);

      const startMarker = new google.maps.Marker({
        position: response.routes[0].legs[0].start_location,
        map: map,
        title: response.routes[0].legs[0].start_address,
        icon: {
          path: google.maps.SymbolPath.CIRCLE,
          fillColor: '#4169e1',
          fillOpacity: 1,
          scale: 8,
          strokeColor: '#FFFFFF',
          strokeWeight: 3.0,
        },
      });

      const endMarker = new google.maps.Marker({
        position: response.routes[0].legs[0].end_location,
        map: map,
        title: response.routes[0].legs[0].end_address,
      });

      startMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow();

        infowindow.setContent(startMarker.getTitle()!);
        infowindow.open(map, startMarker);
      });

      endMarker.addListener('click', () => {
        const infowindow = new google.maps.InfoWindow();

        infowindow.setContent(endMarker.getTitle()!);
        infowindow.open(map, endMarker);
      });
    } else {
      console.error(status);
    }
  });
};

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    dialogContentTextSuccess: {
      fontWeight: 'bold',
      fontSize: '2rem',
      textAlign: 'center',
      letterSpacing: '1.0rem',
    },
    dialogContentTextError: {
      fontWeight: 'bold',
      fontSize: '1.2rem',
      color: theme.palette.error.main,
      textAlign: 'center',
    },
    title: {
      fontWeight: 'bold',
      color: theme.palette.primary.main,
      textAlign: 'center',
    },
    info: {
      border: `2px solid ${theme.palette.primary.main}`,
      background: '#f7fafb',
      padding: theme.spacing(2),
      marginTop: theme.spacing(2),
    },
    expireAt: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    submitButton: {
      fontSize: theme.typography.h6.fontSize,
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    list: {
      marginTop: theme.spacing(1),
    },
    listItem: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    exImg: {
      width: '50%',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0,
      },
    },
    exImgText: {
      color: '#2fa3f3',
      fontWeight: 'bold',
      fontSize: theme.typography.h5.fontSize,
    },
  }),
);

const DeliveryBoxSharePasswordDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  isFirstLoaded: boolean;
  isCompleted: boolean;
  isError: boolean;
  errorMessage: string;
  password: number;
  passwordSettingStatus: DeliveryBoxSharePassword['passwordSettingStatus'];
  pollingCount: number;
}> = ({
  open,
  onClose,
  isFirstLoaded,
  isCompleted,
  isError,
  errorMessage,
  password,
  passwordSettingStatus,
  pollingCount,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="delivery-box-share-password-dialog-title"
      aria-describedby="delivery-box-share-password-dialog-description"
    >
      <DialogTitle id="delivery-box-share-password-dialog-title">
        宅配ボックスの解錠番号
      </DialogTitle>
      <DialogContent>
        {isFirstLoaded ? (
          <>
            {isError && !isCompleted ? (
              <DialogContentText
                id="delivery-box-share-password-dialog-description"
                className={classes.dialogContentTextError}
              >
                {errorMessage}
              </DialogContentText>
            ) : (
              <DialogContentText
                id="delivery-box-share-password-dialog-description"
                component="div"
              >
                {passwordSettingStatus === 'in_setting' ? (
                  <Box mb={2.5}>
                    {pollingCount < 10 ? (
                      <LoadingIndicator />
                    ) : (
                      <>
                        <Typography gutterBottom>
                          解錠番号が取得できませんでした。
                        </Typography>
                        恐れ入りますが、2分程度時間をおいて、再度「解錠番号を取得する」をお試しください。
                        番号が表示されない場合、URLの発行者にお問い合わせください。
                      </>
                    )}
                  </Box>
                ) : passwordSettingStatus === 'wait_pickup_mode' ? (
                  <Box mb={2.5}>
                    <Typography gutterBottom>
                      解錠番号が取得できませんでした。
                    </Typography>
                    宅配ボックスが「集荷待ち」に設定されていません。URLの発行者にお問い合わせください。
                  </Box>
                ) : passwordSettingStatus === 'done' ? (
                  <Box className={classes.dialogContentTextSuccess} mb={2}>
                    {password}
                  </Box>
                ) : (
                  <Box mb={2.5}>
                    <Typography gutterBottom>
                      解錠番号が取得できませんでした。
                    </Typography>
                    URLの発行者にお問い合わせください。
                    問題が解決しない場合、申し訳ございませんが、
                    <Link
                      component={RouterLink}
                      to={'/contact'}
                      color="primary"
                    >
                      こちら
                    </Link>
                    よりお問い合わせください。
                  </Box>
                )}
                <Typography variant="h6" component="h6" color="textPrimary">
                  宅配ボックスの操作方法
                </Typography>
                <List disablePadding className={classes.list}>
                  <ListItem
                    className={classes.listItem}
                    disableGutters
                    alignItems="flex-start"
                  >
                    <img
                      src={operation01}
                      alt="外扉を開け開始をタッチ"
                      className={classes.exImg}
                    />
                    <Box>
                      外扉を開けて
                      <span className={classes.exImgText}>開始</span>
                      をタッチ
                    </Box>
                  </ListItem>
                  <ListItem
                    className={classes.listItem}
                    disableGutters
                    alignItems="flex-start"
                  >
                    <img
                      src={operation02}
                      alt="解錠番号を入力し確定をタッチ"
                      className={classes.exImg}
                    />
                    <Box>
                      解錠番号を入力し
                      <span className={classes.exImgText}>確定</span>
                      をタッチ
                    </Box>
                  </ListItem>
                </List>
              </DialogContentText>
            )}
          </>
        ) : (
          <LoadingIndicator />
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          閉じる
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeliveryBoxShareUnlockPage: React.FC = () => {
  const classes = useStyles();
  const { deliveryBoxShareId } = useParams<{ deliveryBoxShareId: string }>();

  const geolocation = useGeolocation();

  useEffect(() => {
    if (
      !geolocation.isCompleted &&
      !geolocation.isError &&
      !geolocation.isLoading
    ) {
      geolocation.getData();
    }
  }, [geolocation]);

  const getDeliveryBoxShare = useGetDeliveryBoxShare();

  useEffect(() => {
    if (
      !getDeliveryBoxShare.isCompleted &&
      !getDeliveryBoxShare.isError &&
      !getDeliveryBoxShare.isLoading
    ) {
      getDeliveryBoxShare.getData(deliveryBoxShareId!);
    }
  }, [getDeliveryBoxShare, deliveryBoxShareId]);

  const getDeliveryBoxSharePassword = useGetDeliveryBoxSharePassword();

  const deliveryBoxSharePasswordDialog = useDialog();
  const [isPolling, setIsPolling] = useState(false);
  const [pollingCount, setPollingCount] = useState(0);

  const handleCloseDeliveryBoxSharePassword = () => {
    deliveryBoxSharePasswordDialog.handleClose();
    setIsPolling(false);
    setPollingCount(0);
  };
  const handleOpenDeliveryBoxSharePassword = () => {
    deliveryBoxSharePasswordDialog.handleOpen();
    setIsPolling(true);
    setPollingCount(0);
  };

  useInterval(
    () => {
      getDeliveryBoxSharePassword.getData(deliveryBoxShareId!);

      if (
        getDeliveryBoxSharePassword.data.passwordSettingStatus ===
          'in_setting' &&
        pollingCount < 10
      ) {
        setPollingCount((prevCount) => prevCount + 1);
      } else {
        setIsPolling(false);
      }
    },
    isPolling ? 10000 : null,
  );

  return (
    <GenericTemplate>
      <DeliveryBoxShareServiceHeader />
      {getDeliveryBoxShare.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {getDeliveryBoxShare.isError ? (
            <MuiAlert severity="error" icon={false}>
              原因不明のエラーが発生しました
            </MuiAlert>
          ) : (
            getDeliveryBoxShare.isCompleted && (
              <>
                <Typography
                  component="h2"
                  variant="subtitle1"
                  className={classes.title}
                >
                  あずけるボックス
                  {getDeliveryBoxShare.data.type === 'delivery' &&
                    '「もってきて」'}
                  {getDeliveryBoxShare.data.type === 'pickup' &&
                    '「もってって」'}
                  のご案内が届いています
                </Typography>
                <Box className={classes.info}>
                  <Typography
                    variant="body1"
                    gutterBottom
                    color="primary"
                    style={{ fontWeight: 'bold' }}
                  >
                    {getDeliveryBoxShare.data.sender
                      ? getDeliveryBoxShare.data.sender + 'さんからのお知らせ'
                      : 'お知らせ'}
                  </Typography>
                  <Typography
                    component="h2"
                    color="textSecondary"
                    style={{ fontWeight: 'bold' }}
                  >
                    {getDeliveryBoxShare.data.destination}
                  </Typography>
                </Box>
                <Box
                  mt={2}
                  fontSize="1rem"
                  color="text.secondary"
                  fontWeight="bold"
                >
                  解錠番号を取得し、宅配ボックスを開けてものを受け取ってください。
                </Box>
                <Typography
                  component="h2"
                  color="secondary"
                  className={classes.expireAt}
                >
                  ※
                  {DateTime.fromMillis(
                    getDeliveryBoxShare.data.expireAt,
                  ).toFormat('yyyy年MM月dd日 HH時mm分')}
                  まで有効です
                </Typography>
                <Button
                  className={classes.submitButton}
                  color="primary"
                  fullWidth
                  type="button"
                  variant="contained"
                  onClick={handleOpenDeliveryBoxSharePassword}
                >
                  解錠番号を取得する
                </Button>

                {geolocation.isCompleted && !geolocation.isError && (
                  <Box mt={4}>
                    <PageTitleBar>あずけるBOXの場所</PageTitleBar>
                    <Box height={250}>
                      <GoogleMapReact
                        bootstrapURLKeys={{
                          key: REACT_APP_GOOGLE_MAP_API_KEY,
                        }}
                        defaultCenter={geolocation.coords}
                        center={{
                          lat: geolocation.coords.lat,
                          lng: geolocation.coords.lng,
                        }}
                        defaultZoom={21}
                        yesIWantToUseGoogleMapApiInternals
                        onGoogleApiLoaded={({ map, maps }) =>
                          handleApiLoaded(
                            map,
                            maps,
                            geolocation.coords,
                            getDeliveryBoxShare.data.state +
                              getDeliveryBoxShare.data.address1 +
                              getDeliveryBoxShare.data.address2 +
                              getDeliveryBoxShare.data.building || '',
                          )
                        }
                      />
                    </Box>
                  </Box>
                )}

                <Box mt={4} textAlign="center">
                  「あずけるBOX」は、LIXILが提供しているサービスです。
                </Box>

                <DeliveryBoxSharePasswordDialog
                  open={deliveryBoxSharePasswordDialog.open}
                  onClose={handleCloseDeliveryBoxSharePassword}
                  isFirstLoaded={getDeliveryBoxSharePassword.isFirstLoaded}
                  isCompleted={getDeliveryBoxSharePassword.isCompleted}
                  isError={getDeliveryBoxSharePassword.isError}
                  errorMessage={getDeliveryBoxSharePassword.errorMessage}
                  password={getDeliveryBoxSharePassword.data.password}
                  passwordSettingStatus={
                    getDeliveryBoxSharePassword.data.passwordSettingStatus
                  }
                  pollingCount={pollingCount}
                />
              </>
            )
          )}
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxShareUnlockPage;
