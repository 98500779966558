import { useFormik } from 'formik';
import React, { useCallback, useEffect } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { useContact } from '../../../hooks/useContact';
import { useDialog } from '../../../hooks/useDialog';
import { useGetSagawa } from '../../../hooks/useGetSagawaService';
import { useListDevice } from '../../../hooks/useListDevice';
import { useUpdateSagawa } from '../../../hooks/useUpdateSagawaService';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import FormRadioGroup from '../../molecules/FormRadioGroup';
import MessageBox from '../../molecules/MessageBox';
import DeliveryReserveServiceHeader from '../../organisms/DeliveryReservationServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    button: {
      fontWeight: 'bold',
      marginTop: theme.spacing(2),
    },
    cancelServiceButton: {
      display: 'block',
      marginLeft: 'auto',
      marginTop: theme.spacing(8),
    },
    form: {
      marginTop: theme.spacing(2),
    },
  }),
);

const DeliveryReservationSettingPage: React.FC = () => {
  const classes = useStyles();
  const listDevice = useListDevice();

  useEffect(() => {
    if (
      !listDevice.isCompleted &&
      !listDevice.isError &&
      !listDevice.isLoading
    ) {
      listDevice.getData();
    }
  }, [listDevice]);

  const getSagawa = useGetSagawa();

  useEffect(() => {
    if (
      listDevice.data.length > 0 &&
      !getSagawa.isCompleted &&
      !getSagawa.isError &&
      !getSagawa.isLoading
    ) {
      getSagawa.getData(listDevice.data[0].deviceId);
    }
  }, [listDevice, getSagawa]);

  const updateSagawa = useUpdateSagawa();

  const formik = useFormik({
    initialValues: {
      deviceId: listDevice.data[0] && listDevice.data[0].deviceId,
      sendAddress: getSagawa.data.sendAddress,
      reserved: getSagawa.data.reserved
        ? 'reservedAndNotifyUnlockNumber'
        : 'notifyUnlockNumber',
    },
    onSubmit: (values) => {
      (async () => {
        const newValues = {
          ...values,
          ...{ reserved: values.reserved === 'reservedAndNotifyUnlockNumber' },
        };
        await updateSagawa.handleSubmit(newValues);
        await getSagawa.getData(listDevice.data[0].deviceId);
      })();
    },
    enableReinitialize: true,
  });

  const cancelServiceDialog = useDialog();
  const createContact = useContact();

  const sendContact = useCallback(() => {
    const values = {
      name:
        (listDevice.data[0].lastName ?? '') +
        (listDevice.data[0].firstName ?? ''),
      email: getSagawa.data.sendAddress ?? '',
      subject: '佐川急便サービス解約の申し込み',
      text: `宅配ボックスID: ${
        listDevice.data[0].deviceId ?? ''
      }\nユーザーID: ${getSagawa.data.userId ?? ''}\n機器番号: ${
        getSagawa.data.hubMacAddress ?? ''
      }`,
      isFileUploaded: false,
      fileNameMappings: [],
    };

    createContact.handleSubmit(values);
    cancelServiceDialog.handleClose();
  }, [cancelServiceDialog, createContact, listDevice.data, getSagawa.data]);

  return (
    <GenericTemplate loading={listDevice.isLoading || getSagawa.isLoading}>
      <DeliveryReserveServiceHeader />
      {getSagawa.isError && (
        <Box mb={2}>
          <MuiAlert severity="error">{getSagawa.errorMessage}</MuiAlert>
        </Box>
      )}
      {updateSagawa.isError && (
        <Box mb={2}>
          <MuiAlert severity="error">{updateSagawa.errorMessage}</MuiAlert>
        </Box>
      )}
      {updateSagawa.isCompleted && (
        <Box mb={2}>
          <MuiAlert severity="success">設定しました。</MuiAlert>
        </Box>
      )}
      {createContact.isCompleted && (
        <Box mb={2}>
          <MuiAlert severity="success">解約を申し込みました。</MuiAlert>
        </Box>
      )}
      {createContact.isError && (
        <Box mb={2}>
          <MuiAlert severity="error">解約の申し込みに失敗しました。</MuiAlert>
        </Box>
      )}

      <MessageBox>
        <Typography variant="body2" component="div">
          ■宅配ボックスの自動予約とは
          <br />
          佐川急便から確実にお届けものを配達してもらえる設定です。
        </Typography>
      </MessageBox>

      {getSagawa.isCompleted && (
        <>
          {updateSagawa.isLoading ? (
            <Box mt={4}>
              <LoadingIndicator />
            </Box>
          ) : (
            <form className={classes.form} onSubmit={formik.handleSubmit}>
              <FormRadioGroup
                defaultValue={formik.initialValues.reserved}
                label="配達予約の設定"
                items={[
                  {
                    label: '宅配ボックスの自動予約をする',
                    value: 'reservedAndNotifyUnlockNumber',
                    description:
                      '予約者のみ利用可能な「宅配業者の設定番号(配達用)」が設定され、配達予約が行われます。',
                  },
                  {
                    label: '宅配ボックスの自動予約をしない',
                    value: 'notifyUnlockNumber',
                    description:
                      '既に荷物が入っている場合は、「宅配業者の設定番号(配達用)」を利用し、複数荷物を受け取ることができます。',
                  },
                ]}
                name="reserved"
                onChange={formik.handleChange}
                fullWidth
              />
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                設定する
              </Button>
            </form>
          )}
          <Button
            color="secondary"
            onClick={cancelServiceDialog.handleOpen}
            variant="outlined"
            className={classes.cancelServiceButton}
          >
            佐川急便サービスを解約する
          </Button>

          <Dialog
            onClose={cancelServiceDialog.handleClose}
            fullWidth
            aria-labelledby="dialog-title"
            open={cancelServiceDialog.open}
          >
            <DialogTitle disableTypography id="dialog-title">
              <Typography variant="h6" component="p" color="primary">
                佐川急便サービスの解約
              </Typography>
            </DialogTitle>
            <DialogContent>
              以下の解約ボタンをタップすると、解約の手続き処理を開始します。
              <br />
              数日後、手続き完了の旨をメールにてご連絡差し上げますので、今しばらくお待ちください。
              <br />
              <br />
              ※解約後は、スマートクラブで「宛先追加用&nbsp;メールアドレス」を削除してください。
              <br />
              ※解約後は、登録前状態に戻ります。
              <br />
              ※「佐川急便&nbsp;スマートクラブ」の解約は行いません。引き続きご利用いただけます。
            </DialogContent>
            <DialogActions>
              <Button
                onClick={cancelServiceDialog.handleClose}
                variant="contained"
                color="default"
                autoFocus
              >
                キャンセル
              </Button>
              <Button
                onClick={sendContact}
                variant="contained"
                color="secondary"
              >
                解約する
              </Button>
            </DialogActions>
          </Dialog>
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryReservationSettingPage;
