import { useState } from 'react';

export const useDialog = (): {
  open: boolean;
  handleOpen: () => void;
  handleClose: () => void;
} => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  return { open, handleOpen, handleClose };
};
