import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import AuthService from '../../utils/AuthService';

const LogoutPage: React.FC<RouteComponentProps> = (props) => {
  AuthService.removeSession();

  const params = new URLSearchParams(props.location.search);
  const redirect = params.get('redirect');

  if (redirect) {
    window.location.assign(redirect);
  } else {
    window.location.assign('/');
  }

  return <></>;
};

export default LogoutPage;
