import Axios, { AxiosResponse } from 'axios';
import { useState, useCallback } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

interface CreateSagawaServiceResponse {
  hubMacAddress: string;
  sendAddress: string;
  receiveAddress: string;
  status: string;
  reserved: boolean;
  createAt: number;
}

export const useCreateSagawaService = (): {
  data: CreateSagawaServiceResponse;
  handleSubmit: (deviceId: string) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<CreateSagawaServiceResponse>({
    hubMacAddress: '',
    sendAddress: '',
    receiveAddress: '',
    status: '',
    reserved: true,
    createAt: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async (deviceId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response: AxiosResponse<CreateSagawaServiceResponse> =
        await Axios.post(
          `${REACT_APP_API_ENDPOINT}/sagawa`,
          {
            deviceId: deviceId,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { data, handleSubmit, isCompleted, isLoading, isError };
};
