import React, { useEffect, useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import LaunchIcon from '@material-ui/icons/Launch';

import manualCleaning01 from '../../../assets/manual-cleaning-01.png';
import manualCleaning02 from '../../../assets/manual-cleaning-02.png';
import manualCleaning03 from '../../../assets/manual-cleaning-03.png';
import { useGetUser } from '../../../hooks/useGetUser';
import MessageBox from '../../molecules/MessageBox';
import PickupRequestServiceHeader from '../../organisms/PickupRequestServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (): StyleRules => ({
    demoWrapper: {
      fontSize: 0,
      margin: '0 auto',
    },
    icon: {
      verticalAlign: 'bottom',
      marginLeft: '2px',
    },
  }),
);

const PickupRequestIntroPage: React.FC = () => {
  const classes = useStyles();
  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const [canUseCleaning, setCanUseCleaning] = useState(false);

  useEffect(() => {
    if (getUser.data.scopes.includes('members_site_use_cleaning')) {
      setCanUseCleaning(true);
    }
  }, [getUser]);

  return (
    <GenericTemplate loading={getUser.isLoading}>
      <PickupRequestServiceHeader showMenu={canUseCleaning} />
      <Box mb={3}>
        <MessageBox color="inherit">
          現在、サービス開発中につき、実際のご利用イメージをご紹介しています。
          <br />
          ※昨年、江東区江戸川区にお住まいの方を対象に実証プロジェクトを実施しました。
          <br />
          <Link
            href="https://newsrelease.lixil.co.jp/news/pdf/2020062401.pdf"
            target="_blank"
            color="primary"
          >
            実証プロジェクトの内容はこちら
            <LaunchIcon fontSize="small" className={classes.icon} />
          </Link>
          <br />
          <br />
          本サービスにご協力いただけるクリーニング店様を募集しております。
          <br />
          ご興味のある企業様は、ぜひ
          <Link component={RouterLink} to={`/contact`}>
            お問い合わせ
          </Link>
          よりご連絡ください。
        </MessageBox>
      </Box>
      <Grid container>
        <Grid item xs={12} sm={10} md={8} className={classes.demoWrapper}>
          <img
            src={manualCleaning01}
            alt="「クリーニングサービス」では、クリーニングの申し込み、集荷、受け取りが宅配ボックスを通して行えます。"
            width="100%"
          />
          <RouterLink to="/services/pickup-requests">
            <img
              src={manualCleaning02}
              alt="あずけるBOXからクリーニングの集荷日を予約"
              width="100%"
            />
          </RouterLink>
          <img
            src={manualCleaning03}
            alt="クリーニング完了後に宅配ボックスお届け"
            width="100%"
          />
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default PickupRequestIntroPage;
