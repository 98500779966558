import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
  spacing: (factor) => `${0.5 * factor}rem`,
  typography: {
    button: {
      textTransform: 'none',
    },
    fontFamily: [
      'Noto Sans JP',
      'Lato',
      '游ゴシック Medium',
      '游ゴシック体',
      'Yu Gothic Medium',
      'YuGothic',
      'ヒラギノ角ゴ ProN',
      'Hiragino Kaku Gothic ProN',
      'メイリオ',
      'Meiryo',
      'ＭＳ Ｐゴシック',
      'MS PGothic',
      'sans-serif',
    ].join(','),
  },
  palette: {
    primary: { main: '#1dbb90', contrastText: '#fff' },
  },
});

export default theme;
