import React, { forwardRef } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as IconDelivery } from '../../assets/icon-delivery.svg';
import manualSagawa from '../../assets/manual-sagawa.png';
import { useDialog } from '../../hooks/useDialog';
import PageTitleBar from '../atoms/PageTitleBar';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      height: '50px',
      width: '50px',
      padding: '6px',
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.grey[800],
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    button: {
      marginRight: '0.2rem',
      marginLeft: '0.2rem',
      padding: '1px 10px',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DeliveryReservationServiceHeader: React.FC<{ hideMenu?: boolean }> = ({
  hideMenu,
}) => {
  const classes = useStyles();
  const usageTopDialog = useDialog();

  return (
    <>
      <Box mb={2}>
        <PageTitleBar>サービス</PageTitleBar>
        <Box textAlign="center">
          <IconDelivery className={classes.icon} />
        </Box>
        <Box fontWeight="bold" textAlign="center" mb={1}>
          <Typography className={classes.text}>佐川急便サービス</Typography>
        </Box>
        <Box textAlign="center">
          {hideMenu !== true && (
            <>
              <Button
                className={classes.button}
                component={Link}
                to="/services/delivery-reservations"
                color="primary"
                variant="outlined"
              >
                トップ
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="/services/delivery-reservations/histories"
                color="primary"
                variant="outlined"
              >
                利用履歴
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="/services/delivery-reservations/setting"
                color="primary"
                variant="outlined"
              >
                設定
              </Button>
            </>
          )}
          <Button
            className={classes.button}
            onClick={usageTopDialog.handleOpen}
            color="primary"
            variant="outlined"
          >
            使い方
          </Button>
        </Box>
      </Box>
      <Dialog
        onClose={usageTopDialog.handleClose}
        open={usageTopDialog.open}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={usageTopDialog.handleClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              使い方
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.dialogBody}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <img
              src={manualSagawa}
              alt="「佐川急便サービス」では、宅配ボックスの自動予約や複数の荷物の受け取りが行えます。"
              width="100%"
            />
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DeliveryReservationServiceHeader;
