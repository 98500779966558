import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { DateTime } from 'luxon';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import MuiAlert from '@material-ui/lab/Alert';

import GenericTemplate from '../../templates/GenericTemplate';
import DeviceHistoryListItem from '../../molecules/DeviceHistoryListItem';
import { useGetDevice } from '../../../hooks/useGetDevice';
import { useGetDeviceHistory } from '../../../hooks/useGetDeviceHistory';

const useStyles = makeStyles(
  (): StyleRules => ({
    list: {
      marginBottom: '1rem',
    },
  }),
);

const DeliveryBoxHistoryPage: React.FC = () => {
  const classes = useStyles();
  const { deviceId } = useParams<{ deviceId: string }>();

  const device = useGetDevice();

  useEffect(() => {
    if (!device.isCompleted && !device.isError && !device.isLoading) {
      device.getData(deviceId);
    }
  }, [device, deviceId]);

  const deviceHistories = useGetDeviceHistory();

  useEffect(() => {
    if (
      !deviceHistories.isCompleted &&
      !deviceHistories.isError &&
      !deviceHistories.isLoading
    ) {
      deviceHistories.getData(deviceId);
    }
  }, [deviceHistories]);

  const years = deviceHistories.data.deviceHistories
    .map((history) => {
      return DateTime.fromMillis(history.createAt).toLocal().year;
    })
    .filter((x, i, self) => self.indexOf(x) === i)
    .sort((a, b) => b - a);

  return (
    <GenericTemplate
      title={`${
        device.data.nickName ? device.data.nickName : '宅配ボックス'
      }の利用履歴`}
      loading={deviceHistories.isLoading || device.isLoading}
    >
      {deviceHistories.isError ? (
        <MuiAlert severity="error">利用履歴が取得できませんでした</MuiAlert>
      ) : deviceHistories.isCompleted &&
        deviceHistories.data.deviceHistories.length === 0 ? (
        <MuiAlert severity="info">利用履歴はありません</MuiAlert>
      ) : (
        years.map((year) => {
          return (
            <div key={year}>
              <Typography component="h3" color="primary" gutterBottom>
                {year}
              </Typography>
              <List className={classes.list}>
                {deviceHistories.data.deviceHistories
                  .filter(
                    (history) =>
                      DateTime.fromMillis(history.createAt).toLocal().year ===
                      year,
                  )
                  .map((history, index) => {
                    const deliveryBox =
                      device.data.deviceId === history.deviceId
                        ? device.data
                        : undefined;
                    const nickName = deliveryBox?.nickName;

                    return (
                      <DeviceHistoryListItem
                        history={history}
                        nickName={nickName}
                        deviceId={deviceId}
                        key={index}
                      />
                    );
                  })}
              </List>
            </div>
          );
        })
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxHistoryPage;
