import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import {
  DeliveryStatus,
  useListDelivery,
} from '../../../hooks/useListDelivery';
import { useListDevice } from '../../../hooks/useListDevice';
import PageTitleBar from '../../atoms/PageTitleBar';
import MessageBox from '../../molecules/MessageBox';
import DeliveryReservationServiceHeader from '../../organisms/DeliveryReservationServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiButtonBase-root': {
        padding: 'unset',
      },
      '& .MuiListItem-root': {
        paddingBottom: theme.spacing(1),
      },
    },
    link: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      color: 'inherit',
      textDecoration: 'none',
      padding: theme.spacing(0, 0.5),
    },
    status: {
      width: '28%',
      alignSelf: 'stretch',
      fontWeight: 'bold',
      borderRight: `2px solid ${theme.palette.primary.main}`,
      marginRight: '5%',
      paddingRight: '2%',
    },
  }),
);

const getStatusText = (status: DeliveryStatus) => {
  if (status === 'scheduledReserve') return '配達予定';
  if (status === 'reserved') return '予約済み';
  if (status === 'delivered') return '配達完了';
  if (status === 'undeliveredForAbsence') return '不在完了';
  if (status === 'cancelledReserveBySystem') return 'キャンセル';
  if (status === 'cancelledReserveByMember') return 'キャンセル';
  if (status === 'received') return '受取り済み';

  return '不明';
};

const DeliveryReservationHistoryPage: React.FC = () => {
  const classes = useStyles();

  const listDeliveryBox = useListDevice();

  useEffect(() => {
    if (!listDeliveryBox.isCompleted && !listDeliveryBox.isLoading) {
      listDeliveryBox.getData();
    }
  }, [listDeliveryBox]);

  const listDelivery = useListDelivery();

  useEffect(() => {
    if (
      listDeliveryBox.data.length > 0 &&
      !listDelivery.isCompleted &&
      !listDelivery.isError &&
      !listDelivery.isLoading
    ) {
      listDelivery.getData(listDeliveryBox.data[0].deviceId);
    }
  }, [listDelivery, listDeliveryBox]);

  return (
    <GenericTemplate
      loading={listDeliveryBox.isLoading || listDelivery.isLoading}
    >
      <DeliveryReservationServiceHeader />
      <PageTitleBar>ご利用状況の一覧</PageTitleBar>
      {listDelivery.isError && (
        <MessageBox color="secondary">
          佐川急便サービスのご利用状況が取得出来ませんでした。
        </MessageBox>
      )}
      {listDelivery.isCompleted && (
        <>
          {listDelivery.data.length === 0 && (
            <MuiAlert severity="info">利用履歴はありません</MuiAlert>
          )}
          <List className={classes.list}>
            {listDelivery.data.map((delivery, index) => (
              <ListItem button key={index}>
                <Link
                  to={`/services/delivery-reservations/${delivery.deliveryId}/view`}
                  className={classes.link}
                >
                  <Typography color="primary" className={classes.status}>
                    {getStatusText(delivery.status)}
                  </Typography>
                  <Box>
                    <Typography className={classes.detail}>
                      問い合わせ番号: {delivery.trackingNumber}
                    </Typography>
                    <Typography className={classes.detail}>
                      配達予定日:{' '}
                      {DateTime.fromFormat(
                        delivery.deliveryScheduleAt,
                        'yyyy-MM-dd',
                      ).toFormat('yyyy/MM/dd')}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryReservationHistoryPage;
