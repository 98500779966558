import Axios from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

type scope =
  | 'test_user'
  | 'business_site_user'
  | 'business_site_use_delivery'
  | 'business_site_use_cleaning'
  | 'members_site_use_cleaning'
  | '';

export interface SubscriptionEmail {
  deliverySchedule: boolean;
  deliveryReservation: boolean;
  deliveryReservationCancel: boolean;
  deliveryComplete: boolean;
  deliveryReceive: boolean;
  pickupRequest: boolean;
  pickupRequestCencel: boolean;
  pickupAcceptance: boolean;
  pickupRejected: boolean;
  pickupComplete: boolean;
  hubReboot: boolean;
  shareDeliveryReserve: boolean;
  shareDeliveryCancel: boolean;
  shareDeliveryComplete: boolean;
  shareDeliveryExpire: boolean;
  shareCollectReserve: boolean;
  shareCollectCancel: boolean;
  shareCollectComplete: boolean;
  shareCollectExpire: boolean;
  shareCollectNotPickupModeRemind: boolean;
}

export interface User {
  userId: string;
  scopes: [scope];
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  state: string;
  address1: string;
  address2: string;
  building: string;
  phoneNumber: string;
  createAt: number;
  subscriptionEmail: SubscriptionEmail;
  smartExteriorApiTosVersion?: string;
  checkedTutorial?: boolean;
  lastReadDateForNotice?: string;
  lastReadDateForDeviceHistory?: string;
}

export const useGetUser = (): {
  getData: () => Promise<void>;
  data: User;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<User>({
    userId: '',
    scopes: [''],
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    state: '',
    address1: '',
    address2: '',
    building: '',
    phoneNumber: '',
    createAt: 0,
    subscriptionEmail: {
      deliverySchedule: false,
      deliveryReservation: false,
      deliveryReservationCancel: false,
      deliveryComplete: false,
      deliveryReceive: false,
      pickupRequest: false,
      pickupRequestCencel: false,
      pickupAcceptance: false,
      pickupRejected: false,
      pickupComplete: false,
      hubReboot: false,
      shareDeliveryReserve: false,
      shareDeliveryCancel: false,
      shareDeliveryComplete: false,
      shareDeliveryExpire: false,
      shareCollectReserve: false,
      shareCollectCancel: false,
      shareCollectComplete: false,
      shareCollectExpire: false,
      shareCollectNotPickupModeRemind: false,
    },
    smartExteriorApiTosVersion: '',
    checkedTutorial: false,
    lastReadDateForNotice: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async () => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(`${REACT_APP_API_ENDPOINT}/user`, {
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${idToken}`,
        },
      });

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError };
};
