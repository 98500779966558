import React from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

import GenericTemplate from '../templates/GenericTemplate';

const PrivacyPage: React.FC = () => {
  return (
    <GenericTemplate title="プライバシーポリシー">
      <Box mb={3}>
        <Typography component="p" variant="body2" gutterBottom>
          スマートエクステリアは、サービス向上と機能充実のために、クラウドサービスと連携します。
          <br />
          クラウドサービスとは、以下に記載する「取り扱う情報」をやり取りします。
          <br />
          お預かりする個人情報は、LIXILプライバシーポリシーに則って管理、運用します。
        </Typography>
        <Typography component="p" variant="body2" gutterBottom>
          プライバシーポリシーについての詳細は、下記URLを確認してください。
          <br />
          <a
            href="http://www.lixil.co.jp/privacy/"
            target="_blank"
            rel="noopener noreferrer"
          >
            http://www.lixil.co.jp/privacy/
          </a>
        </Typography>
      </Box>
      <Box mb={3}>
        <Typography component="h3" variant="h6" gutterBottom>
          取り扱う情報
        </Typography>
        <Typography component="div" variant="body2" gutterBottom>
          <ol style={{ margin: '0', paddingInlineStart: '20px' }}>
            <li>各機器の作動情報の通知</li>
            <li>各機器の操作</li>
            <li>宅配ポストの記録映像</li>
            <li>スマートフォンのGPS情報</li>
          </ol>
        </Typography>
        <Typography component="p" variant="body2">
          ※GPS情報は、該当機器の盗難検知や機器連動の検出に使用します。
        </Typography>
      </Box>
    </GenericTemplate>
  );
};

export default PrivacyPage;
