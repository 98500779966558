import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { useListDevice } from '../../../hooks/useListDevice';
import { useGetDeviceHistory } from '../../../hooks/useGetDeviceHistory';
import PageTitleBar from '../../atoms/PageTitleBar';
import MessageBox from '../../molecules/MessageBox';
import DemoServiceHeader from '../../organisms/DemoRentalServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const OIDC_CLIENT_ID = process.env.REACT_APP_RENTAL_SERVICE_JANRAIN_CLIENT_ID!;

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiButtonBase-root': {
        padding: 'unset',
      },
      '& .MuiListItem-root': {
        paddingBottom: theme.spacing(1),
      },
    },
    detail: {
      width: '71%',
    },
    status: {
      width: '24%',
      alignSelf: 'stretch',
      fontWeight: 'bold',
      borderRight: `2px solid ${theme.palette.primary.main}`,
      marginRight: '5%',
      paddingRight: '2%',
    },
  }),
);

const getStatusText = (status: string) => {
  if (status === 'getUnlockNumber') return '解錠番号取得成功';
  if (status === 'getUnlockNumberFailed') return '解錠番号取得失敗';
  if (status === 'pickupRequest') return '集荷前通知';
  if (status === 'pickupComplete') return '集荷完了';
  if (status === 'deliveryComplete') return '配達完了';

  return '不明';
};

const DemoServiceHistoryPage: React.FC = () => {
  const classes = useStyles();

  const listDeliveryBox = useListDevice();

  useEffect(() => {
    if (!listDeliveryBox.isCompleted && !listDeliveryBox.isLoading) {
      listDeliveryBox.getData();
    }
  }, [listDeliveryBox]);

  const deviceHistories = useGetDeviceHistory();

  useEffect(() => {
    if (
      listDeliveryBox.data.length > 0 &&
      !deviceHistories.isCompleted &&
      !deviceHistories.isError &&
      !deviceHistories.isLoading
    ) {
      deviceHistories.getData(listDeliveryBox.data[0].deviceId, OIDC_CLIENT_ID);
    }
  }, [deviceHistories, listDeliveryBox]);

  return (
    <GenericTemplate
      loading={listDeliveryBox.isLoading || deviceHistories.isLoading}
    >
      <DemoServiceHeader />
      <PageTitleBar>ご利用状況の一覧</PageTitleBar>
      {deviceHistories.isError && (
        <MessageBox color="secondary">
          ご利用状況が取得出来ませんでした。
        </MessageBox>
      )}
      {deviceHistories.isCompleted && (
        <>
          {deviceHistories.data.deviceHistories.length === 0 && (
            <MuiAlert severity="info">利用履歴はありません</MuiAlert>
          )}
          <List className={classes.list}>
            {deviceHistories.data.deviceHistories.map((history, index) => {
              const deliveryBox = listDeliveryBox.data.find(
                (deliveryBox) => deliveryBox.deviceId === history.deviceId,
              );

              return (
                <ListItem key={index} disableGutters>
                  <Typography color="primary" className={classes.status}>
                    {getStatusText(history.type)}
                  </Typography>
                  <Box className={classes.detail}>
                    <Typography>
                      {DateTime.fromMillis(history.createAt)
                        .toLocal()
                        .toFormat('yyyy/MM/dd HH:mm')}
                    </Typography>
                    {deliveryBox?.lastName && deliveryBox?.firstName && (
                      <Typography>
                        {deliveryBox.lastName + deliveryBox.firstName}
                      </Typography>
                    )}
                    {deliveryBox?.nickName && (
                      <Typography>{deliveryBox.nickName}</Typography>
                    )}
                    {history.type === 'getUnlockNumberFailed' && (
                      <Typography>
                        {history.reason === 'smartPhoneMode' ? (
                          '伺いましたが、「スマートフォンで解錠」設定になっていたため、番号による解錠が出来ませんでした。'
                        ) : history.reason === 'noneMode' ? (
                          '伺いましたが、「解錠しない」設定になっていたため、番号による解錠が出来ませんでした。'
                        ) : history.reason === 'reserved' ? (
                          '伺いましたが、他サービスで利用されていたため、番号による解錠が出来ませんでした。'
                        ) : history.reason === 'freeMode' ? (
                          <>
                            伺いましたが、「設定番号なし」設定になっていたため、番号による解錠は出来ませんでした。
                            <br />
                            ※なお、荷物が投函されている場合もありますので、宅配ボックスをご確認ください。
                          </>
                        ) : null}
                      </Typography>
                    )}
                  </Box>
                </ListItem>
              );
            })}
          </List>
        </>
      )}
    </GenericTemplate>
  );
};

export default DemoServiceHistoryPage;
