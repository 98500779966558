import Axios from 'axios';
import { useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface SubscriptionEmail {
  deliverySchedule?: boolean;
  deliveryReservation?: boolean;
  deliveryReservationCancel?: boolean;
  deliveryComplete?: boolean;
  deliveryReceive?: boolean;
  pickupRequest?: boolean;
  pickupRequestCencel?: boolean;
  pickupAcceptance?: boolean;
  pickupRejected?: boolean;
  pickupComplete?: boolean;
  hubReboot?: boolean;
}

export interface User {
  firstName?: string;
  lastName?: string;
  firstNameKana?: string;
  lastNameKana?: string;
  state?: string;
  address1?: string;
  address2?: string;
  building?: string;
  phoneNumber?: string;
  createAt?: number;
  smartExteriorApiTosVersion?: string;
  subscriptionEmail?: SubscriptionEmail;
  checkedTutorial?: boolean;
  lastReadDateForNotice?: string;
  lastReadDateForDeviceHistory?: string;
}

export const useUpdateUser = (): {
  handleSubmit: (values: User) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = async (values: User) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      await Axios.put(
        `${REACT_APP_API_ENDPOINT}/user`,
        {
          ...values,
        },
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  };

  return { handleSubmit, isCompleted, isLoading, isError };
};
