import Axios from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 宅配ボックスの状態
 *
 * @returns collection: 集荷待ち
 * @returns empty: 荷物なし
 * @returns exist: 荷物あり
 * @returns reserved: 予約中
 * @returns busy: 設定変更中
 * @returns unknown: 不明
 */
export type DeliveryBoxStatus =
  | 'collection'
  | 'empty'
  | 'exist'
  | 'reserved'
  | 'busy'
  | 'unknown';

/**
 * 宅配ボックスを使用しているサービス
 *
 * @returns deliveryReserve: 配達予約
 * @returns pickupRequest: 集荷依頼
 * @returns deliveryBoxShareDelivery: あずけるボックス（配達）
 * @returns deliveryBoxSharePickup: あずけるボックス（集荷）
 * @returns unknown: 不明
 */

export type Service =
  | 'deliveryReserve'
  | 'pickupRequest'
  | 'deliveryBoxShareDelivery'
  | 'deliveryBoxSharePickup'
  | 'unknown';

export interface Device {
  deviceId: string;
  hubMacAddress: string;
  cloudService: boolean;
  yourPassword?: string;
  deliveryPassword?: string;
  collectionPassword?: string;
  unlockMethodFirst?: string;
  unlockMethodSecondOnward?: string;
  status: DeliveryBoxStatus;
  isHubUpdateAvailable?: boolean;
  isHubConnectAvailable?: boolean;
  service?: Service;
  nickName?: string;
  firstName?: string;
  lastName?: string;
  firstNameKana?: string;
  lastNameKana?: string;
  phoneNumber?: string;
  email?: string;
  zip?: string;
  state?: string;
  address1?: string;
  address2?: string;
  building?: string;
}

export const useGetDevice = (): {
  getData: (deviceId: string) => Promise<void>;
  data: Device;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<Device>({
    deviceId: '',
    hubMacAddress: '',
    yourPassword: '',
    cloudService: false,
    deliveryPassword: '',
    collectionPassword: '',
    unlockMethodFirst: '',
    unlockMethodSecondOnward: '',
    status: 'unknown',
    isHubUpdateAvailable: false,
    isHubConnectAvailable: false,
    service: 'unknown',
    nickName: '',
    firstName: '',
    lastName: '',
    firstNameKana: '',
    lastNameKana: '',
    phoneNumber: '',
    email: '',
    zip: '',
    state: '',
    address1: '',
    address2: '',
    building: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (deviceId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/devices/${deviceId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return {
    getData,
    data,
    isCompleted,
    isLoading,
    isError,
  };
};
