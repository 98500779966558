import React, { useEffect, useState } from 'react';

import lixilLogo from '../../assets/desc-lixil-logo.png';
import logo from '../../assets/desc-logo.png';
import service01 from '../../assets/desc-services-image-01.png';
import service04 from '../../assets/desc-services-image-04.png';
import { useListNotice } from '../../hooks/useListNotice';
import AuthService from '../../utils/AuthService';

const NoticeSectionHtml = () => {
  const listNotice = useListNotice();

  const noticeData = listNotice.data
    .map((notice) => {
      return {
        date: notice.date.getDate(),
        year: notice.date.getFullYear(),
        month: ('0' + (notice.date.getMonth() + 1)).slice(-2),
        day: ('0' + notice.date.getDate()).slice(-2),
        title: notice.title,
        body: notice.body,
      };
    })
    .sort((a, b) => (b.date < a.date ? -1 : 0))
    .sort((a, b) => (Number(b.month) < Number(a.month) ? -1 : 0));

  const years = noticeData
    .map((notice) => {
      return notice.year;
    })
    .filter((x, i, self) => self.indexOf(x) === i)
    .sort((a, b) => b - a);

  const html = years.map((year) =>
    noticeData
      .filter((notice) => notice.year === year)
      .map(
        (notice, index) =>
          `<div class="panel panel-default">
             <div class="panel-heading">
               <h4 class="panel-title">
                 <a data-toggle="collapse" data-parent="#accordion_2" href="#c_${index}_accordion_2" class="collapsed">
                   ${year}/${notice.month}/${notice.day}&nbsp;${notice.title}
                 </a>
               </h4>
             </div>
             <div id="c_${index}_accordion_2" class="panel-collapse collapse" style="height: 0px;">
               <div class="panel-body">${notice.body}</div>
             </div>
           </div>`,
      ),
  );

  return html.length > 0
    ? html[0].slice(0, 5).join('')
    : 'お知らせはありません';
};

const Descreption: React.FC = () => {
  const [isLoadedjQuery, setIsLoadedjQuery] = useState(false);

  const appendScriptTag = (url: string, needToOnLoad?: boolean) => {
    const script = document.createElement('script');
    script.src = url;
    if (needToOnLoad) {
      script.onload = () => setIsLoadedjQuery(true);
    }
    document.body.appendChild(script);
  };

  useEffect(() => {
    if (!isLoadedjQuery) {
      appendScriptTag('/js/jquery-1.11.2.js', true);
    }
  }, [isLoadedjQuery]);

  useEffect(() => {
    if (isLoadedjQuery) {
      appendScriptTag('/js/jquery-ui.js');
      appendScriptTag('/js/bootstrap.js');
      appendScriptTag('/js/velocity.min.js');
      appendScriptTag('/js/velocity.ui.min.js');
      appendScriptTag('/js/appear.min.js');
      appendScriptTag('/js/jquery.fancybox.min.js');
      appendScriptTag('/js/custom.js');
    }
  }, [isLoadedjQuery]);

  const dangerouslySetInnerHtmlClickHandler = (
    e: React.MouseEvent<HTMLDivElement | MouseEvent>,
  ) => {
    const el = e.target as HTMLButtonElement;

    if (el && el.name === 'login') {
      AuthService.login({
        redirectUri: `${window.location.origin}/callback`,
        prompt: 'login',
      });
    }
  };

  return (
    <div
      onClick={dangerouslySetInnerHtmlClickHandler}
      dangerouslySetInnerHTML={{
        __html: `
      <link rel="stylesheet" type="text/css" href="/css/bootstrap.css" />
      <link rel="stylesheet" type="text/css" href="/css/font-awesome.min.css" />
      <link rel="stylesheet" type="text/css" href="/css/jquery.fancybox.min.css" />
      <link rel="stylesheet" type="text/css" href="/css/pix_style.css" />
      <link rel="stylesheet" type="text/css" href="/css/main.css" />
      <link rel="stylesheet" type="text/css" href="/css/font-style.css" />
      <link href="/css/animations.min.css" rel="stylesheet" type="text/css" media="all" />
      <!--[if IE]>
        <link rel="stylesheet" type="text/css" href="css/ie-fix.css" />
      <![endif]-->

      <main class="desctiption_page_root">
        <header id="section_1" class="pix_section pix_nav_menu pix_scroll_header normal pix-padding-v-20"
          data-scroll-bg="#fff">
          <div class="container">
            <div class="row">
              <div class="col-md-8 col-xs-6 col-sm-8 column ui-droppable">
                <div class="pix-content">
                  <nav class="navbar navbar-default pix-no-margin-bottom pix-navbar-default">
                    <a class="navbar-brand logo-img logo-img-a pix-adjust-height" href="/">
                      <img src="${logo}" alt="" style="width: 100%;">
                    </a>
                  </nav>
                </div>
              </div>
              <div class="col-md-4 col-xs-6 col-sm-4 column ui-droppable">
                <div class="pix-content pix-adjust-height text-right" id="pix-header-btn" style="margin-top: 13px;">
                  <button name="login" class="btn btn-md small-text pix-white pix-inline-block"
                    style="background: #1dbb90; padding: 12px 20px; font-weight: bold;">
                    ログイン / 会員登録
                  </a>
                </div>
              </div>
            </div>
          </div>
        </header>
        <section id="section_2" class="pix_section pix-padding-v-150 pix-business-1"
          data-bg-img="linear-gradient(rgba(0,0,0,0.4) ,rgba(0,0,0,0.4))" style="background-repeat: no-repeat;">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content">
                  <h2 class="pix-white">
                    <span class="pix_edit_text">住まいと社会をIoTでつなぎます<br>シェアリングサービスを宅配ボックスから</span>
                  </h2>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_3" class="pix_section pix-padding-v-30">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content pix-padding-v-30 text-center">
                  <h2 class="pix-black-gray-dark text-center pix-no-margin-top">
                    <span class="pix_edit_text">サービス一覧</span>
                  </h2>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content pix-padding-v-10 pix-margin-v-30 pix-inline-block">
                  <div class="col-md-6 col-xs-12 col-sm-4 pix-inner-col column ui-droppable">
                    <div class="pix-content pix-margin-v-10">
                      <img src="${service01}" alt="" class="img-responsive pix-radius-3">
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-8 pix-inner-col column ui-droppable">
                    <div class="pix-content">
                      <h5 class="pix-no-margin-top pix-black-gray-dark">
                        <span class="pix_edit_text"><strong>あずけるボックス<br></strong></span>
                      </h5>
                      <p class="pix-black-gray-light pix-no-margin-top pix-margin-bottom-10">
                        <span
                          class="pix_edit_text">個人間で大切なものをシェアできるサービスです。予定が合わない時でも、お届けものを宅配ボックスを介して渡したり（もってって）、受け取ったり（もってきて）を簡単、スマートにやりとりできます。</span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-6 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content pix-padding-v-10 pix-margin-v-30 pix-inline-block">
                  <div class="col-md-6 col-xs-12 col-sm-4 pix-inner-col column ui-droppable">
                    <div class="pix-content pix-margin-v-10">
                      <img src="${service04}" alt="" class="img-responsive pix-radius-3">
                    </div>
                  </div>
                  <div class="col-md-6 col-xs-12 col-sm-8 pix-inner-col column ui-droppable">
                    <div class="pix-content">
                      <h5 class="pix-no-margin-top pix-black-gray-dark">
                        <span class="pix_edit_text"><b>スマートスピーカー Alexa連携</b></span>
                      </h5>
                      <p class="pix-black-gray-light pix-no-margin-top pix-margin-bottom-10">
                        <span class="pix_edit_text">家に帰ったらAlexaが荷物が届いていることをお知らせしてくれます。<br></span>
                      </p>
                      <a href="https://www.amazon.co.jp/dp/B07YLD37QK" target="_blank"
                        class="btn blue-neon-bg pix-white wide" style="background: #1dbb90">
                        <span class="pix_edit_text">
                          <strong>Alexaスキルはこちら</strong>
                        </span>
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_4" class="pix_section pix-padding-v-75 pix-business-2"
          data-bg-img="linear-gradient( rgba(0,0,0,0.4) ,rgba(0,0,0,0.4))"
          style="background-repeat: no-repeat;">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content pix-padding-v-40">
                  <div class="media">
                    <div class="media-body media-top pix-no-h-padding">
                      <h2 class="pix-white pix-no-margin-top">
                        <span class="pix_edit_text">スマート宅配ポスト</span>
                      </h2>
                      <h6 class="pix-margin-bottom-20 pix-margin-top-40">
                        <span class="pix-white pix_edit_text">
                          LIXILのスマート宅配ポストは、家にいる時も、いない時も、荷物の受け取りに加え、発送もできます。<br>スマートフォンと連携すればさらに便利に安心に。
                        </span>
                      </h6>
                      <div class="media-middle text-left media-box-area">
                        <a href="https://www.lixil.co.jp/lineup/gate_fence/smartpost/" target="_blank"
                          class="btn pix-white btn-md wide" style="background: #1dbb90">
                          <span class="pix_edit_text">
                            <strong>詳細はこちら</strong>
                          </span>
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_accordions_1" class="pix_section pix-padding-v-85">
          <div class="container">
            <div class="row">
              <div class="col-xs-12 col-sm-12 column ui-droppable col-md-12">
                <div class="pix-content text-left">
                  <h2 class="pix-black-gray-dark secondary-font pix-small-width-text">
                    <span class="pix_edit_text">お知らせ</span>
                  </h2>
                </div>
              </div>
              <div class="col-xs-12 col-sm-12 column ui-droppable col-md-12">
                <div class="pix-content pix-margin-v-10 text-left">
                  <div class="pix_accordion panel-group ui-sortable" id="accordion_2">
                    ${NoticeSectionHtml()}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_5" class="pix_section pix-padding-v-85 gray-bg">
          <div class="container">
            <div class="row">
              <div class="col-md-12 col-xs-12 col-sm-12 column ui-droppable">
                <div class="pix-content text-left">
                  <h2 class="pix-black-gray-dark secondary-font pix-small-width-text">
                    <span class="pix_edit_text">MyLIXIL</span>
                  </h2>
                  <h6 class="pix-gray">
                    <span class="pix_edit_text">『あずけるBOX』は社会とつながるために、MyLIXILと連携します。MyLIXILとは、LIXILがお客様をデジタルでおもてなしするために必要な情報をお預かりし、安心してLIXILのサービスを便利にご利用できます。</span>
                    <ul class="pix-margin-top-30">
                      <li>LIXILがエンドユーザー様に提供する各サービスで同じIDとパスワードを使うことができます。</li>
                      <li>SNSなどのソーシャルアカウントと連携してログインできます。</li>
                      <li>入力した情報を他のサービスで利用できます。</li>
                    </ul>
                  </h6>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section id="section_6" class="pix_section pix-padding-v-50">
          <div class="container">
            <div class="row">
              <div class="col-md-3 col-xs-12 col-sm-3 column ui-droppable">
                <div class="pix-content pix-padding-top-20 mobile-text-center">
                  <a href="https://www.lixil.co.jp" target="_blank"><img src="${lixilLogo}" alt="" class="img-responsive pix-inline-block"></a>
                </div>
              </div>
              <div class="col-md-9 col-xs-12 col-sm-9 column ui-droppable">
                <div class="pix-content pix-padding-v-30">
                  <div class="pix-light-gray text-right mobile-text-center pix-margin-links">
                    <a href="/faq" class="btn btn-link pix-black-gray-light pix-padding-h-20"><span
                        class="pix_edit_text"><strong>FAQ</strong></span></a>
                    <a href="/contact" class="btn btn-link pix-black-gray-light pix-padding-h-20"><span
                        class="pix_edit_text"><strong>お問い合わせ</strong></span></a>
                    <a href="/support" class="btn btn-link pix-black-gray-light pix-padding-h-20"><span
                        class="pix_edit_text"><strong>サポート</strong></span></a>
                    <a href="/terms" class="btn btn-link pix-black-gray-light pix-padding-h-20"><span
                        class="pix_edit_text"><strong>利用規約</strong></span></a>
                    <a href="/privacy" class="btn btn-link pix-black-gray-light pix-padding-h-20"><span
                        class="pix_edit_text"><strong>プライバシーポリシー</strong></span></a>
                  </div>
                </div>
              </div>
              <div class="col-md-7 col-xs-12 col-sm-7 column ui-droppable">
                <div class="pix-content">
                  <div class="pix-black-gray-light big-text pix-padding-v-20 mobile-text-center">
                    <span class="pix_edit_text">Copyright © LIXIL Group Corporation. All rights reserved.</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>`,
      }}
    />
  );
};

export default Descreption;
