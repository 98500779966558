import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';

import FaqList from '../organisms/FaqList';
import GenericTemplate from '../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    content: {
      padding: theme.spacing(3, 2),
    },
  }),
);

const SupportPage: React.FC = () => {
  const classes = useStyles();

  return (
    <GenericTemplate title="よくあるご質問">
      <Paper className={classes.content}>
        <FaqList />
      </Paper>
    </GenericTemplate>
  );
};

export default SupportPage;
