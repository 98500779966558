import { useState, useCallback } from 'react';
import Axios, { AxiosResponse } from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 配達の状態
 *
 * @returns scheduledReserve: 配達予定済み
 * @returns reserved: 予約済み
 * @returns delivered: 配達完了
 * @returns undeliveredForAbsence: 不在完了
 * @returns cancelledReserveBySystem: キャンセル
 * @returns cancelledReserveByMember: キャンセル
 * @returns received: 受取り済み
 */
export type DeliveryStatus =
  | 'scheduledReserve'
  | 'reserved'
  | 'delivered'
  | 'undeliveredForAbsence'
  | 'cancelledReserveBySystem'
  | 'cancelledReserveByMember'
  | 'received';

export interface Delivery {
  deliveryId: string;
  trackingNumber: string;
  hubMacAddress: string;
  deliveryScheduleAt: string;
  status: DeliveryStatus;
}

export const useListDelivery = (): {
  getData: (deviceId: string, limit?: number) => Promise<void>;
  data: Delivery[];
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<Delivery[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (deviceId: string, limit?: number) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response: AxiosResponse<{ deliveries: Delivery[] }> =
        await Axios.get(`${REACT_APP_API_ENDPOINT}/deliveries`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            deviceId: deviceId,
            limit: limit ? limit : 30,
          },
        });

      setData(response.data.deliveries);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError };
};
