import Axios from 'axios';
import { useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export const useCancelDeliveryBoxShare = (): {
  handleSubmit: (deliveryBoxShareId: string) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const handleSubmit = (deliveryBoxShareId: string) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        await Axios.post(
          `${REACT_APP_API_ENDPOINT}/shares/${deliveryBoxShareId}/cancel`,
          {},
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        setIsCompleted(true);
      } catch (e) {
        if (e.response.status === 400) {
          if (e.response.data.error.type === 'invalid_request') {
            setErrorMessage('不正なリクエストです。');
          } else if (e.response.data.error.type === 'cloud_service_disable') {
            setErrorMessage(
              '宅配ボックスの状態を確認してください。「クラウドサービスを利用する」の設定がOFFになっている可能性があります。',
            );
          } else if (e.response.data.error.type === 'service_in_use') {
            if (e.response.data.error.service === 'deliveryReserve') {
              setErrorMessage('現在、配達予約サービスで利用されています。');
            } else if (e.response.data.error.service === 'pickupRequest') {
              setErrorMessage('現在、集荷予約サービスで利用されています。');
            } else if (e.response.data.error.service === 'deliveryBoxShare') {
              setErrorMessage(
                '現在、あずけるボックスサービスで利用されています。',
              );
            }
          } else if (e.response.data.error.type === 'in_setting') {
            setErrorMessage(
              '宅配ボックスの状態が設定中です。2分程度時間をおいてお試しください。',
            );
          } else {
            setErrorMessage('原因不明のエラーが発生しました。');
          }
        } else {
          setErrorMessage('原因不明のエラーが発生しました。');
        }
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { handleSubmit, isCompleted, isLoading, isError, errorMessage };
};
