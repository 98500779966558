import React from 'react';
import Box from '@material-ui/core/Box';
import Link from '@material-ui/core/Link';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../assets/logo-lixil.svg';
import { useDialog } from '../../hooks/useDialog';
import PageTitleBar from '../atoms/PageTitleBar';
import GenericTemplate from '../templates/GenericTemplate';
import TutorialDialog from '../organisms/TutorialDialog';

const useStyles = makeStyles(
  (): StyleRules => ({
    logo: {
      height: '32px',
    },
  }),
);

const AboutPage: React.FC = () => {
  const classes = useStyles();
  const { open, handleOpen, handleClose } = useDialog();

  return (
    <GenericTemplate title="あずけるBOXについて">
      <Typography component="p" variant="body2">
        LIXILが提供しているサービスです。
        <br />
        お使いのスマート宅配ポストをIoT製品として、スマホやパソコンで操作し、様々なサービスをご利用することができます。
      </Typography>
      <Link onClick={handleOpen} style={{ cursor: 'pointer' }}>
        はじめに
      </Link>
      {open ? <TutorialDialog open={open} onClick={handleClose} /> : <></>}
      <Box mt={3}>
        <PageTitleBar>MyLIXILについて</PageTitleBar>
        <Typography component="p" variant="body2">
          LIXILのサービスをお使いになるお客様の個人情報を管理、運用するID基盤です。
        </Typography>
      </Box>
      <Box mt={3}>
        <PageTitleBar>LIXILについて</PageTitleBar>
        <Typography component="p" variant="body2">
          LIXILは、世界中の誰もが描く住まいの夢を実現するために、日々の暮らしの課題を解決する先進的なトイレ、お風呂、キッチンなどの水まわり製品と窓、ドア、インテリア、エクステリアなどの建材製品を開発、提供しています。
          <br />
          <Link href="https://www.lixil.co.jp/" target="_blank">
            https://www.lixil.co.jp/
          </Link>
        </Typography>
      </Box>
      <Box
        textAlign="center"
        style={{
          marginTop: '100px',
        }}
      >
        <Logo className={classes.logo} />
      </Box>
    </GenericTemplate>
  );
};

export default AboutPage;
