import React, { useEffect } from 'react';

import AuthService from '../../utils/AuthService';

const LoginPage: React.FC = () => {
  useEffect(() => {
    AuthService.login({
      redirectUri: `${window.location.origin}/callback`,
      prompt: 'login',
    });
  });

  return <></>;
};

export default LoginPage;
