import * as React from 'react';
import CircularProgress from '@material-ui/core/CircularProgress';
import Grid from '@material-ui/core/Grid';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(
  (): StyleRules => ({
    circularProgressWrapper: {
      height: '100%',
    },
  }),
);

const LoadingIndicator: React.FC = () => {
  const classes = useStyles();

  return (
    <Grid
      container
      justify="center"
      alignItems="center"
      className={classes.circularProgressWrapper}
    >
      <CircularProgress />
    </Grid>
  );
};

export default LoadingIndicator;
