import React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import makeStyles from '@material-ui/core/styles/makeStyles';

import { Service } from '../../hooks/useGetDevice';

interface DeliveryBoxStatusCardProps {
  service?: Service;
  status:
    | 'collection'
    | 'empty'
    | 'exist'
    | 'reserved'
    | 'cancelling'
    | 'busy'
    | 'unknown';
  handleDialogOpen?: () => void;
}

interface StyleProps {
  backgroundColor: string;
  borderColor: string;
  borderStyle: string;
  color: string;
}

const useStyles = makeStyles({
  deliveryBoxStatusCard: (props: StyleProps) => ({
    backgroundColor: props.backgroundColor,
    borderColor: props.borderColor,
    borderWidth: '2px',
    borderStyle: props.borderStyle,
    borderRadius: '0',
    boxShadow: 'none',
    color: props.color,
    fontSize: '1.4rem',
    fontWeight: 'bold',
  }),
  deliveryBoxStatusCardContent: {
    paddingLeft: '2rem',
    paddingRight: '2rem',
    paddingTop: '1.1rem',
    textAlign: 'center',
    '&:last-child': {
      paddingBottom: '1.1rem',
    },
  },
});

const DeliveryBoxStatusCard: React.FC<DeliveryBoxStatusCardProps> = ({
  status,
}) => {
  const deliveryBoxStatusInfo = ((status) => {
    if (status === 'empty') {
      return {
        name: 'empty',
        style: {
          backgroundColor: '#f7fafb',
          borderColor: '#70daca',
          borderStyle: 'dashed',
          color: '#66d7c6',
        },
        text: '空いています',
      };
    } else if (status === 'exist') {
      return {
        style: {
          backgroundColor: '#fff9f9',
          borderColor: '#f96d6e',
          borderStyle: 'solid',
          color: '#f93f3d',
        },
        text: '荷物が入っています',
      };
    } else if (status === 'reserved') {
      return {
        style: {
          backgroundColor: '#fffbfd',
          borderColor: '#f977b6',
          borderStyle: 'solid',
          color: '#f84a9e',
        },
        text: '予約されています',
      };
    } else if (status === 'cancelling') {
      return {
        style: {
          backgroundColor: '#fffbfd',
          borderColor: '#f977b6',
          borderStyle: 'solid',
          color: '#f84a9e',
        },
        text: '予約の取り消しを受け付けました',
      };
    } else if (status === 'collection') {
      return {
        style: {
          backgroundColor: '#f3f5fb',
          borderColor: '#5e678a',
          borderStyle: 'solid',
          color: '#303b68',
        },
        text: '集荷待ちです',
      };
    } else if (status === 'busy') {
      return {
        style: {
          backgroundColor: '#f3f5fb',
          borderColor: '#5e678a',
          borderStyle: 'solid',
          color: '#303b68',
        },
        text: '設定中',
      };
    } else {
      return {
        style: {
          backgroundColor: '#f7fafb',
          borderColor: '#70daca',
          borderStyle: 'solid',
          color: '#66d7c6',
        },
        text: '不明です',
      };
    }
  })(status);

  const classes = useStyles(deliveryBoxStatusInfo.style);

  return (
    <Card className={classes.deliveryBoxStatusCard}>
      <CardContent className={classes.deliveryBoxStatusCardContent}>
        <Box textAlign="left">{deliveryBoxStatusInfo.text}</Box>
      </CardContent>
    </Card>
  );
};

export default DeliveryBoxStatusCard;
