import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import {
  createStyles,
  makeStyles,
  StyleRules,
  Theme,
} from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import DataUsageIcon from '@material-ui/icons/DataUsage';
import Edit from '@material-ui/icons/Edit';
import LaunchIcon from '@material-ui/icons/Launch';
import SettingsIcon from '@material-ui/icons/Settings';
// import AccessTimeIcon from '@material-ui/icons/AccessTime';
import MuiAlert from '@material-ui/lab/Alert';

import { useDeleteDevice } from '../../../hooks/useDeleteDevice';
import { useDialog } from '../../../hooks/useDialog';
import { useGetDevice } from '../../../hooks/useGetDevice';
import { useInterval } from '../../../hooks/useInterval';
import { useUpdateDevice } from '../../../hooks/useUpdateDevice';
import MessageBox from '../../molecules/MessageBox';
import DeliveryBoxDeleteDialog from '../../organisms/DeliveryBoxDeleteDialog';
import DeliveryBoxDeviceHeader from '../../organisms/DeliveryBoxDeviceHeader';
import DeliveryBoxStatusCard from '../../organisms/DeliveryBoxStatusCard';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules =>
    createStyles({
      iconButton: {
        border: `2px solid ${theme.palette.primary.main}`,
      },
      form: {
        marginTop: theme.spacing(1),
      },
      links: {
        marginTop: theme.spacing(4),
      },
      linkItem: {
        borderLeft: `1px solid ${theme.palette.grey[300]}`,
        '&:first-child': {
          border: 'none',
        },
      },
      label: {
        flexDirection: 'column',
      },
      labelName: {
        fontWeight: 'bold',
        color: theme.palette.primary.main,
        marginTop: theme.spacing(0.5),
      },
      infoList: {
        marginTop: theme.spacing(4),
      },
      deleteButton: {
        display: 'block',
        marginLeft: 'auto',
        marginTop: theme.spacing(2),
      },
    }),
);

const ErrorMessage: React.FC = () => {
  const classes = useStyles();

  return (
    <Box mt={4}>
      <MessageBox color="secondary">
        <Typography variant="body2">
          宅配ボックスの状態が取得できませんでした。次の内容を確認してください。
        </Typography>
        <ul className={classes.errorMessageList}>
          <li>ホームユニットはインターネットに正常につながっていますか？</li>
          <li>
            スマホアプリの「クラウドサービスを利用する」の設定がOFFになっていませんか？
          </li>
          <li>機器番号は正しいですか？</li>
        </ul>
      </MessageBox>
    </Box>
  );
};

const DeliveryBoxDetailPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();

  const getDevice = useGetDevice();

  useInterval(() => {
    getDevice.getData(deviceId);
  }, 10000);

  const updateDevice = useUpdateDevice();
  const [editMode, setEditMode] = useState(false);
  const [savedNickName, setSavedNickName] = useState('');

  const formik = useFormik({
    initialValues: {
      other: {
        nickName: getDevice.data.nickName ?? '宅配ボックス1',
      },
    },
    onSubmit: (values) => {
      updateDevice.handleSubmit({
        deviceId: deviceId,
        other: {
          nickName: values.other.nickName,
        },
      });
      setEditMode(false);
    },
    enableReinitialize: true,
  });

  const cancelEditMode = useCallback(() => {
    setEditMode(false);
    formik.setFieldValue('nickName', savedNickName);
  }, [formik, savedNickName]);

  const startEditMode = useCallback(() => {
    setEditMode(true);
    const saveValue = formik.values.other.nickName ?? '';
    setSavedNickName(saveValue);

    if (editMode) {
      cancelEditMode();
    }
  }, [formik, editMode, cancelEditMode]);

  const deleteDevice = useDeleteDevice();
  const deleteHubDialog = useDialog();

  useEffect(() => {
    if (deleteDevice.isCompleted) {
      history.push('/');
    }
  }, [deleteDevice.isCompleted, history]);

  useEffect(() => {
    if (deleteDevice.isError) {
      deleteHubDialog.handleClose();
    }
  }, [deleteDevice.isError, deleteHubDialog]);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (getDevice.isCompleted || getDevice.isError) {
      setIsLoaded(true);
    }
  }, [getDevice.isCompleted, getDevice.isError]);

  return (
    <GenericTemplate title="デバイス" loading={!isLoaded}>
      {deleteDevice.isError && (
        <Box my={2}>
          <MuiAlert severity="error">機器番号削除に失敗しました</MuiAlert>
        </Box>
      )}
      {getDevice.data.isHubUpdateAvailable && (
        <Box my={2}>
          <MuiAlert severity="info" icon={false}>
            ホームユニットのソフトウェアを更新して下さい
            <Link
              color="textPrimary"
              href={`https://www.lixil.co.jp/lineup/gate_fence/homenetwork/manual/4/#!/id_oi01_0319`}
              target="_blank"
              style={{ display: 'flex' }}
            >
              更新方法について
              <LaunchIcon color="inherit" fontSize="small" />
            </Link>
          </MuiAlert>
        </Box>
      )}
      <DeliveryBoxDeviceHeader />
      {getDevice.isError && !getDevice.isCompleted ? (
        <ErrorMessage />
      ) : (
        <>
          {!getDevice.data.isHubConnectAvailable ? (
            <>
              <Box my={2}>
                <MuiAlert severity="error" icon={false}>
                  宅配ボックスが現在ご利用できません。ホームユニットがインターネットに接続できているかご確認ください。
                </MuiAlert>
              </Box>
              <Button
                component={RouterLink}
                to="/contact"
                color="primary"
                variant="contained"
                fullWidth
              >
                お問い合わせ
              </Button>
            </>
          ) : !getDevice.data.cloudService ? (
            <ErrorMessage />
          ) : (
            <>
              <form className={classes.form} onSubmit={formik.handleSubmit}>
                <Box textAlign="center">
                  <TextField
                    name="other.nickName"
                    value={formik.values.other.nickName}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    InputProps={{
                      readOnly: !editMode,
                      endAdornment: (
                        <InputAdornment position="end">
                          <IconButton onClick={startEditMode}>
                            <Edit />
                          </IconButton>
                        </InputAdornment>
                      ),
                    }}
                    variant={editMode ? 'outlined' : 'standard'}
                  />
                  {editMode && (
                    <Box
                      style={{
                        marginTop: '0.5rem',
                        marginLeft: '-4.75rem',
                      }}
                    >
                      <Button
                        variant="contained"
                        color="primary"
                        style={{ marginRight: '0.5rem' }}
                        type="submit"
                      >
                        更新
                      </Button>
                      <Button
                        variant="outlined"
                        color="default"
                        onClick={cancelEditMode}
                      >
                        キャンセル
                      </Button>
                    </Box>
                  )}
                </Box>
              </form>
              <Box mt={2} mb={2}>
                <DeliveryBoxStatusCard status={getDevice.data.status} />
              </Box>
              {getDevice.data.service === 'deliveryBoxShareDelivery' ? (
                <MuiAlert severity="info">
                  あずけるボックスサービスで利用中
                </MuiAlert>
              ) : getDevice.data.service === 'deliveryBoxSharePickup' ? (
                <>
                  <Box mb={1}>
                    <MuiAlert severity="info">
                      あずけるボックスサービスで利用中
                    </MuiAlert>
                  </Box>
                  {getDevice.data.status === 'empty' && (
                    <MuiAlert severity="warning">
                      「集荷待ち」状態にしてください
                    </MuiAlert>
                  )}
                </>
              ) : getDevice.data.service === 'deliveryReserve' ? (
                <MuiAlert severity="info">佐川急便サービスで利用中</MuiAlert>
              ) : (
                getDevice.data.service === 'pickupRequest' && (
                  <MuiAlert severity="info">
                    クリーニングサービスで利用中
                  </MuiAlert>
                )
              )}
              <Grid container className={classes.links}>
                <Grid item xs={6} className={classes.linkItem}>
                  <Button
                    component={RouterLink}
                    to={`/devices/delivery-boxes/${getDevice.data.deviceId}/address/edit`}
                    style={{ width: '100%' }}
                    classes={{ label: classes.label }}
                  >
                    <DataUsageIcon color="primary" fontSize="large" />
                    <span className={classes.labelName}>情報</span>
                  </Button>
                </Grid>
                {/* <Grid item xs={4} className={classes.linkItem}>
                  <Button
                    component={RouterLink}
                    to={`/devices/histories/delivery-boxes/${getDevice.data.deviceId}`}
                    style={{ width: '100%' }}
                    classes={{ label: classes.label }}
                  >
                    <AccessTimeIcon color="primary" fontSize="large" />
                    <span className={classes.labelName}>利用履歴</span>
                  </Button>
                </Grid> */}
                <Grid item xs={6} className={classes.linkItem}>
                  <Button
                    component={RouterLink}
                    to={`/devices/delivery-boxes/${getDevice.data.deviceId}/edit`}
                    style={{ width: '100%' }}
                    classes={{ label: classes.label }}
                  >
                    <SettingsIcon color="primary" fontSize="large" />
                    <span className={classes.labelName}>解錠設定</span>
                  </Button>
                </Grid>
              </Grid>
              <List className={classes.infoList} disablePadding>
                <ListSubheader
                  disableGutters
                  disableSticky
                  color="inherit"
                  style={{ fontWeight: 'bold', lineHeight: 1 }}
                >
                  ホームユニット機器番号
                </ListSubheader>
                <ListItem disableGutters dense>
                  <ListItemText
                    primary={`${getDevice.data.hubMacAddress.toUpperCase()}`}
                  />
                </ListItem>
              </List>
            </>
          )}
          <Button
            color="secondary"
            onClick={deleteHubDialog.handleOpen}
            variant="outlined"
            className={classes.deleteButton}
          >
            宅配ボックスの削除
          </Button>
          <DeliveryBoxDeleteDialog
            handleCloseDeleteDialog={deleteHubDialog.handleClose}
            handleDelete={deleteDevice.handleDeleteHub}
            deviceId={getDevice.data.deviceId}
            openDeleteDialog={deleteHubDialog.open}
          />
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxDetailPage;
