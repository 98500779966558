import { useState, useCallback } from 'react';

import GeolocationService from '../utils/GeolocationService';

export const useGeolocation = (): {
  coords: {
    lat: number;
    lng: number;
  };
  getData: () => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [coords, setCoords] = useState({
    lat: 0,
    lng: 0,
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(() => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const position: GeolocationPosition =
          await GeolocationService.getCurrentPosition();

        setCoords({
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        });
        setIsCompleted(true);
      } catch (positionError) {
        setIsError(true);
      }
      setIsLoading(false);
    })();
  }, []);

  return { coords, getData, isCompleted, isLoading, isError };
};
