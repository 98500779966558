import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { Notice } from '../../hooks/useListNotice';
import MessageBox from '../molecules/MessageBox';
import NoticeListItem from '../molecules/NoticeListItem';

interface NoticeListProps {
  data: Notice[];
  isLoading: boolean;
  isError: boolean;
}

const useStyles = makeStyles(
  (): StyleRules => ({
    noticeYearList: {
      paddingBottom: '1rem',
      paddingLeft: '0',
      paddingRight: '0',
      paddingTop: '0',
      width: '100%',
    },
  }),
);

const NoticeList: React.FC<NoticeListProps> = ({
  data,
  isLoading,
  isError,
}) => {
  const classes = useStyles();

  const noticeData = data
    .map((notice) => {
      return {
        date: notice.date.getDate(),
        year: notice.date.getFullYear(),
        month: ('0' + (notice.date.getMonth() + 1)).slice(-2),
        day: ('0' + notice.date.getDate()).slice(-2),
        title: notice.title,
        body: notice.body,
      };
    })
    .sort((a, b) => (b.date < a.date ? -1 : 0))
    .sort((a, b) => (Number(b.month) < Number(a.month) ? -1 : 0));

  const years = noticeData
    .map((notice) => {
      return notice.year;
    })
    .filter((x, i, self) => self.indexOf(x) === i)
    .sort((a, b) => b - a);

  return (
    <>
      {!isLoading &&
        (!isError ? (
          years.map((year) => (
            <div key={year}>
              <Typography component="h3" color="primary" gutterBottom>
                {year}
              </Typography>
              <List className={classes.noticeYearList}>
                {noticeData
                  .filter((notice) => notice.year === year)
                  .map((notice, index) => (
                    <ListItem key={index} disableGutters dense>
                      <NoticeListItem
                        month={notice.month}
                        day={notice.day}
                        title={notice.title}
                        body={notice.body}
                      />
                    </ListItem>
                  ))}
              </List>
            </div>
          ))
        ) : (
          <MessageBox color="secondary">
            お知らせが取得できませんでした
          </MessageBox>
        ))}
    </>
  );
};

export default NoticeList;
