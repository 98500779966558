import { DateTime } from 'luxon';
import React, { useCallback, useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { useCancelDeliveryReservation } from '../../../hooks/useCancelDeliveryReservation';
import { useDialog } from '../../../hooks/useDialog';
import { useGetDelivery } from '../../../hooks/useGetDelivery';
import CancelReservationDialog from '../../organisms/CancelReservationDialog';
import DeliveryReservationServiceHeader from '../../organisms/DeliveryReservationServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    info: {
      border: `2px solid ${theme.palette.primary.main}`,
      background: '#f7fafb',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    infoDesc: {
      '& dt': {
        width: 'auto',
        float: 'left',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& dd': {
        width: 'auto',
        margin: '0 0 0 7rem',
      },
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      display: 'block',
      marginLeft: 'auto',
    },
  }),
);

const DeliveryStatusText: React.FC<{ status: string }> = ({ status }) => {
  if (status === 'scheduledReserve') {
    return <>配達予定</>;
  } else if (status === 'reserved') {
    return <>予約済み</>;
  } else if (status === 'delivered') {
    return <>配達完了</>;
  } else if (status === 'undeliveredForAbsence') {
    return <>不在完了</>;
  } else if (status === 'cancelledReserveBySystem') {
    return <>キャンセル</>;
  } else if (status === 'cancelledReserveByMember') {
    return <>キャンセル</>;
  } else if (status === 'received') {
    return <>受取り済み</>;
  }

  return <>不明</>;
};

const DeliveryReservationDetailPage: React.FC = () => {
  const classes = useStyles();
  const { deliveryId } = useParams<{ deliveryId: string }>();

  const getDelivery = useGetDelivery();

  const cancelDialog = useDialog();

  useEffect(() => {
    if (
      !getDelivery.isCompleted &&
      !getDelivery.isError &&
      !getDelivery.isLoading
    ) {
      getDelivery.getData(deliveryId!);
    }
  }, [getDelivery, deliveryId]);

  const cancelDeliveryReservation = useCancelDeliveryReservation();

  const handleCancelDeliveryReservation = useCallback(() => {
    cancelDeliveryReservation.handleSubmit(deliveryId);
    cancelDialog.handleClose();
  }, [cancelDeliveryReservation, cancelDialog, deliveryId]);

  const history = useHistory();

  useEffect(() => {
    if (cancelDeliveryReservation.isCompleted) {
      history.push('/services/delivery-reservations/histories');
    }
  }, [cancelDeliveryReservation.isCompleted, history]);

  return (
    <GenericTemplate
      loading={getDelivery.isLoading || cancelDeliveryReservation.isLoading}
    >
      <DeliveryReservationServiceHeader />
      {cancelDeliveryReservation.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            配達予約のキャンセルに失敗しました。
          </MuiAlert>
        </Box>
      )}
      {getDelivery.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            配達予約のご利用状況が取得出来ませんでした。
          </MuiAlert>
        </Box>
      )}
      {getDelivery.isCompleted && (
        <>
          <Box className={classes.info}>
            <List component="dl" disablePadding className={classes.infoDesc}>
              <ListItem disableGutters dense component="dt">
                問い合わせ番号
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDelivery.data.trackingNumber}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                状態
              </ListItem>
              <ListItem disableGutters dense component="dd">
                <DeliveryStatusText status={getDelivery.data.status} />
              </ListItem>
              <ListItem disableGutters dense component="dt">
                配達予定日
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {DateTime.fromFormat(
                  getDelivery.data.deliveryScheduleAt,
                  'yyyy-MM-dd',
                ).toFormat('yyyy/MM/dd')}
              </ListItem>
              {getDelivery.data.qrReadAt && (
                <>
                  <ListItem disableGutters dense component="dt">
                    訪問日時
                  </ListItem>
                  <ListItem disableGutters dense component="dd">
                    {DateTime.fromMillis(getDelivery.data.qrReadAt).toFormat(
                      'yyyy/MM/dd HH:mm',
                    )}
                  </ListItem>
                </>
              )}
              <ListItem disableGutters dense component="dt">
                名前
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDelivery.data.fullName}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                住所
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {`${
                  getDelivery.data.postalCode
                    ? `\u3012${getDelivery.data.postalCode}`
                    : ''
                } ${getDelivery.data.address1 || ''} ${
                  getDelivery.data.address2 || ''
                } `}
              </ListItem>
            </List>
          </Box>
          {getDelivery.data.status === 'reserved' && (
            <Button
              className={classes.cancelButton}
              onClick={cancelDialog.handleOpen}
              color="secondary"
              variant="outlined"
            >
              配達予約を取り消す
            </Button>
          )}
        </>
      )}
      <CancelReservationDialog
        open={cancelDialog.open}
        onClose={cancelDialog.handleClose}
        onCancelReservation={handleCancelDeliveryReservation}
      />
    </GenericTemplate>
  );
};

export default DeliveryReservationDetailPage;
