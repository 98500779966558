import React from 'react';
import { Link } from 'react-router-dom';
import Badge from '@material-ui/core/Badge';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import ErrorIcon from '@material-ui/icons/Error';
import InfoIcon from '@material-ui/icons/Info';

import { ReactComponent as DeliveryBoxIcon } from '../../assets/icon-delivery-box.svg';
import { Device } from '../../hooks/useListDevice';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      width: '35px',
      height: '35px',
    },
    item: {
      textAlign: 'center',
      textDecoration: 'none',
    },
    iconButton: {
      border: `1px solid`,
      padding: '10px',
      '&:hover': {
        background: 'none',
      },
    },
    label: {
      fontWeight: 'bold',
      fontSize: theme.typography.caption.fontSize,
      color: theme.palette.text.secondary,
      margin: theme.spacing(1.5, 0, 0),
    },
    customPopper: {
      zIndex: 'auto',
    },
    customTooltip: {
      color: '#fff',
      fontWeight: 'bold',
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.primary.main,
      margin: '14px 0',
    },
    customArrow: {
      color: theme.palette.primary.main,
    },
    marginTop: {
      marginTop: theme.spacing(4),
    },
  }),
);

interface BadgeContentProps {
  isHubConnectAvailable: boolean;
  isHubUpdateAvailable: boolean;
}

const BadgeContent: React.FC<BadgeContentProps> = ({
  isHubConnectAvailable,
  isHubUpdateAvailable,
}) => {
  return !isHubConnectAvailable ? (
    <ErrorIcon color="error" />
  ) : isHubUpdateAvailable ? (
    <InfoIcon style={{ color: '#64b5f6' }} />
  ) : null;
};

interface DeviceListProps {
  devices: Device[];
}

const DeviceList: React.FC<DeviceListProps> = ({ devices }) => {
  const classes = useStyles();

  return (
    <Box mt={4}>
      <Grid container spacing={3}>
        {devices.length > 0 ? (
          devices.map((device) => (
            <Grid
              key={device.deviceId}
              item
              xs={4}
              md={2}
              component={Link}
              to={`/devices/delivery-boxes/${device.deviceId}`}
              className={
                device.service !== undefined || device.status === 'exist'
                  ? classes.item + ' ' + classes.marginTop
                  : classes.item
              }
            >
              <Tooltip
                arrow
                open={device.service !== undefined || device.status === 'exist'}
                placement="top"
                title={
                  device.service !== undefined
                    ? '利用中'
                    : device.status === 'exist'
                    ? '荷物あり'
                    : ''
                }
                PopperProps={{
                  disablePortal: true,
                  modifiers: {
                    flip: {
                      enabled: false,
                    },
                  },
                }}
                classes={{
                  popper: classes.customPopper,
                  tooltip: classes.customTooltip,
                  arrow: classes.customArrow,
                }}
              >
                <Badge
                  overlap="circular"
                  badgeContent={
                    <BadgeContent
                      isHubConnectAvailable={device.isHubConnectAvailable}
                      isHubUpdateAvailable={device.isHubUpdateAvailable}
                    />
                  }
                  className={classes.badge}
                >
                  <IconButton
                    className={classes.iconButton}
                    style={{ border: '1px solid #1dbb90' }}
                  >
                    <DeliveryBoxIcon className={classes.icon} />
                  </IconButton>
                </Badge>
              </Tooltip>
              <p className={classes.label}>
                {device.nickName ?? '宅配ボックス1'}
              </p>
            </Grid>
          ))
        ) : (
          <Grid
            item
            xs={4}
            md={2}
            component={Link}
            to="/devices/delivery-boxes/add"
            className={classes.item}
          >
            <IconButton className={classes.iconButton}>
              <AddIcon fontSize="large" color="inherit" />
            </IconButton>
            <p className={classes.label}>追加</p>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DeviceList;
