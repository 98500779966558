import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import {
  Link,
  RouteComponentProps,
  useParams,
  withRouter,
} from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import { makeStyles, StyleRules } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

import { useGetDevice } from '../../../hooks/useGetDevice';
import { useUpdateDevice } from '../../../hooks/useUpdateDevice';
import AuthService from '../../../utils/AuthService';
import FormSelectField from '../../molecules/FormSelectField';
import FormTextField from '../../molecules/FormTextField';
import MessageBox from '../../molecules/MessageBox';
import SelectAddressDialog, {
  Address,
} from '../../organisms/SelectAddressDialog';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles((): StyleRules => ({}));

const validationSchema = Yup.object().shape({
  address: Yup.object().shape({
    zip: Yup.string().required('必須項目です'),
    state: Yup.string()
      .max(10, '文字数は10文字以内で入力してください')
      .required('必須項目です'),
    address1: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
    address2: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
    building: Yup.string().max(50, '文字数は50文字以内で入力してください'),
    phoneNumber: Yup.string()
      .min(9, '文字数は9文字以内で入力してください')
      .max(11, '文字数は11文字以内で入力してください')
      .matches(/^[0-9]+$/, {
        message: '半角数字で入力してください',
      })
      .required('必須項目です'),
    email: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .email('メールアドレスを入力してください'),
    lastName: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
    firstName: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
    lastNameKana: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
    firstNameKana: Yup.string()
      .max(50, '文字数は50文字以内で入力してください')
      .required('必須項目です'),
  }),
});

const DeliveryBoxAddressAddPage: React.FC<RouteComponentProps> = (props) => {
  const classes = useStyles();
  const { deviceId } = useParams<{ deviceId: string }>();

  const updateDevice = useUpdateDevice();

  const params = new URLSearchParams(props.location.search);
  const redirect = params.get('redirect');

  const formik = useFormik({
    initialValues: {
      deviceId: deviceId,
      address: {
        zip: '',
        state: '',
        address1: '',
        address2: '',
        building: '',
        phoneNumber: '',
        email: '',
        lastName: '',
        firstName: '',
        lastNameKana: '',
        firstNameKana: '',
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      updateDevice.handleSubmit(values);
    },
  });

  useEffect(() => {
    if (updateDevice.isCompleted) {
      if (redirect) {
        props.history.push(redirect);
      } else {
        props.history.push('/');
      }
    }
  }, [updateDevice.isCompleted, props.history, redirect]);

  const [modalOpen, setModelOpen] = useState(false);

  const handleModelOpen = () => {
    setModelOpen(true);
  };

  const handleModelClose = () => {
    setModelOpen(false);
  };

  const handleSelectAddress = (address: Address) => {
    formik.setFieldValue('address.zip', address.zip ?? '');
    formik.setFieldValue('address.state', address.state ?? '');
    formik.setFieldValue('address.address1', address.address1 ?? '');
    formik.setFieldValue('address.address2', address.address2 ?? '');
    formik.setFieldValue('address.building', address.building ?? '');
    formik.setFieldValue('address.phoneNumber', address.phoneNumber ?? '');
    formik.setFieldValue('address.lastName', address.lastName ?? '');
    formik.setFieldValue('address.firstName', address.firstName ?? '');
    formik.setFieldValue('address.lastNameKana', address.lastNameKana ?? '');
    formik.setFieldValue('address.firstNameKana', address.firstNameKana ?? '');
  };

  const [addresses, setAddresses] = useState<Address[]>();

  useEffect(() => {
    (async () => {
      const userInfo = await AuthService.getUserInfo();

      if (userInfo.consumer) {
        const _addresses = userInfo.consumer.map((consumer) => ({
          label: consumer.label,
          zip: consumer.zip,
          state: consumer.state,
          address1: consumer.address1,
          address2: consumer.address2,
          building: consumer.building,
          phoneNumber: consumer.phoneNumber,
          lastName: consumer.familyName,
          firstName: consumer.givenName,
          lastNameKana: consumer.familyNameKana,
          firstNameKana: consumer.givenNameKana,
          id: consumer.id,
        }));

        if (userInfo.consumerDefault) {
          const i = _addresses.findIndex((address) => {
            return address.id === userInfo.consumerDefault;
          });

          const _address = _addresses[i];
          delete _addresses[i];
          _addresses.unshift(_address);
        }

        setAddresses(_addresses);
      }
    })();
  }, []);

  const [isCompletedAddress, setIsCompletedAddress] = useState(false);

  useEffect(() => {
    (async () => {
      if (!isCompletedAddress && addresses && addresses.length > 0) {
        formik.setFieldValue('address.zip', addresses[0].zip ?? '');
        formik.setFieldValue('address.state', addresses[0].state ?? '');
        formik.setFieldValue('address.address1', addresses[0].address1 ?? '');
        formik.setFieldValue('address.address2', addresses[0].address2 ?? '');
        formik.setFieldValue('address.building', addresses[0].building ?? '');
        formik.setFieldValue(
          'address.phoneNumber',
          addresses[0].phoneNumber ?? '',
        );
        formik.setFieldValue('address.lastName', addresses[0].lastName ?? '');
        formik.setFieldValue('address.firstName', addresses[0].firstName ?? '');
        formik.setFieldValue(
          'address.lastNameKana',
          addresses[0].lastNameKana ?? '',
        );
        formik.setFieldValue(
          'address.firstNameKana',
          addresses[0].firstNameKana ?? '',
        );

        setIsCompletedAddress(true);
      }
    })();
  }, [isCompletedAddress, addresses, formik]);

  const deliveryBox = useGetDevice();

  useEffect(() => {
    (async () => {
      if (!deliveryBox.isCompleted && !deliveryBox.isLoading) {
        deliveryBox.getData(deviceId);
      }
    })();
  }, [deliveryBox, deviceId]);

  return (
    <GenericTemplate
      title={`${
        deliveryBox.data.nickName ? deliveryBox.data.nickName : '宅配ボックス'
      }の情報登録`}
    >
      <Grid container spacing={3}>
        <Grid item xs={12} md={8} lg={6}>
          <Box mb={1}>
            <MessageBox color="initial">
              サービスを利用する場合は宅配ボックスの各項目を登録してください。
              <Typography variant="subtitle2" color="secondary">
                ※必須項目です。
              </Typography>
            </MessageBox>
          </Box>
          {addresses && addresses.length > 0 && (
            <Box mb={1} textAlign="right">
              <Button
                className={classes.submitButton}
                color="primary"
                type="button"
                variant="contained"
                onClick={handleModelOpen}
              >
                MyLIXILから別の住所を指定する
              </Button>
              <SelectAddressDialog
                open={modalOpen}
                onClose={handleModelClose}
                onSelect={handleSelectAddress}
                addresses={addresses}
              />
            </Box>
          )}
          <form onSubmit={formik.handleSubmit}>
            <Box mb={3}>
              <FormTextField
                defaultValue={formik.values.address.zip}
                value={formik.values.address.zip}
                error={
                  !!formik.errors.address?.zip && !!formik.touched.address?.zip
                }
                helperText={formik.errors.address?.zip}
                label="郵便番号"
                name="address.zip"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）0000000"
                required
              />
              <FormSelectField
                defaultValue={formik.values.address.state}
                value={formik.values.address.state}
                error={
                  !!formik.errors.address?.state &&
                  !!formik.touched.address?.state
                }
                helperText={formik.errors.address?.state}
                label="都道府県"
                name="address.state"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={[
                  { label: '選択してください', value: '' },
                  { label: '北海道', value: '北海道' },
                  { label: '青森県', value: '青森県' },
                  { label: '岩手県', value: '岩手県' },
                  { label: '宮城県', value: '宮城県' },
                  { label: '秋田県', value: '秋田県' },
                  { label: '山形県', value: '山形県' },
                  { label: '福島県', value: '福島県' },
                  { label: '茨城県', value: '茨城県' },
                  { label: '栃木県', value: '栃木県' },
                  { label: '群馬県', value: '群馬県' },
                  { label: '埼玉県', value: '埼玉県' },
                  { label: '千葉県', value: '千葉県' },
                  { label: '東京都', value: '東京都' },
                  { label: '神奈川県', value: '神奈川県' },
                  { label: '新潟県', value: '新潟県' },
                  { label: '富山県', value: '富山県' },
                  { label: '石川県', value: '石川県' },
                  { label: '福井県', value: '福井県' },
                  { label: '山梨県', value: '山梨県' },
                  { label: '長野県', value: '長野県' },
                  { label: '岐阜県', value: '岐阜県' },
                  { label: '静岡県', value: '静岡県' },
                  { label: '愛知県', value: '愛知県' },
                  { label: '三重県', value: '三重県' },
                  { label: '滋賀県', value: '滋賀県' },
                  { label: '京都府', value: '京都府' },
                  { label: '大阪府', value: '大阪府' },
                  { label: '兵庫県', value: '兵庫県' },
                  { label: '奈良県', value: '奈良県' },
                  { label: '和歌山県', value: '和歌山県' },
                  { label: '鳥取県', value: '鳥取県' },
                  { label: '島根県', value: '島根県' },
                  { label: '岡山県', value: '岡山県' },
                  { label: '広島県', value: '広島県' },
                  { label: '山口県', value: '山口県' },
                  { label: '徳島県', value: '徳島県' },
                  { label: '香川県', value: '香川県' },
                  { label: '愛媛県', value: '愛媛県' },
                  { label: '高知県', value: '高知県' },
                  { label: '福岡県', value: '福岡県' },
                  { label: '佐賀県', value: '佐賀県' },
                  { label: '長崎県', value: '長崎県' },
                  { label: '熊本県', value: '熊本県' },
                  { label: '大分県', value: '大分県' },
                  { label: '宮崎県', value: '宮崎県' },
                  { label: '鹿児島県', value: '鹿児島県' },
                  { label: '沖縄県', value: '沖縄県' },
                ]}
                required
              />
              <FormTextField
                defaultValue={formik.values.address.address1}
                value={formik.values.address.address1}
                error={
                  !!formik.errors.address?.address1 &&
                  !!formik.touched.address?.address1
                }
                helperText={formik.errors.address?.address1}
                label="市区町村"
                name="address.address1"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）千代田区"
                required
              />
              <FormTextField
                defaultValue={formik.values.address.address2}
                value={formik.values.address.address2}
                error={
                  !!formik.errors.address?.address2 &&
                  !!formik.touched.address?.address2
                }
                helperText={formik.errors.address?.address2}
                label="町名・番地"
                name="address.address2"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）○○町1-1-1"
                required
              />
              <FormTextField
                defaultValue={formik.values.address.building}
                value={formik.values.address.building}
                error={
                  !!formik.errors.address?.building &&
                  !!formik.touched.address?.building
                }
                helperText={formik.errors.address?.building}
                label="建物名・部屋番号"
                name="address.building"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）〇〇ビル101号室"
              />
              <FormTextField
                defaultValue={formik.values.address.phoneNumber}
                value={formik.values.address.phoneNumber}
                error={
                  !!formik.errors.address?.phoneNumber &&
                  !!formik.touched.address?.phoneNumber
                }
                helperText={formik.errors.address?.phoneNumber}
                label="電話番号"
                name="address.phoneNumber"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）0000000000"
                required
              />
              <FormTextField
                defaultValue={formik.values.address.email}
                value={formik.values.address.email}
                error={
                  !!formik.errors.address?.email &&
                  !!formik.touched.address?.email
                }
                helperText={formik.errors.address?.email}
                label="メールアドレス"
                name="address.email"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                placeholder="例）xxx@example.com"
              />
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormTextField
                    defaultValue={formik.values.address.lastName}
                    error={
                      !!formik.errors.address?.lastName &&
                      !!formik.touched.address?.lastName
                    }
                    helperText={formik.errors.address?.lastName}
                    label="姓"
                    name="address.lastName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="例）山田"
                    value={formik.values.address.lastName}
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    defaultValue={formik.values.address.firstName}
                    value={formik.values.address.firstName}
                    error={
                      !!formik.errors.address?.firstName &&
                      !!formik.touched.address?.firstName
                    }
                    helperText={formik.errors.address?.firstName}
                    label="名"
                    name="address.firstName"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="例）太郎"
                    required
                  />
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <FormTextField
                    defaultValue={formik.values.address.lastNameKana}
                    value={formik.values.address.lastNameKana}
                    error={
                      !!formik.errors.address?.lastNameKana &&
                      !!formik.touched.address?.lastNameKana
                    }
                    helperText={formik.errors.address?.lastNameKana}
                    label="姓 フリガナ"
                    name="address.lastNameKana"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="例）ヤマダ"
                    required
                  />
                </Grid>
                <Grid item xs={6}>
                  <FormTextField
                    defaultValue={formik.values.address.firstNameKana}
                    value={formik.values.address.firstNameKana}
                    error={
                      !!formik.errors.address?.firstNameKana &&
                      !!formik.touched.address?.firstNameKana
                    }
                    helperText={formik.errors.address?.firstNameKana}
                    label="名 フリガナ"
                    name="address.firstNameKana"
                    onBlur={formik.handleBlur}
                    onChange={formik.handleChange}
                    placeholder="例）タロウ"
                    required
                  />
                </Grid>
              </Grid>
            </Box>
            <Box mb={1}>
              <Button
                className={classes.submitButton}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                登録する
              </Button>
            </Box>
            <Box mb={1}>
              <Button
                component={Link}
                to="/"
                className={classes.submitButton}
                color="default"
                fullWidth
                type="button"
                variant="contained"
              >
                登録せずに完了する
              </Button>
            </Box>
          </form>
        </Grid>
      </Grid>
    </GenericTemplate>
  );
};

export default withRouter(DeliveryBoxAddressAddPage);
