import { useState, useCallback } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 配達の状態
 *
 * @returns scheduledReserve: 配達予定済み
 * @returns reserved: 予約済み
 * @returns delivered: 配達完了
 * @returns undeliveredForAbsence: 不在完了
 * @returns cancelledReserveBySystem: キャンセル
 * @returns cancelledReserveByMember: キャンセル
 * @returns received: 受取り済み
 */
export type DeliveryStatus =
  | 'scheduledReserve'
  | 'reserved'
  | 'delivered'
  | 'undeliveredForAbsence'
  | 'cancelledReserveBySystem'
  | 'cancelledReserveByMember'
  | 'received'
  | 'unknown';

export interface Delivery {
  deliveryId: string;
  trackingNumber: string;
  hubMacAddress: string;
  deliveryScheduleAt: string;
  status: DeliveryStatus;
  postalCode: string;
  fullName: string;
  address1: string;
  address2: string;
  qrReadAt?: number;
}

export const useGetDelivery = (): {
  getData: (deliveryId: string) => Promise<void>;
  data: Delivery;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [data, setData] = useState<Delivery>({
    deliveryId: '',
    trackingNumber: '',
    hubMacAddress: '',
    deliveryScheduleAt: '',
    status: 'unknown',
    postalCode: '',
    fullName: '',
    address1: '',
    address2: '',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getData = useCallback(async (deliveryId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/deliveries/${deliveryId}`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);

      if (e.response.status === 404) {
        setErrorMessage('配達履歴詳細が取得出来ませんでした。');
      } else {
        setErrorMessage('原因不明のエラーが発生しました。');
      }
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError, errorMessage };
};
