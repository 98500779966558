import { DateTime } from 'luxon';
import React, { useEffect, useCallback, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import MuiAlert from '@material-ui/lab/Alert';

import { useDialog } from '../../../hooks/useDialog';
import { useGetUser } from '../../../hooks/useGetUser';
import { useGetDevice } from '../../../hooks/useGetDevice';
import { useGetPickupRequest } from '../../../hooks/useGetPickupRequest';
import PickupRequestServiceHeader from '../../organisms/PickupRequestServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';
import { useCancelPickupRequest } from '../../../hooks/useCancelPickupRequest';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    info: {
      border: `2px solid ${theme.palette.primary.main}`,
      background: '#f7fafb',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(2),
    },
    infoText: {
      color: '#666666',
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
    },
    infoDesc: {
      '& dt': {
        width: 'auto',
        float: 'left',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& dd': {
        width: 'auto',
        margin: '0 0 0 7rem',
      },
    },
    customPopper: {
      zIndex: 'auto',
    },
    customTooltip: {
      color: '#fff',
      fontWeight: 'bold',
      lineHeight: 'inherit',
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(1.5, 0),
    },
    customArrow: {
      color: theme.palette.primary.main,
    },
    shareButtonWrapper: {
      width: '65%',
      display: 'flex',
      margin: `${theme.spacing(1)} auto 0`,
      [theme.breakpoints.down('sm')]: {
        width: '75%',
      },
    },
    shareButton: {
      flex: 1,
      border: `2px solid ${grey[400]}!important`,
      borderRadius: '5px',
      padding: `${theme.spacing(1)}!important`,
      margin: theme.spacing(1),
    },
    shareButtonText: {
      fontWeight: 'bold',
      margin: 0,
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      display: 'block',
      marginLeft: 'auto',
    },
  }),
);

const PickupRequestStatusText: React.FC<{ status: string }> = ({ status }) => {
  if (status === 'postingWait') {
    return <>投函待ち</>;
  } else if (status === 'requested') {
    return <>申込済み</>;
  } else if (status === 'requestCanceled') {
    return <>申込キャンセル</>;
  } else if (status === 'approved') {
    return <>受付済み</>;
  } else if (status === 'approveRejected') {
    return <>受付キャンセル</>;
  } else if (status === 'pickedup') {
    return <>集荷済み</>;
  } else if (status === 'delivering') {
    return <>配達中</>;
  } else if (status === 'delivered') {
    return <>配達済み</>;
  }

  return <>不明</>;
};

const PickupRequestDetailPage: React.FC = () => {
  const classes = useStyles();
  const { pickupRequestId } = useParams<{ pickupRequestId: string }>();

  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const [canUseCleaning, setCanUseCleaning] = useState(false);

  useEffect(() => {
    if (getUser.data.scopes.includes('members_site_use_cleaning')) {
      setCanUseCleaning(true);
    }
  }, [getUser]);

  const getPickupRequest = useGetPickupRequest();
  const getDevice = useGetDevice();

  const cancelDialog = useDialog();

  useEffect(() => {
    if (
      !getPickupRequest.isCompleted &&
      !getPickupRequest.isError &&
      !getPickupRequest.isLoading
    ) {
      getPickupRequest.getData(pickupRequestId!);
    }
  }, [getPickupRequest, pickupRequestId]);

  useEffect(() => {
    if (
      !getDevice.isCompleted &&
      !getDevice.isError &&
      !getDevice.isLoading &&
      getPickupRequest.data.deviceId
    ) {
      getDevice.getData(getPickupRequest.data.deviceId);
    }
  }, [getDevice, getPickupRequest.data.deviceId]);

  const preferredDate = DateTime.fromFormat(
    getPickupRequest.data.preferredDate,
    'yyyy-MM-dd',
  ).toFormat('yyyy/MM/dd');

  const preferredTimeZone = getPickupRequest.data.preferredTimeZone.replace(
    /(\d+)-(\d+)/,
    '$1時-$2時',
  );

  const cancelPickupRequest = useCancelPickupRequest();

  const handleCancelPickupRequest = useCallback(() => {
    cancelPickupRequest.handleSubmit(pickupRequestId);
    cancelDialog.handleClose();
  }, [cancelPickupRequest, cancelDialog, pickupRequestId]);

  const history = useHistory();

  useEffect(() => {
    if (cancelPickupRequest.isCompleted) {
      history.push('/services/pickup-requests/histories');
    }
  }, [cancelPickupRequest.isCompleted, history]);

  return (
    <GenericTemplate
      loading={
        getUser.isLoading ||
        getPickupRequest.isLoading ||
        cancelPickupRequest.isLoading
      }
    >
      <PickupRequestServiceHeader showMenu={canUseCleaning} />
      {cancelPickupRequest.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            クリーニング申し込みのキャンセルに失敗しました。
          </MuiAlert>
        </Box>
      )}
      {getPickupRequest.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            クリーニングサービスのご利用状況が取得出来ませんでした。
          </MuiAlert>
        </Box>
      )}
      {getPickupRequest.isCompleted && (
        <>
          <Box className={classes.info}>
            <List component="dl" disablePadding className={classes.infoDesc}>
              <ListItem disableGutters dense component="dt">
                注文番号
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getPickupRequest.data.pickupRequestId}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                状態
              </ListItem>
              <ListItem disableGutters dense component="dd">
                <PickupRequestStatusText
                  status={getPickupRequest.data.status}
                />
              </ListItem>
              <ListItem disableGutters dense component="dt">
                申し込み日時
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {DateTime.fromMillis(getPickupRequest.data.createAt, {
                  zone: 'UTC',
                })
                  .toLocal()
                  .toFormat('yyyy/MM/dd HH:mm')}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                集荷希望日時
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {`${
                  preferredDate + preferredTimeZone
                    ? `${preferredDate + ' ' + preferredTimeZone} まで`
                    : ''
                }`}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                名前
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getPickupRequest.data.fullName}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                住所
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {`${
                  getPickupRequest.data.postalCode
                    ? `\u3012${getPickupRequest.data.postalCode}`
                    : ''
                } ${getPickupRequest.data.state || ''} ${
                  getPickupRequest.data.address1 || ''
                } ${getPickupRequest.data.address2 || ''} ${
                  getPickupRequest.data.building || ''
                }`}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                依頼内容
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getPickupRequest.data.memo}
              </ListItem>
            </List>
          </Box>
          {getPickupRequest.data.status === 'requested' && (
            <Button
              className={classes.cancelButton}
              onClick={cancelDialog.handleOpen}
              color="secondary"
              variant="outlined"
            >
              申し込みを取り消す
            </Button>
          )}
        </>
      )}
      <Dialog open={cancelDialog.open} onClose={cancelDialog.handleClose}>
        <DialogTitle>申し込みを取り消し</DialogTitle>
        <DialogContent>
          <DialogContentText>
            クリーニングの申し込みを取り消します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelDialog.handleClose}
            variant="contained"
            autoFocus
          >
            キャンセル
          </Button>
          <Button
            onClick={handleCancelPickupRequest}
            variant="contained"
            color="secondary"
          >
            申し込みを取り消す
          </Button>
        </DialogActions>
      </Dialog>
    </GenericTemplate>
  );
};

export default PickupRequestDetailPage;
