import Axios, { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 宅配ボックスの状態
 *
 * @returns collection: 集荷待ち
 * @returns empty: 荷物なし
 * @returns exist: 荷物あり
 * @returns reserved: 予約中
 * @returns cancelling: 予約取り消し受け付け中
 * @returns busy: 設定変更中
 * @returns unknown: 不明
 */
export type DeliveryBoxStatus =
  | 'collection'
  | 'empty'
  | 'exist'
  | 'reserved'
  | 'cancelling'
  | 'busy'
  | 'unknown';

/**
 * 宅配ボックスを使用しているサービス
 *
 * @returns deliveryReserve: 配達予約
 * @returns pickupRequest: 集荷依頼
 * @returns deliveryBoxShareDelivery: あずけるボックス（配達）
 * @returns deliveryBoxSharePickup: あずけるボックス（集荷）
 */
export type Service =
  | 'deliveryReserve'
  | 'pickupRequest'
  | 'deliveryBoxShareDelivery'
  | 'deliveryBoxSharePickup';

export interface Device {
  deviceId: string;
  hubMacAddress: string;
  cloudService: boolean;
  yourPassword: string;
  deliveryPassword: string;
  collectionPassword: string;
  unlockMethodFirst: string;
  unlockMethodSecondOnward: string;
  status: DeliveryBoxStatus;
  isHubUpdateAvailable: boolean;
  isHubConnectAvailable: boolean;
  service?: Service;
  nickName?: string;
  firstName?: string;
  lastName?: string;
  firstNameKana?: string;
  lastNameKana?: string;
  phoneNumber?: string;
  zip?: string;
  state?: string;
  address1?: string;
  address2?: string;
  building?: string;
  email?: string;
}

export const useListDevice = (): {
  getData: () => Promise<void>;
  data: Device[];
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<Device[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async () => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response: AxiosResponse<{ devices: Device[] }> = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/devices`,
        {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        },
      );

      setData(response.data.devices);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return {
    getData,
    data,
    isCompleted,
    isLoading,
    isError,
  };
};
