import React, { useCallback, useState } from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import MobileStepper from '@material-ui/core/MobileStepper';
import {
  makeStyles,
  StyleRules,
  Theme,
  useTheme,
} from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import LaunchIcon from '@material-ui/icons/Launch';

import appStoreIcon from '../../assets/icon-app-store.svg';
import googlePlayIcon from '../../assets/icon-google-play.png';
import operation01 from '../../assets/sp-app-operation-01.png';
import operation02 from '../../assets/sp-app-operation-02.png';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    bold: {
      fontWeight: 'bold',
    },
    cancelButton: {
      paddingTop: '0',
    },
    listItem: {
      color: theme.palette.text.primary,
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    exImg: {
      width: '50%',
      marginRight: theme.spacing(2),
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0,
      },
    },
    exText01: {
      marginTop: theme.spacing(2),
    },
    appleIcon: {},
  }),
);

const TutorialSteps: React.FC<{ activeStep: number }> = ({ activeStep }) => {
  const classes = useStyles({});

  return (
    <>
      {activeStep === 0 && (
        <Box>
          <Typography variant="body2" className={classes.bold}>
            1.1 アプリをインストールする
          </Typography>
          <Box mt={2} pl={3}>
            <Typography variant="body2">
              ・iPhoneまたはiPadをお使いの方
            </Typography>
            <a
              href="https://apps.apple.com/jp/app/id1084947280"
              target="_blank"
              rel="noopener noreferrer"
              style={{ margin: '10px 0 0 10px', display: 'block' }}
            >
              <img alt="App Store からダウンロード" src={appStoreIcon} />
            </a>
            <Typography variant="body2" className={classes.exText01}>
              ・Androidをお使いの方
            </Typography>
            <a
              href="https://play.google.com/store/apps/details?id=com.lixil.hmnet.android.smartjp"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                alt="Google Play で手に入れよう"
                src={googlePlayIcon}
                style={{ width: '148px' }}
              />
            </a>
          </Box>
          <Typography variant="body2" className={classes.bold}>
            1.2 ホームユニットとWiFiを接続する
          </Typography>
          <Box my={2} pl={3}>
            アプリを使ってホームユニットとWiFiを接続します。
            <br />
            <Link
              href="https://www.lixil.co.jp/lineup/gate_fence/homenetwork/manual/2/#!/id_oi01_0022"
              target="_blank"
            >
              初期設定マニュアル
            </Link>
            に沿って接続してください。
          </Box>
          <Typography variant="body2" className={classes.bold}>
            1.3 スマート宅配ポストを登録する
          </Typography>
          <Box my={2} pl={3}>
            アプリにスマート宅配ポストを登録します。
            <br />
            <Link
              href="https://www.lixil.co.jp/lineup/gate_fence/homenetwork/manual/2/#!/id_oi01_1810"
              target="_blank"
            >
              クラウドサービス設定マニュアル
            </Link>
            に沿って接続してください。
          </Box>
        </Box>
      )}
      {activeStep === 1 && (
        <List disablePadding>
          <ListItem
            className={classes.listItem}
            disableGutters
            alignItems="flex-start"
            dense
          >
            <img
              src={operation01}
              alt="外扉を開け開始をタッチ"
              className={classes.exImg}
            />
            <Box>
              スマホの「ホームシステム」アプリを起動し、「宅配ボックス」→「設定」→「スマートエクステリア
              クラウドサービスの設定」を表示。
            </Box>
          </ListItem>
          <ListItem
            className={classes.listItem}
            disableGutters
            alignItems="flex-start"
          >
            <img
              src={operation02}
              alt="クラウドサービスを利用する"
              className={classes.exImg}
            />
            <Box>
              ・「クラウドサービスを利用する。」を有効にする。
              <br />
              ・「ホームユニット機器番号」をメモまたはコピー。
              <br />
              <br />
              アプリを終了し、本画面に戻って登録を進めてください。
              <br />
              <br />
              <Link href="jp.co.lixil.HomeSystem://">
                <span>ホームシステムアプリを起動（iPhone）</span>
                <LaunchIcon
                  style={{ fontSize: '1rem', verticalAlign: 'text-bottom' }}
                />
              </Link>
              <br />
              <Link href="intent://HomeSystem/#Intent;scheme=jp.co.lixil.HomeSystem;package=com.lixil.hmnet.android.smartjp;end">
                <span>ホームシステムアプリを起動（Android）</span>
                <LaunchIcon
                  style={{ fontSize: '1rem', verticalAlign: 'text-bottom' }}
                />
              </Link>
            </Box>
          </ListItem>
        </List>
      )}
    </>
  );
};

interface DeliveryBoxAddDialogProps {
  open: boolean;
  onClose?: () => void;
}

const DeliveryBoxAddDialog: React.FC<DeliveryBoxAddDialogProps> = ({
  open,
  onClose,
}) => {
  const classes = useStyles({});
  const theme = useTheme();

  const maxSteps = 2;
  const [activeStep, setActiveStep] = useState(0);

  const handleNext = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  }, []);

  const handleBack = useCallback(() => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  }, []);

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" color="primary">
          {activeStep === 0 && '1. アプリにスマート宅配ポストを登録する'}
          {activeStep === 1 && (
            <>
              2. クラウド接続をONにする
              <br />
              3. 「ホームユニット機器番号」をメモまたはコピーする
            </>
          )}
        </Typography>
      </DialogTitle>
      <DialogContent>
        <TutorialSteps activeStep={activeStep} />
      </DialogContent>
      <DialogActions>
        <MobileStepper
          steps={maxSteps}
          position="static"
          variant="text"
          activeStep={activeStep}
          nextButton={
            <Button
              size="small"
              onClick={handleNext}
              disabled={activeStep === maxSteps - 1}
            >
              Next
              {theme.direction === 'rtl' ? (
                <KeyboardArrowLeft />
              ) : (
                <KeyboardArrowRight />
              )}
            </Button>
          }
          backButton={
            <Button
              size="small"
              onClick={handleBack}
              disabled={activeStep === 0}
            >
              {theme.direction === 'rtl' ? (
                <KeyboardArrowRight />
              ) : (
                <KeyboardArrowLeft />
              )}
              Back
            </Button>
          }
          style={{ width: '100%' }}
        />
      </DialogActions>
      <DialogActions className={classes.cancelButton}>
        <Button onClick={onClose}>閉じる</Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryBoxAddDialog;
