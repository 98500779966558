import clsx from 'clsx';
import React, { useEffect, useState, useCallback } from 'react';
import { Link, NavLink, useHistory } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Badge from '@material-ui/core/Badge';
import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import CircularProgress from '@material-ui/core/CircularProgress';
import Container from '@material-ui/core/Container';
import Divider from '@material-ui/core/Divider';
import Drawer from '@material-ui/core/Drawer';
import Hidden from '@material-ui/core/Hidden';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import CardMembership from '@material-ui/icons/CardMembership';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import DeviceHub from '@material-ui/icons/DeviceHub';
import HomeIcon from '@material-ui/icons/Home';
import MenuIcon from '@material-ui/icons/Menu';
import MoreHoriz from '@material-ui/icons/MoreHoriz';
import NotificationsIcon from '@material-ui/icons/Notifications';
import NotificationsActiveIcon from '@material-ui/icons/NotificationsActive';

import { useUpdateUser } from '../../hooks/useUpdateUser';
import { useGetDeviceHistory } from '../../hooks/useGetDeviceHistory';
import { useGetUser } from '../../hooks/useGetUser';
import { useListNotice } from '../../hooks/useListNotice';
import PageTitleBar from '../atoms/PageTitleBar';
import HeaderBarLogo from '../molecules/HeaderBarLogo';

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
      height: '100%',
    },
    toolbarIcon: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: '0 8px',
      ...theme.mixins.toolbar,
    },
    appBar: {
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        marginLeft: drawerWidth,
        width: `calc(100% - ${drawerWidth}px)`,
      },
    },
    menuButton: {
      marginRight: 36,
    },
    menuButtonHidden: {
      display: 'none',
    },
    title: {
      flexGrow: 1,
    },
    drawerPaper: {
      position: 'relative',
      whiteSpace: 'nowrap',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('md')]: {
        width: drawerWidth,
      },
    },
    drawerPaperClose: {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      width: theme.spacing(7),
    },
    appBarSpacer: theme.mixins.toolbar,
    content: {
      flexGrow: 1,
      overflow: 'auto',
    },
    container: {
      paddingTop: theme.spacing(2),
      paddingBottom: theme.spacing(10),
    },
    logoText: {
      display: 'block',
      color: theme.palette.common.white,
      lineHeight: 1,
      fontWeight: 'bold',
      textDecoration: 'none',
      paddingLeft: theme.spacing(1),
    },
    subTitle: {
      fontSize: theme.typography.subtitle2.fontSize,
    },
    footer: {
      bottom: 0,
      boxShadow: theme.shadows[2],
      marginTop: 'auto',
      paddingBottom: 'constant(safe-area-inset-bottom)',
      position: 'fixed',
      width: '100%',
      zIndex: 1202,
    },
    customSafeAreaInsetBottom: {
      paddingBottom: 'env(safe-area-inset-bottom)',
    },

    appBarIcon: {
      padding: 0,
      marginLeft: '1rem',
      marginBottom: '-4px',
    },
    appBarIconLabel: {
      flexDirection: 'column',
      fontSize: theme.typography.caption.fontSize,
    },
    activeAppBarIcon: {
      animationName: `$activeAppBarIconAnim`,
      animationDuration: '30s',
      animationTimingFunction: 'linear',
      animationIterationCount: 'infinite',
    },
    '@keyframes activeAppBarIconAnim': {
      from: {
        transform: 'translateX(3px) rotate(2deg)',
      },
      '0.25%': {
        transform: 'translateX(-3px) rotate(-2deg)',
      },
      '0.5%': {
        transform: 'translateX(3px) rotate(2deg)',
      },
      '0.75%': {
        transform: 'translateX(-3px) rotate(-2deg)',
      },
      '1%': {
        transform: 'translateX(3px) rotate(2deg)',
      },
      '1.25%': {
        transform: 'translateX(-3px) rotate(-2deg)',
      },
      '1.5%': {
        transform: 'translateX(3px) rotate(2deg)',
      },
      '1.75%': {
        transform: 'translateX(-3px) rotate(-2deg)',
      },
      '2%': {
        transform: 'none',
      },
      to: {
        transform: 'none',
      },
    },
    navLink: {
      '&.active': {
        color: theme.palette.primary.main,
        background: theme.palette.grey[200],
        '& .MuiListItemIcon-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    loader: {
      position: 'fixed',
      height: '40px',
      width: '40px',
      left: 'calc(50% - 40px / 2)',
      top: 'calc(50% - 40px / 2)',
    },
  }),
);

const GenericTemplate: React.FC<{
  children: React.ReactNode;
  title?: string;
  loading?: boolean;
}> = (props) => {
  const classes = useStyles();

  const [open, setOpen] = useState(true);

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const updateUser = useUpdateUser();
  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isError && !getUser.isLoading) {
      getUser.getData();
    }
  }, [getUser]);

  const listNotice = useListNotice();

  const [latestNoticeDate, setLatestNoticeDate] = useState('');
  const [isUnreadNotice, setIsUnreadNotice] = useState(false);

  useEffect(() => {
    if (
      listNotice.isCompleted &&
      listNotice.data.length > 0 &&
      getUser.isCompleted
    ) {
      const latestNoticeDate = getUser.data.lastReadDateForNotice;

      const latestNotice = listNotice.data.sort((a, b) => {
        if (a.date > b.date) return -1;
        if (a.date < b.date) return 1;

        return 0;
      })[0];

      setLatestNoticeDate(latestNotice.date.toISOString());

      if (latestNoticeDate === latestNotice.date.toISOString()) {
        setIsUnreadNotice(false);
      } else {
        setIsUnreadNotice(true);
      }
    }
  }, [listNotice, getUser, setLatestNoticeDate]);

  const deviceHistories = useGetDeviceHistory();

  useEffect(() => {
    if (
      !deviceHistories.isCompleted &&
      !deviceHistories.isError &&
      !deviceHistories.isLoading
    ) {
      deviceHistories.getData();
    }
  }, [deviceHistories]);

  const [latestDeviceHistoryDate, setLatestDeviceHistoryDate] = useState('');
  const [isUnreadDeviceHistory, setIsUnreadDeviceHistory] = useState(false);

  useEffect(() => {
    if (
      deviceHistories.isCompleted &&
      deviceHistories.data.deviceHistories.length > 0
    ) {
      const latestDeviceHistoryDate = getUser.data.lastReadDateForDeviceHistory;

      const latestDeviceHistory = deviceHistories.data.deviceHistories.sort(
        (a, b) => {
          if (a.createAt > b.createAt) return -1;
          if (a.createAt < b.createAt) return 1;

          return 0;
        },
      )[0];

      setLatestDeviceHistoryDate(latestDeviceHistory.createAt.toString());

      if (latestDeviceHistoryDate === latestDeviceHistory.createAt.toString()) {
        setIsUnreadDeviceHistory(false);
      } else {
        setIsUnreadDeviceHistory(true);
      }
    } else {
      setIsUnreadDeviceHistory(false);
    }
  }, [deviceHistories]);

  const [isLoading, setIsLoading] = useState(false);

  const history = useHistory();

  const [isUpdatedLastReadDateForNotice, setIsUpdatedLastReadDateForNotice] =
    useState(false);

  const clickNoticeButton = useCallback(() => {
    if (listNotice.data.length > 0) {
      updateUser.handleSubmit({
        lastReadDateForNotice: latestNoticeDate,
      });
    }

    setIsUpdatedLastReadDateForNotice(true);
    setIsLoading(true);
  }, [
    listNotice.data,
    updateUser,
    latestNoticeDate,
    setIsUpdatedLastReadDateForNotice,
    setIsLoading,
  ]);

  useEffect(() => {
    if (
      isUpdatedLastReadDateForNotice &&
      !updateUser.isError &&
      !updateUser.isLoading
    ) {
      setIsUpdatedLastReadDateForNotice(false);
      setIsLoading(false);

      history.push('/notices');
    }
  }, [
    isUpdatedLastReadDateForNotice,
    updateUser,
    setIsUpdatedLastReadDateForNotice,
    setIsLoading,
    history,
  ]);

  const [
    isUpdatedLastReadDateForDeviceHistory,
    setIsUpdatedLastReadDateForDeviceHistory,
  ] = useState(false);

  const clickDeviceHistoryButton = useCallback(() => {
    if (deviceHistories.data.deviceHistories.length > 0) {
      updateUser.handleSubmit({
        lastReadDateForDeviceHistory: latestDeviceHistoryDate,
      });
    }

    setIsUpdatedLastReadDateForDeviceHistory(true);
    setIsLoading(true);
  }, [
    deviceHistories.data.deviceHistories,
    updateUser,
    latestDeviceHistoryDate,
    setIsUpdatedLastReadDateForDeviceHistory,
    setIsLoading,
  ]);

  useEffect(() => {
    if (
      isUpdatedLastReadDateForDeviceHistory &&
      !updateUser.isError &&
      !updateUser.isLoading
    ) {
      setIsUpdatedLastReadDateForDeviceHistory(false);
      setIsLoading(false);

      history.push('/devices/histories');
    }
  }, [
    isUpdatedLastReadDateForDeviceHistory,
    updateUser,
    setIsUpdatedLastReadDateForDeviceHistory,
    setIsLoading,
    history,
  ]);

  return (
    <div className={classes.root}>
      {(props.loading || isLoading) && (
        <CircularProgress className={classes.loader} />
      )}
      <AppBar
        position="absolute"
        className={clsx(classes.appBar, open && classes.appBarShift)}
      >
        <Toolbar>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(
              classes.menuButton,
              open && classes.menuButtonHidden,
            )}
          >
            <MenuIcon />
          </IconButton>
          <HeaderBarLogo to="/" />
          <Typography
            component="p"
            variant="subtitle1"
            color="inherit"
            className={classes.title}
          >
            <Link to="/" className={classes.logoText}>
              あずけるBOX
              <br />
              <span className={classes.subTitle}>メンバーズサイト</span>
            </Link>
          </Typography>
          <IconButton
            onClick={clickDeviceHistoryButton}
            color="inherit"
            className={classes.appBarIcon}
            classes={{
              label: classes.appBarIconLabel,
            }}
          >
            <Badge
              variant="dot"
              color="secondary"
              invisible={!isUnreadDeviceHistory}
            >
              {isUnreadDeviceHistory ? (
                <AccessTimeIcon className={classes.activeAppBarIcon} />
              ) : (
                <AccessTimeIcon />
              )}
            </Badge>
            利用履歴
          </IconButton>
          <IconButton
            onClick={clickNoticeButton}
            color="inherit"
            className={classes.appBarIcon}
            classes={{
              label: classes.appBarIconLabel,
            }}
          >
            <Badge variant="dot" color="secondary" invisible={!isUnreadNotice}>
              {isUnreadNotice ? (
                <NotificationsActiveIcon className={classes.activeAppBarIcon} />
              ) : (
                <NotificationsIcon />
              )}
            </Badge>
            お知らせ
          </IconButton>
        </Toolbar>
      </AppBar>
      <Hidden smDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          <div className={classes.toolbarIcon}>
            <IconButton onClick={handleDrawerClose}>
              <ChevronLeftIcon />
            </IconButton>
          </div>
          <Divider />
          <List>
            <ListItem
              button
              exact
              component={NavLink}
              to="/"
              className={classes.navLink}
            >
              <ListItemIcon>
                <HomeIcon />
              </ListItemIcon>
              <ListItemText primary="ホーム" />
            </ListItem>
            <ListItem
              button
              exact
              component={NavLink}
              to="/services"
              className={classes.navLink}
            >
              <ListItemIcon>
                <CardMembership />
              </ListItemIcon>
              <ListItemText primary="サービス" />
            </ListItem>
            <ListItem
              button
              exact
              component={NavLink}
              to="/devices"
              className={classes.navLink}
            >
              <ListItemIcon>
                <DeviceHub />
              </ListItemIcon>
              <ListItemText primary="デバイス" />
            </ListItem>
            <ListItem
              button
              exact
              component={NavLink}
              to="/settings"
              className={classes.navLink}
            >
              <ListItemIcon>
                <MoreHoriz />
              </ListItemIcon>
              <ListItemText primary="その他" />
            </ListItem>
          </List>
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          {!isLoading && (
            <>
              {props.title && <PageTitleBar>{props.title}</PageTitleBar>}
              {!props.loading && props.children}
            </>
          )}
        </Container>
      </main>
      <Hidden mdUp>
        <footer
          className={`${classes.footer} ${classes.customSafeAreaInsetBottom}`}
        >
          <BottomNavigation showLabels>
            <BottomNavigationAction
              exact
              component={NavLink}
              to="/"
              label="ホーム"
              icon={<HomeIcon />}
              className={classes.navLink}
            />
            <BottomNavigationAction
              exact
              component={NavLink}
              to="/services"
              label="サービス"
              icon={<CardMembership />}
              className={classes.navLink}
            />
            <BottomNavigationAction
              exact
              component={NavLink}
              to="/devices"
              label="デバイス"
              icon={<DeviceHub />}
              className={classes.navLink}
            />
            <BottomNavigationAction
              exact
              component={NavLink}
              to="/settings"
              label="その他"
              icon={<MoreHoriz />}
              className={classes.navLink}
            />
          </BottomNavigation>
        </footer>
      </Hidden>
    </div>
  );
};

export default GenericTemplate;
