import Cookies from 'js-cookie';
import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';

import AuthService from '../../utils/AuthService';
import DescreptionPage from '../pages/Descreption';

const SECURE_COOKIE =
  process.env.REACT_APP_SECURE_COOKIE! === 'true' ? true : false;

type AuthProps = {
  children: React.ReactNode;
};

const AFTER_LOGIN_REDIRECT_URL = 'redirect_url';

const checkOnline = async (): Promise<boolean> => {
  const timestamp = Math.ceil(DateTime.local().toSeconds());

  try {
    await fetch(`/favicon.ico?${timestamp}`);
  } catch {
    return false;
  }

  return true;
};

const AuthPage: React.FC<AuthProps> = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);

  const checkAuthenticated = useCallback(async () => {
    if (AuthService.isAuthenticated()) {
      setIsAuthenticated(true);
    } else {
      try {
        if (await checkOnline()) {
          const refreshToken = AuthService.getRefreshToken();

          await AuthService.updateToken({
            redirectUri: `${window.location.origin}/callback`,
            refreshToken: refreshToken,
          });

          setIsAuthenticated(true);
        }
      } catch (e) {
        Cookies.set(AFTER_LOGIN_REDIRECT_URL, window.location.href, {
          secure: SECURE_COOKIE,
          sameSite: 'Lax',
        });

        setIsAuthenticated(false);
      }
    }

    setIsLoaded(true);
    setTimeout(checkAuthenticated, 10000);
  }, []);

  useEffect(() => {
    checkAuthenticated();
  }, [checkAuthenticated]);

  if (isAuthenticated) {
    return <>{children}</>;
  }

  if (!isLoaded) {
    return <></>;
  }

  return <DescreptionPage />;
};

export default AuthPage;
