import React from 'react';
import Paper from '@material-ui/core/Paper';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import { Typography, Link } from '@material-ui/core';

import ContactForm from '../organisms/ContactForm';
import GenericTemplate from '../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    content: {
      padding: theme.spacing(3, 2),
    },
    supportCenter: {
      padding: theme.spacing(3, 2),
      margin: theme.spacing(0, 0, 2, 0),
    },
  }),
);

const SupportPage: React.FC = () => {
  const classes = useStyles();

  return (
    <GenericTemplate title="サポートセンター・お問合せフォーム">
      <Paper className={classes.supportCenter}>
        <Box mb={3}>
          <Typography variant="subtitle1">
            商品のご相談・お問い合わせは下記WEBサイトからも行えます
          </Typography>
          <Typography variant="subtitle2">
            <Link target="_blank" href="http://www.lixil.co.jp/support/">
              http://www.lixil.co.jp/support/
            </Link>
          </Typography>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle1">
            商品についてのお問合せ・部品の購入は、お客様相談センターまで
          </Typography>
          <Typography variant="subtitle2">
            TEL.&nbsp;
            <Link style={{ fontWeight: 'bold' }} href="tel:0120-126-001">
              0120-126-001
            </Link>
            <br />
            受付時間 月～金 9:00~18:00
            <br />
            土日祝 9:00~17:00
            <br />
            (定休日:ゴールデンウィーク・夏期休暇・年末年始)
          </Typography>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle1">
            修理のご依頼は、LIXIL修理受付センターまで
          </Typography>
          <Typography variant="subtitle2" noWrap>
            TEL.&nbsp;
            <Link style={{ fontWeight: 'bold' }} href="tel:0120-413-433">
              0120-413-433
            </Link>
            <br />
            受付時間 月～金 9:00~18:00
            <br />
            土日祝 9:00~17:00
            <br />
            (定休日:ゴールデンウィーク・夏期休暇・年末年始)
          </Typography>
        </Box>
        <Box my={3}>
          <Typography variant="subtitle1">
            スマートエクステリアの設定やネットワーク環境に関する問い合わせ・ご相談は、LIXILスマートエクステリア
            サポートセンターまで
          </Typography>
          <Typography variant="subtitle2">
            TEL.&nbsp;
            <Link style={{ fontWeight: 'bold' }} href="tel:0120-126-008">
              0120-126-008
            </Link>
            <br />
            受付時間 月～金 9:00~18:00
            <br />
            土日祝 9:00~17:00
            <br />
            (定休日:ゴールデンウィーク・夏期休暇・年末年始)
          </Typography>
        </Box>
      </Paper>

      <Paper className={classes.content}>
        <Typography variant="subtitle1">
          あずけるBOXメンバーズサイトに関するお問い合わせ
        </Typography>
        <Typography variant="subtitle2">
          下記フォームに必要事項を記載して送信ボタンを押してください
        </Typography>
        <ContactForm />
      </Paper>
    </GenericTemplate>
  );
};

export default SupportPage;
