import * as React from 'react';
import { Link } from 'react-router-dom';
import { StyleRules } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { ReactComponent as Logo } from '../../assets/logo.svg';

const useStyles = makeStyles(
  (): StyleRules => ({
    logo: {
      height: '32px',
      width: '32px',
    },
  }),
);

interface HeaderBarLogoPresenterProps {
  to: string;
}

export const HeaderBarLogoPresenter: React.FC<HeaderBarLogoPresenterProps> = ({
  to,
}) => {
  const classes = useStyles();

  return (
    <Typography variant="inherit" component="h1" className={classes.logo}>
      <Link to={to}>
        <Logo className={classes.logo} />
      </Link>
    </Typography>
  );
};

export default HeaderBarLogoPresenter;
