import React, { forwardRef, useCallback } from 'react';
import { Link } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import bgManualTop from '../../assets/bg-manual-top.png';
import btnManualMottekite from '../../assets/btn-manual-mottekite.png';
import btnManualMottette from '../../assets/btn-manual-mottette.png';
import { ReactComponent as Logo } from '../../assets/logo-green.svg';
import manualMottekite from '../../assets/manual-mottekite.png';
import manualMottette from '../../assets/manual-mottette.png';
import { useDialog } from '../../hooks/useDialog';
import AuthService from '../../utils/AuthService';
import PageTitleBar from '../atoms/PageTitleBar';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      height: '50px',
      width: '50px',
      padding: '6px',
      marginTop: '-0.6rem',
    },
    text: {
      color: theme.palette.grey[800],
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    button: {
      marginRight: '0.2rem',
      marginLeft: '0.2rem',
      padding: '1px 10px',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
    dialogTitle: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    dialogBody: {
      padding: theme.spacing(2, 1),
    },
    bgManualTop: {
      background: `#F2FBF8 url(${bgManualTop}) no-repeat left top`,
      backgroundSize: '100% auto',
      paddingTop: '100%',
    },
    btnManual: {
      display: 'block',
      width: '98%',
      margin: '0 auto',
    },
    btnUsage: {
      display: 'block',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: '1rem',
      marginLeft: 'auto',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const DeliveryBoxShareServiceHeader: React.FC = () => {
  const classes = useStyles();
  const authenticated = AuthService.isAuthenticated();

  const usageTopDialog = useDialog();
  const usageMottekiteDialog = useDialog();
  const usageMottetteDialog = useDialog();

  const cntMottette = useCallback(() => {
    usageMottetteDialog.handleOpen();
    usageMottekiteDialog.handleClose();
  }, [usageMottetteDialog, usageMottekiteDialog]);

  const cntMottekite = useCallback(() => {
    usageMottetteDialog.handleClose();
    usageMottekiteDialog.handleOpen();
  }, [usageMottetteDialog, usageMottekiteDialog]);

  return (
    <>
      <Box mb={2}>
        <PageTitleBar>サービス</PageTitleBar>
        <Box textAlign="center">
          <Logo className={classes.icon} />
        </Box>
        <Box textAlign="center" mb={1}>
          <Typography className={classes.text}>あずけるボックス</Typography>
        </Box>
        <Box textAlign="center">
          {authenticated && (
            <>
              <Button
                className={classes.button}
                component={Link}
                to="/services/delivery-box-shares"
                color="primary"
                variant="outlined"
              >
                申し込み
              </Button>
              <Button
                className={classes.button}
                component={Link}
                to="/services/delivery-box-shares/histories"
                color="primary"
                variant="outlined"
              >
                利用履歴
              </Button>
            </>
          )}
          <Button
            className={classes.button}
            onClick={usageTopDialog.handleOpen}
            color="primary"
            variant="outlined"
          >
            使い方
          </Button>
        </Box>
      </Box>
      <Dialog
        onClose={usageTopDialog.handleClose}
        open={usageTopDialog.open}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={usageTopDialog.handleClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              使い方
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.dialogBody}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Box mb={3} className={classes.bgManualTop}>
              <Button onClick={cntMottette} className={classes.btnManual}>
                <img
                  src={btnManualMottette}
                  alt="「もってって」の使い方 宅配ボックスを使って、ご近所さんにものを渡すサービスです"
                  width="100%"
                />
              </Button>
              <Button onClick={cntMottekite} className={classes.btnManual}>
                <img
                  src={btnManualMottekite}
                  alt="「もってきて」の使い方 宅配ボックスを使って、ご近所さんからものを受け取るサービスです"
                  width="100%"
                />
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        onClose={usageMottetteDialog.handleClose}
        open={usageMottetteDialog.open}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={usageMottetteDialog.handleClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              「もってって」の使い方
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.dialogBody}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Box>
              <img src={btnManualMottette} alt="" width="100%" />
              <img
                src={manualMottette}
                alt="「もってって」の使い方 宅配ボックスを使って、ご近所さんにものを渡すサービスです"
                width="100%"
                style={{ marginTop: '10px' }}
              />
              <Button onClick={cntMottekite} className={classes.btnUsage}>
                &gt;「もってきて」の使い方をみる
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
      <Dialog
        onClose={usageMottekiteDialog.handleClose}
        open={usageMottekiteDialog.open}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={usageMottekiteDialog.handleClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              「もってきて」の使い方
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.dialogBody}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            <Box>
              <img src={btnManualMottekite} alt="" width="100%" />
              <img
                src={manualMottekite}
                alt="「もってきて」の使い方 宅配ボックスを使って、ご近所さんからものを受け取るサービスです"
                width="100%"
                style={{ marginTop: '10px' }}
              />
              <Button onClick={cntMottette} className={classes.btnUsage}>
                &gt;「もってって」の使い方をみる
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default DeliveryBoxShareServiceHeader;
