import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Link from '@material-ui/core/Link';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import LaunchIcon from '@material-ui/icons/Launch';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import MuiAlert from '@material-ui/lab/Alert';

import { useCancelDeliveryReservation } from '../../../hooks/useCancelDeliveryReservation';
import { useCreateSagawaService } from '../../../hooks/useCreateSagawaService';
import { useDialog } from '../../../hooks/useDialog';
import { useGetSagawa } from '../../../hooks/useGetSagawaService';
import { useListDelivery } from '../../../hooks/useListDelivery';
import { useListDevice } from '../../../hooks/useListDevice';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import MessageBox from '../../molecules/MessageBox';
import CancelReservationDialog from '../../organisms/CancelReservationDialog';
import DeliveryReserveServiceHeader from '../../organisms/DeliveryReservationServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    button: {
      marginTop: theme.spacing(2),
    },
    icon: {
      verticalAlign: 'bottom',
      marginLeft: '2px',
    },
    appliedIcon: {
      display: 'flex',
      justifyContent: 'flex-end',
      marginBottom: '0.5rem',
    },
    cancelButton: {
      display: 'block',
      marginLeft: 'auto',
      marginTop: theme.spacing(2),
    },
  }),
);

const ReservationDialog: React.FC<{
  open: boolean;
  onClose: () => void;
  sendContact: () => void;
}> = ({ open, onClose, sendContact }) => {
  const classes = useStyles();

  return (
    <Dialog
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" color="primary">
          佐川急便サービス 利用申し込み
        </Typography>
      </DialogTitle>
      <DialogContent>
        <Typography variant="body2" gutterBottom>
          ご利用申し込みされた方への連絡は、原則メールにて行います。
          <br />
          <br />
          弊社からは、「@lixil.com」「@sys.lixil.com」「@smartexterior.net」のドメインから送付いたします。
          <span style={{ color: 'red' }}>
            お申し込みの際には必ず受信設定を行ってください。
          </span>
        </Typography>
      </DialogContent>
      <DialogActions className={classes.cancelButton}>
        <Button onClick={onClose}>キャンセル</Button>
        <Button onClick={sendContact} color="primary">
          お申し込み
        </Button>
      </DialogActions>
    </Dialog>
  );
};

const DeliveryReservationPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const listDevice = useListDevice();

  useEffect(() => {
    if (
      !listDevice.isCompleted &&
      !listDevice.isError &&
      !listDevice.isLoading
    ) {
      listDevice.getData();
    }
  }, [listDevice]);

  const listDelivery = useListDelivery();

  useEffect(() => {
    if (
      listDevice.data.length > 0 &&
      !listDevice.isCompleted &&
      !listDevice.isError &&
      !listDevice.isLoading
    ) {
      listDelivery.getData(listDevice.data[0].deviceId);
    }
  }, [listDelivery, listDevice]);

  const [deliveryId, setDeliveryId] = useState<undefined | string>();

  useEffect(() => {
    const deliveryId =
      listDelivery.data.find((d) => d.status === 'reserved')?.deliveryId ||
      undefined;
    setDeliveryId(deliveryId);
  }, [listDelivery.data]);

  const cancelDeliveryReservation = useCancelDeliveryReservation();
  const cancelReservationDialog = useDialog();

  const handleCancelDeliveryReservation = useCallback(() => {
    cancelDeliveryReservation.handleSubmit(deliveryId || '');
    cancelReservationDialog.handleClose();
  }, [cancelDeliveryReservation, cancelReservationDialog, deliveryId]);

  useEffect(() => {
    if (cancelDeliveryReservation.isCompleted) {
      history.push('/services');
    }
  }, [cancelDeliveryReservation.isCompleted, history]);

  const [isExistDeliveryBox, setIsExistDeliveryBox] = useState(false);
  const [isExistAddress, setIsExistAddress] = useState(false);

  const getSagawa = useGetSagawa();

  useEffect(() => {
    if (listDevice.isCompleted) {
      if (listDevice.data.length > 0) {
        const deliveryBox = listDevice.data[0];

        setIsExistDeliveryBox(true);

        if (deliveryBox.zip) {
          setIsExistAddress(true);
        } else {
          setIsExistAddress(false);
        }

        if (
          !getSagawa.isCompleted &&
          !getSagawa.isLoading &&
          !getSagawa.isError
        ) {
          getSagawa.getData(deliveryBox.deviceId);
        }
      } else {
        setIsExistDeliveryBox(false);
        setIsExistAddress(false);
      }
    }
  }, [listDevice, getSagawa]);

  const createSagawaService = useCreateSagawaService();
  const createReservationDialog = useDialog();

  return (
    <GenericTemplate loading={listDevice.isLoading || getSagawa.isLoading}>
      <DeliveryReserveServiceHeader
        hideMenu={getSagawa.data.status !== 'complete'}
      />
      {createSagawaService.isError && (
        <Box mb={2}>
          <MuiAlert severity="error">お申し込みに失敗しました。</MuiAlert>
        </Box>
      )}
      {cancelDeliveryReservation.isError && (
        <Box mb={2}>
          <MuiAlert severity="error">予約の取り消しに失敗しました。</MuiAlert>
        </Box>
      )}
      {getSagawa.data.status === 'unrequested' ||
      getSagawa.data.status === 'reject' ? (
        <Box mb={2}>
          <MuiAlert severity="info">
            現在、江東区、江戸川区の方が対象のサービスです。
          </MuiAlert>
        </Box>
      ) : getSagawa.data.status === 'request' ? (
        <Box mb={2}>
          <MuiAlert severity="info">現在手続き中です。</MuiAlert>
        </Box>
      ) : (
        getSagawa.data.status === 'complete' && (
          <Box mb={2}>
            <MuiAlert severity="info">サービスはご利用可能です。</MuiAlert>
          </Box>
        )
      )}
      <MessageBox>
        <Typography variant="body2" component="div">
          {getSagawa.data.status === 'request' && (
            <Box mb={2} color="#f00">
              オペレーターが申し込み内容を確認しております。3営業日程お時間をいただきますので、ご了承ください。
            </Box>
          )}
          ■佐川急便サービスとは
          <br />
          佐川急便スマートクラブとLIXIL宅配ボックスをつなげて、時間にしばられない生活を実現します。
          <br />
          お荷物の配達日を知り、受け取りを確実に。
          <Box mt={2}>
            「利用申し込み」をタップしてサービスをお申し込みしてください。
            <br />
            お申し込み後、LIXILから「宛先追加用&nbsp;メールアドレス」が届きますので、佐川急便スマートクラブに追加登録してください。
            <br />
            <Link
              href="/files/delivery-service-setting.pdf"
              target="_blank"
              color="primary"
            >
              サービスの設定方法
              <PictureAsPdfIcon fontSize="small" className={classes.icon} />
            </Link>
          </Box>
          <Box my={1}>
            ご利用には佐川急便スマートクラブに登録(無料)が必要です。
            <br />
            <Link
              href="https://www.e-service.sagawa-exp.co.jp/portal/do/login/show"
              target="_blank"
              color="primary"
            >
              スマートクラブ
              <LaunchIcon fontSize="small" className={classes.icon} />
            </Link>
          </Box>
          本サービス、お申込みに関するお問い合わせは、
          <Link component={RouterLink} to={`/contact`}>
            こちら
          </Link>
          よりご連絡ください。
        </Typography>
      </MessageBox>
      {createSagawaService.isLoading ? (
        <Box mt={2}>
          <LoadingIndicator />
        </Box>
      ) : (
        <>
          {listDevice.isError ? (
            <Box mt={2}>
              <MuiAlert severity="error">
                ユーザー情報、宅配ボックス情報の取得に失敗しました。
              </MuiAlert>
            </Box>
          ) : (
            listDevice.isCompleted && (
              <>
                {isExistDeliveryBox && isExistAddress ? (
                  <>
                    {getSagawa.data.status !== 'complete' && (
                      <Button
                        color="primary"
                        variant="contained"
                        fullWidth
                        onClick={createReservationDialog.handleOpen}
                        className={classes.button}
                        disabled={
                          getSagawa.data.status !== 'unrequested' &&
                          getSagawa.data.status !== 'reject'
                        }
                      >
                        利用申し込み
                        <br />
                        （江東区、江戸川区の方のみ）
                      </Button>
                    )}
                    {listDevice.data.length > 0 && (
                      <>
                        <ReservationDialog
                          open={createReservationDialog.open}
                          onClose={createReservationDialog.handleClose}
                          sendContact={async () => {
                            await createSagawaService.handleSubmit(
                              listDevice.data[0].deviceId,
                            );
                            createReservationDialog.handleClose();
                            await getSagawa.getData(
                              listDevice.data[0].deviceId,
                            );
                          }}
                        />
                        {listDevice.data[0].status === 'reserved' &&
                          listDevice.data[0].service === 'deliveryReserve' && (
                            <>
                              <Box mt={1}>
                                <MuiAlert severity="error">
                                  宅配ボックスは現在配達予約中です。
                                </MuiAlert>
                              </Box>
                              {deliveryId && (
                                <Box mt={1}>
                                  <Button
                                    component={RouterLink}
                                    to={`/services/delivery-reservations/${deliveryId}/view`}
                                    color="primary"
                                    variant="contained"
                                    fullWidth
                                  >
                                    現在の配達予約の詳細を確認する
                                  </Button>
                                </Box>
                              )}
                              <Box mt={1}>
                                <Button
                                  fullWidth
                                  onClick={cancelReservationDialog.handleOpen}
                                  color="secondary"
                                  variant="outlined"
                                >
                                  配達予約を取り消す
                                </Button>
                              </Box>
                              <CancelReservationDialog
                                open={cancelReservationDialog.open}
                                onClose={cancelReservationDialog.handleClose}
                                onCancelReservation={
                                  handleCancelDeliveryReservation
                                }
                              />
                            </>
                          )}
                      </>
                    )}
                  </>
                ) : !isExistDeliveryBox ? (
                  <>
                    <Box mt={2}>
                      <MuiAlert severity="error" icon={false}>
                        サービスのご利用には宅配ボックスの登録が必要です。
                      </MuiAlert>
                    </Box>
                    <Button
                      component={RouterLink}
                      to="/devices/delivery-boxes/add"
                      color="primary"
                      variant="contained"
                      fullWidth
                      className={classes.button}
                    >
                      宅配ボックスを登録する
                    </Button>
                  </>
                ) : (
                  <>
                    <Box mt={2}>
                      <MuiAlert severity="error" icon={false}>
                        サービスのご利用には住所の登録が必要です。
                      </MuiAlert>
                    </Box>
                    <Button
                      component={RouterLink}
                      to={`/devices/delivery-boxes/${listDevice.data[0].deviceId}/address/add?redirect=/services/delivery-reservations`}
                      color="primary"
                      variant="contained"
                      fullWidth
                      className={classes.button}
                    >
                      住所を登録する
                    </Button>
                  </>
                )}
              </>
            )
          )}
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryReservationPage;
