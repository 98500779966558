import React from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

import GenericTemplate from '../templates/GenericTemplate';

const TermsPage: React.FC = () => {
  return (
    <GenericTemplate title="スマートエクステリア クラウドサービス利用規約">
      <Typography component="span" variant="body2" color="textPrimary">
        この利用規約は、株式会社LIXIL（以下、「当社」といいます。）が提供するスマートエクステリアクラウドサービス及びこれに付随して提供するサービス（あずけるBOXを含む。以下総称して「本サービス」といいます。）に関する契約です。登録ユーザーの皆さま（以下、「ユーザー」といいます。）には、本サービスの利用に際しては、ホームネットワークシステムの利用規約、LIXILのプライバシーポリシーならびにその他の適用ある規則、規約および条件注意事項、ガイドライン等（当社が随時契約者等に行う通知を含みます。（以下、「本規約」といいます。）をよくお読みください。ユーザーが本サービスのご使用を開始されることにより、本規約に同意したものとみなされます。ユーザーがこの契約に同意できない場合、本サービスをご利用いただくことはできません。
      </Typography>
      <List>
        <ListItem disableGutters>
          <ListItemText
            primary="第1条（適用）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                本規約は、ユーザーと当社との間の本サービスの利用に関わる一切の関係に適用されるものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第2条（利用登録）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                登録希望者が当社の定める方法によって利用登録を申請し、当社がこれを承認することによって、利用登録が完了するものとします。
                <br />
                当社は、利用登録の申請者に以下の事由があると判断した場合、利用登録の申請を承認しないことがあり、その理由については一切の開示義務を負わないものとします。
                <br />
                （１）利用登録の申請に際して虚偽の事項を届け出た場合
                <br />
                （２）本規約に違反したことがある者からの申請である場合
                <br />
                （３）その他、当社が利用登録を相当でないと判断した場合
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第3条（ユーザーIDおよびパスワードの管理）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                ユーザーは、自己の責任において、本サービスのユーザーIDおよびパスワードを管理するものとします。
                <br />
                ユーザーは、いかなる場合にも、ユーザーIDおよびパスワードを第三者に譲渡または貸与することはできません。当社は、ユーザーIDとパスワードの組み合わせが登録情報と一致してログインされた場合には、そのユーザーIDを登録しているユーザー自身による利用とみなします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第4条（利用者の責任）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                （１）ユーザーは、自己の責任において本サービスを利用するものとし、本サービスにおいて行った機器の操作・設定、データの収集・利用、コンテンツの作成・利用、その他の本アプリケーションの利用ほか一切の行為およびその結果について一切の責任を負うものとします。
                <br />
                （２）ユーザーは、本アプリケーションを正常に利用するために必要な機器、家電製品、通信機器、通信回線、パーソナルコンピュータ等の端末機器の設置、設定、維持・管理について、利用者自らの責任で行うものとします。
                <br />
                （３）ユーザーを特定する所定の認証方法（外部のアカウントによる認証を含みます。）で認証された本サービスの利用は、ユーザー自身による利用とみなされ、サービスの利用や商品の購入などによって料金や代金が発生した場合、当社は当該ユーザーに対して支払いを請求できるものとします。
                <br />
                （４）当社は、ユーザーが本規約に違反して本サービスを利用していると認めた場合、当社が必要かつ適切と判断する措置を講じます。ただし、当社は、かかる違反を防止または是正する義務を負いません。
                <br />
                （５）ユーザーは、本サービスの利用に際して、利用者本人に関する情報を登録する場合、真実、正確かつ完全な情報を提供しなければならず、常に最新の情報となるように修正しなければなりません。
                <br />
                （６）ユーザーは、本サービスを利用したことに起因して（当社がかかる利用を原因とするクレームを第三者より受けた場合を含みます。）、当社が直接的もしくは間接的に何らかの損害（弁護士費用の負担を含みます。）を被った場合、当社の請求にしたがって直ちにこれを補償しなければなりません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第5条（提携パートナーサービス）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                本サービスは、提携パートナーが提供するサービスまたはコンテンツを含む場合があります。かかるサービスまたはコンテンツに対する責任は、これを提供する提携パートナーが負います。また、かかるサービスまたはコンテンツには、これを提供する提携パートナーが定める利用規約その他の条件が適用されることがあります。これらの提携パートナーの利用に関する条件は、各提携パートナーサービスの利用規約をご覧ください。提携パートナーサービスに適用される利用条件に同意しない場合は、当該提携パートナーサービスを使用しないでください。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第6条（知的財産権）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                （１）本サービスで当社が提供する情報および本サービスを構成するプログラム等に係る著作権、商標権、その他の知的財産権（以下、「本サービスの知財権等」といいます。）は当社または当社が本サービスにおける利用許諾を受けた他の権利保有者に帰属します。ただし、本サービスに含まれる第三者のオープンソースソフトウェア（以下、OSSといいます。）部分の著作権は、各OSSの作者が保有しており、その限りではありません。本規約に明示的に定めがある場合を除き、利用者に本サービスの知財権等を移転することを許諾するものではありません。
                <br />
                （２）利用者は、本サービスにおいて、利用者が登録、記載または投稿等した著作物を、当社（当社の子会社、関連会社等を含む。以下本項において同じ）が、当社事業のために、利用者に対価を支払うことなく、非独占的に、期間の限定なく使用・利用できることに、あらかじめ同意するものとします。ただし、当該著作物に写真等利用者の個人情報やプライバシーに関わるものが含まれる場合は、当社は、利用者本人から、別途、事前の同意を得ることなくこれを使用・利用しないものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第7条（契約者に関する情報の利用及び保護）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                （１）当社は、ユーザーが利用申し込みに際して申告した内容その他の申し込み者が当社に提供した情報（ユーザー等に係る氏名及び名称、電話番号、住所及び居所等の情報等）、ユーザーのデバイス情報（センサーのペアリング状態・最終通信日時・最終電池アラート日時等の検知データ及び配送日、保証期間等）並びにユーザーの登録家電情報（ユーザーがアプリを通じて登録した、ユーザーの保有する当社所定の機器に関する型番、型番を取得するために撮影した画像・購入日・保障期限等の情報）を取得し、以下各号に定める目的その他当社が公開するプライバシーポリシーに定める目的で利用します。
                <br />
                （イ）当社がユーザーにとって有益と考える情報の通知、配信等を提供するため
                <br />
                （ロ）本サービスの提供、運営、ユーザー等の管理、品質向上、利用分析のため
                <br />
                （ハ）本規約に定める禁止行為へ対処するため
                <br />
                （２）当社は、検知データに分析統計処理を施し個人及び個々の通信を特定できないよう加工した情報を、以下各号に定める
                目的その他当社の業務の遂行上必要な範囲で利用できるものとします。
                <br />
                （イ）本サービスの各種機能の有効性評価、機能改善及び品質向上のため
                <br />
                （ロ）本サービスのご使用状況の計測・分析のため
                <br />
                （ハ）本サービスの障害・不具合時の調査・対応のため
                <br />
                （ニ）本サービスの新機能開発、マーケティング活動又は新しいサービスの開発・研究・サービス向上を目的とした統計分析を行うため
                <br />
                （３）当社は、前二項により取得又は加工した情報を、それぞれ前二項に定める目的に必要な範囲で業務委託先に開示することがあります。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第8条（利用料金および支払方法）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                （１）本サービスまたはコンテンツは、別途当社の定める場合を除き、無料でご利用いただけます。なお、当社が、本サービスを通じて提供する有料のサービスまたはコンテンツについては、別途当社の定める有料サービス用の利用規約が適用されるものとし、その利用にかかる費用および料金については、別途当社との間の契約によるものとします。
                <br />
                （２）本サービスには、当社と提携する他の事業者が提供するサービスまたはコンテンツを含む場合があります。他の事業者が提供する各種サービスまたはコンテンツに関する責任はこれを提供する事業者が負い、その利用にかかる費用および料金については、別途これを提供する事業者との間の契約によるものとします。
                <br />
                （３）前項にかかわらず、本サービスを利用するために必要な機器、家電製品、スマートフォン等の通信機器、パーソナルコンピュータ等の機器・端末に関する費用、およびネットワークサービス提供者との接続その他通信等に関する費用は、利用者が自らの責任と負担のもとで管理するものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第9条（禁止事項）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                ユーザーは、本サービスの利用に際して、以下に記載することを行なってはなりません。
                <br />
                （１）法令、裁判所の判決、決定もしくは命令、または法令上拘束力のある行政措置に違反する行為。
                <br />
                （２）公の秩序または善良の風俗を害するおそれのある行為。
                <br />
                （３）当社または第三者の著作権、商標権、特許権等の知的財産権、名誉権、プライバシー権、その他法令上または契約上の権利を侵害する行為。
                <br />
                （４）過度に暴力的な表現、露骨な性的表現、人種、国籍、信条、性別、社会的身分、門地等による差別につながる表現、自殺、自傷行為、薬物乱用を誘引または助長する表現、その他反社会的な内容を含み他人に不快感を与える表現を、投稿または送信する行為。
                <br />
                （５）当社または第三者になりすます行為または意図的に虚偽の情報を流布させる行為。
                <br />
                （６）営業、宣伝、広告、勧誘、その他営利を目的とする行為（当社の認めたものを除きます。）、性行為やわいせつな行為を目的とする行為、面識のない異性との出会いや交際を目的とする行為、他のお客様に対する嫌がらせや誹謗中傷を目的とする行為、その他本サービスが予定している利用目的と異なる目的で本サービスを利用する行為。
                <br />
                （７）反社会的勢力に対する利益供与その他の協力行為。
                <br />
                （８）宗教活動または宗教団体への勧誘行為。
                <br />
                （９）他人の個人情報、登録情報、利用履歴情報などを、不正に収集、開示または提供する行為。
                <br />
                （１０）本サービスのサーバーやネットワークシステムに支障を与える行為、BOT、チートツール、その他の技術的手段を利用して本サービスを不正に操作する行為、本サービスの不具合を意図的に利用する行為、同様の質問を必要以上に繰り返す等、当社に対し不当な問い合わせまたは要求をする行為、当社および本サービスシステムへの不正侵入、変更、改変、移転、クラッキング、リバースエンジニアリング、逆コンパイル、逆アセンブル、解析その他当社による本サービスの運営または他のお客様による本サービスの利用を妨害し、これらに支障を与える行為。ただし、OSS部分には、複製、変更、改造、移転を含むOSSライセンスに基づく権利には適用しない。
                <br />
                （１１）コンピュータウイルス等本サービスのシステムに悪影響を与えるアプリケーションおよびソフトウェア等のアップロード行為、またはこれに類似する行為
                <br />
                （１２）当社の事前の承諾なく、当社または当社関連会社が提供するサービスの検証を目的とする行為
                <br />
                （１３）本サービスのシステムと同様または類似のシステムを作成することを目的とした行為
                <br />
                （１４）当社または本サービスの信用を毀損またはそのおそれのある行為
                <br />
                （１５）上記各号のいずれかに該当する行為を援助または助長する行為。
                <br />
                （１６）その他、当社が不適当と判断した行為。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第10条（本サービスの提供の停止等）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                当社は、以下のいずれかの事由があると判断した場合、ユーザーに事前に通知することなく本サービスの全部または一部の提供を停止または中断することができるものとします。
                <br />
                （１）本サービスにかかるコンピュータシステムの保守点検または更新を定期的にまたは緊急に行う場合
                <br />
                （２）地震、落雷、火災、停電または天災などの不可抗力により、本サービスの提供が困難となった場合
                <br />
                （３）コンピュータまたは通信回線等が事故により停止した場合
                <br />
                （４）その他、当社が本サービスの提供が困難と判断した場合
                <br />
                当社は、本サービスの提供の停止または中断により、ユーザーまたは第三者が被ったいかなる不利益または損害について、理由を問わず一切の責任を負わないものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第11条（利用制限および登録抹消）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                当社は、以下の場合には、事前の通知なく、ユーザーに対して、本サービスの全部もしくは一部の利用を制限し、またはユーザーとしての登録を抹消することができるものとします。
                <br />
                （１）本規約のいずれかの条項に違反した場合
                <br />
                （２）登録事項に虚偽の事実があることが判明した場合
                <br />
                （３）その他、当社が本サービスの利用を適当でないと判断した場合
                <br />
                当社は、本条に基づき当社が行った行為によりユーザーに生じた損害について、一切の責任を負いません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第12条（保証の否認）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                （１）当社は、本サービス及び本製品がユーザーの特定の目的に適合すること、情報の安全性、期待する機能・商品価値・正確性・有用性を有すること、ならびにユーザーによる本サービス及び本製品の利用がユーザーに適用のある法令又は業界団体の内部規則等に適合すること及び不具合が生じないことについて、何ら保証するものではありません。
                <br />
                （２）当社は、本サービスの内容、本サービスを通じて提供される各種サービスおよびコンテンツの実行結果、提供情報、その他利用者が本サービスを通じて得る情報について、その正確性、完全性、確実性、有用性等、さらにユーザーによる本サービス利用の効果等については一切保証しません。
                <br />
                （３）ユーザーが、本サービスによって提供されるサービスの利用に関して他のユーザーや第三者（利用者を含みますが
                これに限られません。以下本条において同じです。）に対して損害を与えた場合、ユーザーは自己の費用負担と責任において
                当該損害を賠償するものとし、当社は一切の責任を負わないものとします。
                <br />
                （４）前項の損害につき何らかの理由により当社が責任を負う場合であっても、当社は、付随的損害、間接損害、特別損害、将来の損害及び逸失利益にかかる損害については賠償する責任を負わず、かつ、ユーザーが利用する本製品の価格相当額を限度額としてのみ損害賠償責任を負うものとします。
                <br />
                （５）本サービス又は当社ウェブサイトに関連してユーザーと他のユーザー、ISP等又は第三者との間において生じた取引、連絡、紛争等については、当社は一切責任を負いません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第13条（免責事項）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                当社の債務不履行責任は、当社の故意または重過失によらない場合には免責されるものとします。
                <br />
                当社は、何らかの理由によって責任を負う場合にも、通常生じうる損害の範囲内かつ有料サービスにおいては代金額（継続的サービスの場合には1か月分相当額）の範囲内においてのみ賠償の責任を負うものとします。
                <br />
                当社は、本サービスに関して、ユーザーと他のユーザーまたは第三者との間において生じた取引、連絡または紛争等について一切責任を負いません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第14条（サービス内容の変更等）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                当社は、ユーザーに通知することなく、本サービスの内容を変更しまたは本サービスの提供を中止することができるものとし、これによってユーザーに生じた損害について一切の責任を負いません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第15条（利用規約の変更）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                当社は、必要と判断した場合には、ユーザーに通知することなくいつでも本規約を変更することができるものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第16条（通知または連絡）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                ユーザーと当社との間の通知または連絡は、当社の定める方法によって行うものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第17条（権利義務の譲渡の禁止）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                ユーザーは、当社の書面による事前の承諾なく、利用契約上の地位または本規約に基づく権利もしくは義務を第三者に譲渡し、または担保に供することはできません。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第18条（分離可能性）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                本規約のいずれかの条項又はその一部が、消費者契約法その他の法令等により無効又は執行不能と判断された場合であっても、本規約の残りの規定又は一部が無効もしくは執行不能と判断された規定の残りの部分は、継続して完全に効力を有し、当社及びユーザーは、
                当該無効もしくは執行不能の条項又は部分を適法とし、執行力を持たせるために必要な範囲で修正し、当該無効もしくは執行不能な条項又は部分の趣旨ならびに法律的及び経済的に同等の効果を確保できるように努めるものとします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            primary="第19条（準拠法・裁判管轄）"
            primaryTypographyProps={{
              component: 'p',
              gutterBottom: true,
            }}
            secondary={
              <Typography variant="body2">
                本規約の解釈にあたっては、日本法を準拠法とします。
                <br />
                本サービスに関して紛争が生じた場合には、当社の本店所在地を管轄する裁判所を専属的合意管轄とします。
              </Typography>
            }
          />
        </ListItem>
        <ListItem disableGutters>
          <ListItemText
            secondary={
              <Typography variant="body2">最終更新日：2020年9月17日</Typography>
            }
          />
        </ListItem>
      </List>
    </GenericTemplate>
  );
};

export default TermsPage;
