import React from 'react';
import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

interface NoticeListItemProps {
  month: string;
  day: string;
  title: string;
  body: string;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    noticeList: {
      backgroundColor: 'initial',
      boxShadow: 'none',
      borderBottom: 'solid 1px #d2d2d2',
      width: '100%',
      '& .MuiAccordionSummary-content.Mui-expanded': {
        margin: '12px 0',
      },
      '& .MuiAccordionSummary-root': {
        padding: '0',
      },
      '& .MuiAccordionDetails-root': {
        paddingBottom: '1.2rem',
        paddingLeft: '0',
        paddingRight: '0',
        paddingTop: '0',
      },
      '& .MuiAccordionSummary-root.Mui-expanded': {
        minHeight: '0',
      },
    },
    noticeDate: {
      color: theme.palette.primary.main,
      fontSize: '0.9rem',
      paddingRight: '0.6rem',
    },
    noticeTitle: {
      fontSize: '0.9rem',
    },
    noticeBody: {
      color: theme.palette.grey[700],
      fontSize: '0.9rem',
    },
  }),
);

const NoticeListItem: React.FC<NoticeListItemProps> = ({
  month,
  day,
  title,
  body,
}) => {
  const classes = useStyles();

  return (
    <Accordion className={classes.noticeList}>
      <AccordionSummary expandIcon={<ExpandMoreIcon />}>
        <Typography component="div" className={classes.noticeDate}>
          {month}/{day}
        </Typography>
        <Typography component="div" className={classes.noticeTitle}>
          {title}
        </Typography>
      </AccordionSummary>
      <AccordionDetails>
        <Typography component="div" className={classes.noticeBody}>
          <div dangerouslySetInnerHTML={{ __html: body }} />
        </Typography>
      </AccordionDetails>
    </Accordion>
  );
};

export default NoticeListItem;
