import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import Typography from '@material-ui/core/Typography';

interface CancelReservationDialogProps {
  open: boolean;
  onClose: () => void;
  onCancelReservation: () => void;
}

const CancelReservationDialog: React.FC<CancelReservationDialogProps> = ({
  open,
  onClose,
  onCancelReservation,
}) => {
  return (
    <Dialog
      onClose={onClose}
      fullWidth
      aria-labelledby="dialog-title"
      open={open}
    >
      <DialogTitle disableTypography id="dialog-title">
        <Typography variant="h6" color="primary">
          配達予約の取り消し
        </Typography>
      </DialogTitle>
      <DialogContent>
        <List
          disablePadding
          subheader={
            <ListSubheader disableGutters disableSticky>
              <Typography variant="body2" color="secondary" gutterBottom>
                配達予約を取り消します。
                <br />
                取り消しを行うと、以下を元の設定に戻します。
              </Typography>
            </ListSubheader>
          }
        >
          <ListItem dense disableGutters>
            ・宅配業者の設定番号【配達用】
          </ListItem>
          <ListItem dense disableGutters>
            ・ボックスに荷物が入っていない場合の解錠方式
          </ListItem>
          <ListItem dense disableGutters>
            ・ボックスに荷物が入っている場合の解錠方式
          </ListItem>
        </List>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="contained" color="default" autoFocus>
          キャンセル
        </Button>
        <Button
          onClick={onCancelReservation}
          variant="contained"
          color="secondary"
        >
          取り消す
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelReservationDialog;
