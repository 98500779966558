import { useState, useCallback } from 'react';
import Axios, { AxiosResponse } from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

/**
 * 集荷依頼の状態
 *
 * @returns postingWait: 投函待ち
 * @returns requested: 申込済み
 * @returns requestCanceled: 申込キャンセル
 * @returns approved: 受付済み
 * @returns approveRejected: 受付拒否
 * @returns pickedup: 集荷済み
 * @returns delivering: 配達中
 * @returns delivered: 配達済み
 */
export type PickupRequestStatus =
  | 'postingWait'
  | 'requested'
  | 'requestCanceled'
  | 'approved'
  | 'approveRejected'
  | 'pickedup'
  | 'delivering'
  | 'delivered';

export interface PickupRequest {
  userId: string;
  pickupRequestId: string;
  fullName: string;
  postalCode: string;
  state: string;
  address1: string;
  address2: string;
  building: string;
  phoneNumber: string;
  preferredDate: string;
  preferredTimeZone: string;
  memo: string;
  status: PickupRequestStatus;
  cancelAt: number;
  updateAt: number;
  createAt: number;
}

export const useListPickupRequest = (): {
  getData: (limit?: number) => Promise<void>;
  data: PickupRequest[];
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<PickupRequest[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const getData = useCallback(async (limit?: number) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const idToken = AuthService.getIdToken();

      const response: AxiosResponse<{ pickupRequests: PickupRequest[] }> =
        await Axios.get(`${REACT_APP_API_ENDPOINT}/pickup-requests`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
          params: {
            limit,
          },
        });

      setData(response.data.pickupRequests);
      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { getData, data, isCompleted, isLoading, isError };
};
