import { DateTime } from 'luxon';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import {
  DeliveryboxShareStatus,
  useListDeliveryBoxShare,
} from '../../../hooks/useListDeliveryBoxShare';
import { useListDevice } from '../../../hooks/useListDevice';
import PageTitleBar from '../../atoms/PageTitleBar';
// import FormSelectField from '../../molecules/FormSelectField';
import MessageBox from '../../molecules/MessageBox';
import DeliveryBoxShareServiceHeader from '../../organisms/DeliveryBoxShareServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiButtonBase-root': {
        padding: 'unset',
      },
      '& .MuiListItem-root': {
        paddingBottom: theme.spacing(1),
      },
    },
    link: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      color: 'inherit',
      textDecoration: 'none',
      padding: theme.spacing(0, 0.5),
    },
    status: {
      width: '28%',
      alignSelf: 'stretch',
      fontWeight: 'bold',
      borderRight: `2px solid ${theme.palette.primary.main}`,
      marginRight: '5%',
      paddingRight: '2%',
    },
  }),
);

const getStatusText = (status: DeliveryboxShareStatus) => {
  if (status === 'canceled') return 'キャンセル';
  if (status === 'delivered') return '完了';
  if (status === 'collected') return '完了';
  if (status === 'expireCanceled') return '有効期限切れ';
  if (status === 'reserved') return '予約中';

  return '不明';
};

const DeliveryBoxShareDetailPage: React.FC = () => {
  const classes = useStyles();

  const listDevice = useListDevice();

  useEffect(() => {
    if (!listDevice.isCompleted && !listDevice.isLoading) {
      listDevice.getData();
    }
  }, [listDevice]);

  const listDeliveryBoxShare = useListDeliveryBoxShare();

  useEffect(() => {
    if (
      listDevice.data.length > 0 &&
      !listDeliveryBoxShare.isCompleted &&
      !listDeliveryBoxShare.isError &&
      !listDeliveryBoxShare.isLoading
    ) {
      listDeliveryBoxShare.getData();
    }
  }, [listDeliveryBoxShare, listDevice]);

  // const [deviceId, setDeviceId] = useState<string>('');

  // const handleSelectDevice = useCallback(
  //   (event: React.FocusEvent<HTMLSelectElement>) => {
  //     setDeviceId(event.target.value);

  //     if (event.target.value !== '') {
  //       listDeliveryBoxShare.getData(event.target.value);
  //     } else {
  //       listDeliveryBoxShare.getData();
  //     }
  //   },
  //   [listDeliveryBoxShare],
  // );

  return (
    <GenericTemplate
      loading={listDevice.isLoading || listDeliveryBoxShare.isLoading}
    >
      <DeliveryBoxShareServiceHeader />
      <PageTitleBar>ご利用状況の一覧</PageTitleBar>
      {listDeliveryBoxShare.isError && (
        <MessageBox color="secondary">
          あずけるボックスサービスのご利用状況が取得出来ませんでした。
        </MessageBox>
      )}
      {listDeliveryBoxShare.isCompleted && (
        <>
          {/* <Box>
            <FormSelectField
              defaultValue={deviceId}
              value={deviceId}
              error={false}
              label="宅配ボックス"
              name="deviceId"
              // onBlur={formik.handleBlur}
              onChange={handleSelectDevice}
              options={[
                {
                  label: '全ての宅配ボックス',
                  value: '',
                },
              ].concat(
                listDevice.data.map((deliveryBox) => ({
                  label: deliveryBox.nickName
                    ? deliveryBox.nickName
                    : 'マイ宅配ボックス',
                  value: deliveryBox.deviceId,
                })),
              )}
            />
          </Box> */}
          {listDeliveryBoxShare.data.length === 0 && (
            <MuiAlert severity="info">利用履歴はありません</MuiAlert>
          )}
          <List className={classes.list}>
            {listDeliveryBoxShare.data.map((deliveryBoxShare, index) => (
              <ListItem button key={index}>
                <Link
                  to={`/services/delivery-box-shares/${deliveryBoxShare.deliveryBoxShareId}/view`}
                  className={classes.link}
                >
                  <Typography color="primary" className={classes.status}>
                    {getStatusText(deliveryBoxShare.status)}
                  </Typography>
                  <Box>
                    <Typography className={classes.detail}>
                      {deliveryBoxShare.destination}
                    </Typography>
                    <Typography className={classes.detail}>
                      {listDevice.data.find(
                        (device) =>
                          device.deviceId === deliveryBoxShare.deviceId,
                      )?.nickName || '不明'}
                    </Typography>
                    <Typography className={classes.detail}>
                      {DateTime.fromFormat(
                        deliveryBoxShare.createDate,
                        'yyyy-MM-dd',
                      )
                        .toLocal()
                        .toFormat('yyyy/MM/dd')}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxShareDetailPage;
