import * as React from 'react';
import Snackbar from '@material-ui/core/Snackbar';
import { makeStyles, StyleRules } from '@material-ui/core/styles';
import WarningIcon from '@material-ui/icons/Warning';
import MuiAlert, { AlertProps } from '@material-ui/lab/Alert';

const useStyles = makeStyles(
  (): StyleRules => ({
    alert: {
      alignItems: 'center',
    },
  }),
);

const Alert = ({ severity, iconMapping, onClose, children }: AlertProps) => {
  const classes = useStyles();

  return (
    <MuiAlert
      variant="standard"
      severity={severity}
      color={severity}
      iconMapping={iconMapping}
      onClose={onClose}
      className={classes.alert}
    >
      {children}
    </MuiAlert>
  );
};

interface NotificationBarProps {
  isOpen?: boolean;
  severity?: 'success' | 'warning' | 'info' | 'error';
  onExited?: () => void;
  children?: React.ReactNode;
}

const NotificationBar: React.FC<NotificationBarProps> = ({
  isOpen,
  severity,
  onExited,
  children,
}) => {
  const [open, setOpen] = React.useState(isOpen || false);
  const handleClose = (event?: React.SyntheticEvent, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };

  return (
    <Snackbar open={open} onClose={handleClose} onExited={onExited}>
      <Alert
        severity={severity}
        iconMapping={{ error: <WarningIcon /> }}
        onClose={handleClose}
      >
        {children}
      </Alert>
    </Snackbar>
  );
};

export default NotificationBar;
