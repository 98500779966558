import { useFormik } from 'formik';
import React, { useEffect } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { useGetDevice } from '../../../hooks/useGetDevice';
import { useUpdateDevice } from '../../../hooks/useUpdateDevice';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import FormRadioGroup from '../../molecules/FormRadioGroup';
import FormTextField from '../../molecules/FormTextField';
import MessageBox from '../../molecules/MessageBox';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    button: {
      fontWeight: 'bold',
      marginTop: theme.spacing(1),
    },
  }),
);

const validPasswordLength = (password?: number) =>
  password !== undefined && String(password).length <= 6;
const validPasswordRange = (password?: number) =>
  password !== undefined && /^[1-4]*?$/.test(String(password));

const validationSchema = Yup.object().shape({
  unlockSettings: Yup.object().shape({
    yourPassword: Yup.number()
      .typeError('数字で入力して下さい')
      .required('必須項目です')
      .test('maxLength', '6桁以内で入力して下さい', (yourPassword) =>
        validPasswordLength(yourPassword),
      )
      .test(
        'numberRanges',
        '1-4の数字の組み合わせで入力して下さい',
        (yourPassword) => validPasswordRange(yourPassword),
      ),
    deliveryPassword: Yup.number()
      .typeError('数字で入力して下さい')
      .required('必須項目です')
      .test('maxLength', '6桁以内で入力して下さい', (deliveryPassword) =>
        validPasswordLength(deliveryPassword),
      )
      .test(
        'numberRanges',
        '1-4の数字の組み合わせで入力して下さい',
        (deliveryPassword) => validPasswordRange(deliveryPassword),
      ),
    collectionPassword: Yup.number()
      .typeError('数字で入力して下さい')
      .required('必須項目です')
      .test('maxLength', '6桁以内で入力して下さい', (collectionPassword) =>
        validPasswordLength(collectionPassword),
      )
      .test(
        'numberRanges',
        '1-4の数字の組み合わせで入力して下さい',
        (collectionPassword) => validPasswordRange(collectionPassword),
      ),
  }),
});

const unlockMethodItemsConstans = {
  free: {
    description: '「宅配業者の設定番号(配達用)」の入力をせずに解錠',
    label: '設定番号なし',
    value: 'free',
  },
  number: {
    description: '「宅配業者の設定番号(配達用)」の入力をして解錠',
    label: '設定番号あり',
    value: 'number',
  },
  smartphone: {
    description: 'スマートフォンで対応してから解錠',
    label: 'スマホで解錠',
    value: 'smartphone',
  },
  none: {
    description: '2回目以降の荷物は、配達されても解錠しない',
    label: '解錠しない',
    value: 'none',
  },
};

const DeliveryBoxEditPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { deviceId } = useParams<{ deviceId: string }>();

  const deliveryBox = useGetDevice();

  useEffect(() => {
    (async () => {
      if (!deliveryBox.isCompleted && !deliveryBox.isLoading) {
        deliveryBox.getData(deviceId);
      }
    })();
  }, [deliveryBox, deviceId]);

  const updateDevice = useUpdateDevice();

  const formik = useFormik({
    initialValues: {
      deviceId: deliveryBox.data && deliveryBox.data.deviceId,
      unlockSettings: {
        yourPassword:
          deliveryBox.data && Number(deliveryBox.data.yourPassword!),
        deliveryPassword:
          deliveryBox.data && Number(deliveryBox.data.deliveryPassword!),
        collectionPassword:
          deliveryBox.data && Number(deliveryBox.data.collectionPassword!),
        unlockMethodFirst:
          deliveryBox.data && deliveryBox.data.unlockMethodFirst!,
        unlockMethodSecondOnward:
          deliveryBox.data && deliveryBox.data.unlockMethodSecondOnward!,
      },
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      values.unlockSettings.yourPassword = Number(
        values.unlockSettings.yourPassword,
      );
      values.unlockSettings.deliveryPassword = Number(
        values.unlockSettings.deliveryPassword,
      );
      values.unlockSettings.collectionPassword = Number(
        values.unlockSettings.collectionPassword,
      );
      updateDevice.handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (updateDevice.isCompleted) {
      history.push(`/devices/delivery-boxes/${deviceId}`);
    }
  }, [updateDevice.isCompleted, history, deviceId]);

  return (
    <GenericTemplate
      title={`${
        deliveryBox.data.nickName ? deliveryBox.data.nickName : '宅配ボックス'
      }の解錠設定`}
    >
      {deliveryBox.isLoading || updateDevice.isLoading ? (
        <LoadingIndicator />
      ) : (
        <>
          {deliveryBox.isError && (
            <MuiAlert severity="error" icon={false}>
              宅配ボックス解錠設定の読み込みに失敗しました。
            </MuiAlert>
          )}
          {deliveryBox.isCompleted && (
            <form onSubmit={formik.handleSubmit}>
              <MessageBox color="initial">
                配達時、スマート宅配ポストを解錠するときの番号の入力の有無を設定してください。
                <Typography variant="subtitle2" color="secondary">
                  ※すべて必須項目です。
                </Typography>
              </MessageBox>
              {deliveryBox.data.service && (
                <Box mt={2}>
                  <MuiAlert severity="error">
                    サービス利用中のため、解錠設定は行えません。
                  </MuiAlert>
                </Box>
              )}
              <Box mt={2} mb={1}>
                <FormTextField
                  defaultValue={String(
                    formik.values.unlockSettings.yourPassword,
                  )}
                  error={
                    !!formik.errors.unlockSettings?.yourPassword &&
                    !!formik.touched.unlockSettings?.yourPassword
                  }
                  helperText={formik.errors.unlockSettings?.yourPassword}
                  label="あなたの設定番号（数字の1〜4を使用して6桁以内）"
                  name="unlockSettings.yourPassword"
                  description="集荷の荷物を入れるとき、配達された荷物を取り出すときに宅配ポストを解錠する番号"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="未設定"
                  type="tel"
                />
                <FormTextField
                  defaultValue={String(
                    formik.values.unlockSettings.deliveryPassword,
                  )}
                  error={
                    !!formik.errors.unlockSettings?.deliveryPassword &&
                    !!formik.touched.unlockSettings?.deliveryPassword
                  }
                  helperText={formik.errors.unlockSettings?.deliveryPassword}
                  label="宅配業者の設定番号【配達用】（数字の1〜4を使用して6桁以内）"
                  name="unlockSettings.deliveryPassword"
                  description="宅配時（宅配業者が荷物を入れるとき）、宅配ポストを解錠する番号"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="未設定"
                  type="tel"
                />
                <FormTextField
                  defaultValue={String(
                    formik.values.unlockSettings.collectionPassword,
                  )}
                  error={
                    !!formik.errors.unlockSettings?.collectionPassword &&
                    !!formik.touched.unlockSettings?.collectionPassword
                  }
                  helperText={formik.errors.unlockSettings?.collectionPassword}
                  label="宅配業者の設定番号【集荷用】（数字の1〜4を使用して6桁以内）"
                  name="unlockSettings.collectionPassword"
                  description="宅配時（宅配業者が荷物を出すとき）、宅配ポストを解錠する番号"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="未設定"
                  type="tel"
                />
                <FormRadioGroup
                  defaultValue={
                    formik.initialValues.unlockSettings.unlockMethodFirst
                  }
                  items={
                    formik.initialValues.unlockSettings.unlockMethodFirst ===
                      'smartphone' ||
                    formik.initialValues.unlockSettings
                      .unlockMethodSecondOnward === 'smartphone'
                      ? [
                          unlockMethodItemsConstans.free,
                          unlockMethodItemsConstans.number,
                          unlockMethodItemsConstans.smartphone,
                        ]
                      : [
                          unlockMethodItemsConstans.free,
                          unlockMethodItemsConstans.number,
                        ]
                  }
                  label="ボックスに荷物が入っていない場合"
                  name="unlockSettings.unlockMethodFirst"
                  onChange={formik.handleChange}
                  fullWidth
                />
                <FormRadioGroup
                  defaultValue={
                    formik.initialValues.unlockSettings.unlockMethodSecondOnward
                  }
                  label="ボックスに荷物が入っている場合"
                  items={
                    formik.initialValues.unlockSettings.unlockMethodFirst ===
                      'smartphone' ||
                    formik.initialValues.unlockSettings
                      .unlockMethodSecondOnward === 'smartphone'
                      ? [
                          unlockMethodItemsConstans.number,
                          unlockMethodItemsConstans.none,
                          unlockMethodItemsConstans.smartphone,
                        ]
                      : [
                          unlockMethodItemsConstans.number,
                          unlockMethodItemsConstans.none,
                        ]
                  }
                  name="unlockSettings.unlockMethodSecondOnward"
                  onChange={formik.handleChange}
                  fullWidth
                />
              </Box>
              <Button
                className={classes.button}
                color="primary"
                fullWidth
                type="submit"
                variant="contained"
              >
                設定する
              </Button>
            </form>
          )}
          <Button
            className={classes.button}
            fullWidth
            variant="contained"
            onClick={() => {
              history.goBack();
            }}
          >
            戻る
          </Button>
          {updateDevice.isError && (
            <Box mt={1}>
              <MuiAlert severity="error" icon={false}>
                更新に失敗しました。宅配ボックスが「サービスで利用中」または「設定中」の場合、更新できません。
                <br />
                サービス完了後、設定完了後に再度設定してください。
              </MuiAlert>
            </Box>
          )}
        </>
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxEditPage;
