import React from 'react';

import { useListNotice } from '../../hooks/useListNotice';
import NoticeList from '../organisms/NoticeList';
import GenericTemplate from '../templates/GenericTemplate';

const NoticePage: React.FC = () => {
  const listNotice = useListNotice();

  return (
    <GenericTemplate title="お知らせ">
      <NoticeList
        data={listNotice.data}
        isLoading={listNotice.isLoading}
        isError={listNotice.isError}
      />
    </GenericTemplate>
  );
};

export default NoticePage;
