import Axios, { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface DeliveryBoxSharePassword {
  password: number;
  expireAt: number;
  passwordSettingStatus: 'done' | 'in_setting' | 'wait_pickup_mode';
  type?: 'invalid_request' | 'expired' | 'canceled' | 'collected' | 'delivered';
}

export const useGetDeliveryBoxSharePassword = (): {
  getData: (deliveryBoxShareId: string) => Promise<void>;
  data: DeliveryBoxSharePassword;
  isCompleted: boolean;
  isFirstLoaded: boolean;
  isLoading: boolean;
  isError: boolean;
  errorMessage: string;
} => {
  const [data, setData] = useState<DeliveryBoxSharePassword>({
    password: 0,
    expireAt: 0,
    passwordSettingStatus: 'in_setting',
  });
  const [isCompleted, setIsCompleted] = useState(false);
  const [isFirstLoaded, setIsFirstLoaded] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const getData = useCallback(async (deliveryBoxShareId: string) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const response: AxiosResponse<DeliveryBoxSharePassword> = await Axios.get(
        `${REACT_APP_API_ENDPOINT}/shares/${deliveryBoxShareId}/password`,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      if (response.data.type && response.data.type === 'expired') {
        setErrorMessage('URLの有効期限が切れています。');
        setIsError(true);
      } else if (response.data.type && response.data.type === 'canceled') {
        setErrorMessage('シェアがキャンセルされています。');
        setIsError(true);
      } else if (response.data.type && response.data.type === 'collected') {
        setErrorMessage('既にシェアは終了しています。');
        setIsError(true);
      } else if (response.data.type && response.data.type === 'delivered') {
        setErrorMessage('既にシェアは終了しています。');
        setIsError(true);
      } else {
        setData(response.data);
        setIsCompleted(true);
      }
    } catch (e) {
      if (e.response && e.response.status === 400) {
        if (e.response.data.type === 'invalid_request') {
          setErrorMessage('不正なリクエストです。');
        } else {
          setErrorMessage('原因不明のエラーが発生しました。');
        }
      } else {
        setErrorMessage('原因不明のエラーが発生しました。');
      }

      setIsError(true);
    }

    setIsFirstLoaded(true);
    setIsLoading(false);
  }, []);

  return {
    getData,
    data,
    isCompleted,
    isFirstLoaded,
    isLoading,
    isError,
    errorMessage,
  };
};
