import React, { useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { useGetUser } from '../../../hooks/useGetUser';
import { useListDevice } from '../../../hooks/useListDevice';
import ServiceList from '../../organisms/ServiceList';
import GenericTemplate from '../../templates/GenericTemplate';

const ServicePage: React.FC = () => {
  const listDeliveryBoxes = useListDevice();

  useEffect(() => {
    if (
      !listDeliveryBoxes.isCompleted &&
      !listDeliveryBoxes.isError &&
      !listDeliveryBoxes.isLoading
    ) {
      listDeliveryBoxes.getData();
    }
  }, [listDeliveryBoxes]);

  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  return (
    <GenericTemplate loading={listDeliveryBoxes.isLoading || getUser.isLoading}>
      {listDeliveryBoxes.isCompleted && getUser.isCompleted ? (
        <ServiceList devices={listDeliveryBoxes.data} />
      ) : (
        listDeliveryBoxes.isError ||
        (getUser.isError && (
          <MuiAlert severity="error">
            サービス一覧の読み込みに失敗しました。
          </MuiAlert>
        ))
      )}
    </GenericTemplate>
  );
};

export default ServicePage;
