import { useState } from 'react';
import Axios from 'axios';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

interface FileNameMappings {
  fileName: string;
  uploadFileName: string;
}

export interface Contact {
  name: string;
  email: string;
  subject: string;
  text: string;
  isFileUploaded: boolean;
  fileNameMappings: FileNameMappings[];
}

export const useContact = (): {
  handleSubmit: (values: Contact) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (values: Contact) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        await Axios.post(
          `${REACT_APP_API_ENDPOINT}/contacts`,
          {
            name: values.name,
            email: values.email,
            subject: values.subject,
            text: values.text,
            isFileUploaded: values.isFileUploaded,
            fileNameMappings: values.fileNameMappings,
          },
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { handleSubmit, isCompleted, isLoading, isError };
};
