import { useState, useCallback } from 'react';
import Axios, { AxiosResponse } from 'axios';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export interface ContactUploadUrls {
  uploadUrl: string;
  uploadFileName: string;
}

export const useCreateContactUploadUrls = (): {
  data?: ContactUploadUrls;
  // eslint-disable-next-line
  handleSubmit: (file: File) => Promise<any>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<ContactUploadUrls>();
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async (file: File) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      const response: AxiosResponse<ContactUploadUrls> = await Axios.post(
        `${REACT_APP_API_ENDPOINT}/contact-upload-urls`,
        {
          fileName: file.name,
        },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      );

      setData(response.data);
      setIsCompleted(true);
      setIsLoading(false);

      return response.data;
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { data, handleSubmit, isCompleted, isLoading, isError };
};
