import { DateTime } from 'luxon';
import React, { useCallback, useEffect, useState } from 'react';
import CopyToClipBoard from 'react-copy-to-clipboard';
import { useHistory, useParams } from 'react-router-dom';
import { EmailShareButton, LineIcon, LineShareButton } from 'react-share';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import grey from '@material-ui/core/colors/grey';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import IconButton from '@material-ui/core/IconButton';
import InputAdornment from '@material-ui/core/InputAdornment';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import OutlinedInput from '@material-ui/core/OutlinedInput';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Tooltip from '@material-ui/core/Tooltip';
import Typography from '@material-ui/core/Typography';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import MailOutlineIcon from '@material-ui/icons/MailOutline';
import MuiAlert from '@material-ui/lab/Alert';

import { useCancelDeliveryBoxShare } from '../../../hooks/useCancelDeliveryBoxShare';
import { useDialog } from '../../../hooks/useDialog';
import { useGetDevice } from '../../../hooks/useGetDevice';
import { useGetDeliveryBoxShare } from '../../../hooks/useGetDeliveryBoxShare';
import DeliveryBoxShareServiceHeader from '../../organisms/DeliveryBoxShareServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    info: {
      border: `2px solid ${theme.palette.primary.main}`,
      background: '#f7fafb',
      padding: theme.spacing(2),
      marginBottom: theme.spacing(6),
    },
    infoText: {
      color: '#666666',
      fontWeight: 'bold',
      fontSize: theme.typography.subtitle1.fontSize,
    },
    infoDesc: {
      '& dt': {
        width: 'auto',
        float: 'left',
        color: theme.palette.primary.main,
        fontWeight: 'bold',
      },
      '& dd': {
        display: 'block',
        width: 'auto',
        margin: '0 0 0 10rem',
      },
    },
    customPopper: {
      zIndex: 'auto',
    },
    customTooltip: {
      color: '#fff',
      fontWeight: 'bold',
      lineHeight: 'inherit',
      fontSize: theme.typography.caption.fontSize,
      background: theme.palette.primary.main,
      padding: theme.spacing(0.5, 1),
      margin: theme.spacing(1.5, 0),
    },
    customArrow: {
      color: theme.palette.primary.main,
    },
    shareButtonWrapper: {
      display: 'flex',
      margin: '1rem auto',
    },
    shareButton: {
      flex: 1,
      border: `2px solid ${grey[400]}!important`,
      borderRadius: '5px',
      boxSizing: 'border-box',
      padding: `${theme.spacing(1)}!important`,
      margin: '0 1%',
    },
    shareButtonText: {
      fontWeight: 'bold',
      margin: 0,
      [theme.breakpoints.down('sm')]: {
        fontSize: '10px',
      },
    },
    cancelButton: {
      marginTop: theme.spacing(1),
      fontWeight: 'bold',
      display: 'block',
      marginLeft: 'auto',
    },
  }),
);

const DeliveryBoxShareStatusText: React.FC<{ status: string }> = ({
  status,
}) => {
  if (status === 'canceled') {
    return <>キャンセル済み</>;
  } else if (status === 'delivered') {
    return <>完了</>;
  } else if (status === 'collected') {
    return <>完了</>;
  } else if (status === 'reserved') {
    return <>予約中</>;
  } else if (status === 'expireCanceled') {
    return <>有効期限切れ</>;
  }

  return <>不明</>;
};

const DeliveryBoxShareDetailPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const { deliveryBoxShareId } = useParams<{ deliveryBoxShareId: string }>();

  const getDeliveryBoxShare = useGetDeliveryBoxShare();
  const cancelDeliveryBoxShare = useCancelDeliveryBoxShare();
  const getDevice = useGetDevice();

  const cancelDialog = useDialog();

  const [copied, setCopied] = useState(false);
  const handleCopy = useCallback(() => {
    setCopied(true);
  }, []);

  const handleCancelDeliveryBoxShare = useCallback(() => {
    cancelDeliveryBoxShare.handleSubmit(deliveryBoxShareId!);
    cancelDialog.handleClose();
  }, [cancelDeliveryBoxShare, cancelDialog, deliveryBoxShareId]);

  useEffect(() => {
    if (
      !getDeliveryBoxShare.isCompleted &&
      !getDeliveryBoxShare.isError &&
      !getDeliveryBoxShare.isLoading
    ) {
      getDeliveryBoxShare.getData(deliveryBoxShareId!);
    }
  }, [getDeliveryBoxShare, deliveryBoxShareId]);

  useEffect(() => {
    if (
      !getDevice.isCompleted &&
      !getDevice.isError &&
      !getDevice.isLoading &&
      getDeliveryBoxShare.isCompleted
    ) {
      getDevice.getData(getDeliveryBoxShare.data.deviceId);
    }
  }, [
    getDevice,
    getDeliveryBoxShare.isCompleted,
    getDeliveryBoxShare.data.deviceId,
  ]);

  useEffect(() => {
    if (cancelDeliveryBoxShare.isCompleted) {
      history.push('/services/delivery-box-shares/histories');
    }
  }, [cancelDeliveryBoxShare.isCompleted, history]);

  const shareUrl = `https://${window.location.host}/services/delivery-box-shares/${deliveryBoxShareId}/unlock`;

  return (
    <GenericTemplate
      loading={
        getDeliveryBoxShare.isLoading || cancelDeliveryBoxShare.isLoading
      }
    >
      <DeliveryBoxShareServiceHeader />
      {cancelDeliveryBoxShare.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            {cancelDeliveryBoxShare.errorMessage}
          </MuiAlert>
        </Box>
      )}
      {getDeliveryBoxShare.isError && (
        <Box mb={3}>
          <MuiAlert severity="error" icon={false}>
            あずけるボックスサービスのご利用状況が取得出来ませんでした。
          </MuiAlert>
        </Box>
      )}
      {getDeliveryBoxShare.isCompleted && (
        <>
          {getDevice.isCompleted && (
            <>
              {getDeliveryBoxShare.data.status === 'reserved' &&
                getDevice.data.service === 'deliveryBoxSharePickup' &&
                getDevice.data.status === 'empty' && (
                  <Box mb={1}>
                    <MuiAlert severity="warning">
                      「集荷待ち」状態にしてください
                    </MuiAlert>
                  </Box>
                )}
            </>
          )}
          <Box className={classes.info}>
            {getDeliveryBoxShare.data.status === 'reserved' && (
              <Typography className={classes.infoText} gutterBottom>
                シェアする相手にURLを連絡してください。
              </Typography>
            )}
            <List component="dl" disablePadding className={classes.infoDesc}>
              <ListItem disableGutters dense component="dt">
                宅配ボックス
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDevice.data.nickName ? getDevice.data.nickName : '不明'}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                状態
              </ListItem>
              <ListItem disableGutters dense component="dd">
                <DeliveryBoxShareStatusText
                  status={getDeliveryBoxShare.data.status}
                />
                {getDeliveryBoxShare.data.status !== 'reserved' && (
                  <>
                    <br />
                    {DateTime.fromMillis(
                      Number(getDeliveryBoxShare.data.updateAt),
                    ).toFormat('yyyy/MM/dd HH:mm')}
                  </>
                )}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                お申し込み日時
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {DateTime.fromMillis(
                  Number(getDeliveryBoxShare.data.createAt),
                ).toFormat('yyyy/MM/dd HH:mm')}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                お申し込み内容
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDeliveryBoxShare.data.type === 'pickup' && 'もってって'}
                {getDeliveryBoxShare.data.type === 'delivery' && 'もってきて'}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                期限
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDeliveryBoxShare.data.duration === 'today'
                  ? '当日中'
                  : getDeliveryBoxShare.data.duration === 'tomorrow'
                  ? '翌日中'
                  : '不明'}
              </ListItem>
              <ListItem disableGutters dense component="dt">
                用途・お知らせ
              </ListItem>
              <ListItem disableGutters dense component="dd">
                {getDeliveryBoxShare.data.destination}
              </ListItem>
            </List>
          </Box>
          {getDeliveryBoxShare.data.status === 'reserved' && (
            <>
              <FormControl variant="outlined" fullWidth>
                <OutlinedInput
                  style={{ background: '#f7fafb', paddingRight: '2px' }}
                  type="text"
                  value={shareUrl}
                  endAdornment={
                    <InputAdornment position="end">
                      <Tooltip
                        arrow
                        open
                        placement="top-end"
                        title={
                          copied
                            ? 'URLをコピーしました'
                            : 'URLのコピーができます'
                        }
                        PopperProps={{
                          modifiers: {
                            flip: {
                              enabled: false,
                            },
                          },
                        }}
                        classes={{
                          popper: classes.customPopper,
                          tooltip: classes.customTooltip,
                          touch: classes.customTooltip,
                          arrow: classes.customArrow,
                        }}
                      >
                        <CopyToClipBoard text={shareUrl}>
                          <IconButton onClick={handleCopy}>
                            <FileCopyIcon color="primary" />
                          </IconButton>
                        </CopyToClipBoard>
                      </Tooltip>
                    </InputAdornment>
                  }
                />
              </FormControl>
              <Box className={classes.shareButtonWrapper}>
                <EmailShareButton
                  url=""
                  subject={getDeliveryBoxShare.data.destination}
                  body={`\n\n宅配ボックスがシェアされました。\n下記のURLから宅配ボックスの解錠番号を取得し、宅配ボックスに入力してください。\nこのURLは ${DateTime.fromMillis(
                    Number(getDeliveryBoxShare.data.expireAt),
                  ).toFormat(
                    'yyyy/MM/dd HH:mm',
                  )} まで有効です。\n${shareUrl}\n\n※このメールは、LIXILあずけるボックスサービスにお申し込みいただいた方に送信しています。`}
                  className={classes.shareButton}
                  onClick={(_, link) => {
                    window.open(link, '_blank');
                  }}
                >
                  <MailOutlineIcon fontSize="large" />
                  <p className={classes.shareButtonText}>メールで送る</p>
                </EmailShareButton>
                <LineShareButton
                  url={shareUrl}
                  title={`宅配ボックスがシェアされました。\n下記のURLから宅配ボックスの解錠番号を取得し、宅配ボックスに入力してください。\nこのURLは ${DateTime.fromMillis(
                    Number(getDeliveryBoxShare.data.expireAt),
                  ).toFormat(
                    'yyyy/MM/dd HH:mm',
                  )} まで有効です。\n\n※このメールは、LIXILあずけるボックスサービスにお申し込みいただいた方に送信しています。`}
                  className={classes.shareButton}
                >
                  <LineIcon size={35} round />
                  <p className={classes.shareButtonText}>LINEで送る</p>
                </LineShareButton>
                {/* <FacebookMessengerShareButton
                      appId={FACEBOOK_APP_ID}
                      url={shareUrl}
                      className={classes.shareButton}
                    >
                      <FacebookMessengerIcon size={35} round />
                      <p className={classes.shareButtonText}>Messangerで送る</p>
                    </FacebookMessengerShareButton> */}
              </Box>
              <Button
                className={classes.cancelButton}
                onClick={cancelDialog.handleOpen}
                color="secondary"
                variant="outlined"
              >
                シェアを取り消す
              </Button>
            </>
          )}
        </>
      )}
      <Dialog open={cancelDialog.open} onClose={cancelDialog.handleClose}>
        <DialogTitle>シェアの取り消し</DialogTitle>
        <DialogContent>
          <DialogContentText>
            宅配ボックスのシェアを取り消します。
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={cancelDialog.handleClose}
            variant="contained"
            autoFocus
          >
            キャンセル
          </Button>
          <Button
            onClick={handleCancelDeliveryBoxShare}
            variant="contained"
            color="secondary"
          >
            シェアを取り消す
          </Button>
        </DialogActions>
      </Dialog>
    </GenericTemplate>
  );
};

export default DeliveryBoxShareDetailPage;
