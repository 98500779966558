import { useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormGroup from '@material-ui/core/FormGroup';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import { SubscriptionEmail, useGetUser } from '../../../hooks/useGetUser';
import { useUpdateUser } from '../../../hooks/useUpdateUser';
import LoadingIndicator from '../../atoms/LoadingIndicator';
import NotificationBar from '../../atoms/NotificationBar';
import MessageBox from '../../molecules/MessageBox';
import GenericTemplate from '../../templates/GenericTemplate';
import { useListDevice } from '../../../hooks/useListDevice';
import { useGetSagawa } from '../../../hooks/useGetSagawaService';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    bold: {
      fontWeight: 'bold',
    },
    submitButton: {
      marginTop: theme.spacing(3),
    },
    cancelButton: {
      marginTop: theme.spacing(1),
    },
  }),
);

interface FormCheckBoxProps {
  label: string;
  name: string;
  value?: boolean;
  // eslint-disable-next-line
  onChange: (event: React.ChangeEvent<any>) => void;
  checked?: boolean;
}

const FormCheckBox: React.FC<FormCheckBoxProps> = ({
  label,
  name,
  value,
  onChange,
  checked,
}) => {
  return (
    <FormControlLabel
      control={
        <Checkbox
          name={name}
          value={value}
          onChange={onChange}
          checked={checked}
          color="primary"
          size="small"
        />
      }
      label={label}
    />
  );
};

const NotificationSettingsPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const user = useGetUser();

  useEffect(() => {
    if (!user.isCompleted && !user.isLoading && !user.isError) {
      user.getData();
    }
  }, [user]);
  const updateUser = useUpdateUser();
  const listDevices = useListDevice();

  useEffect(() => {
    listDevices.getData();
  }, []);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (listDevices.isCompleted || listDevices.isError) {
      setIsLoaded(true);
    }
  }, [listDevices.isCompleted, listDevices.isError]);

  const getSagawa = useGetSagawa();

  useEffect(() => {
    if (
      listDevices.data.length > 0 &&
      !getSagawa.isCompleted &&
      !getSagawa.isError &&
      !getSagawa.isLoading
    ) {
      getSagawa.getData(listDevices.data[0].deviceId);
    }
  }, [getSagawa]);

  const [isSagawa, setIsSagwa] = useState(false);
  useEffect(() => {
    if (getSagawa.data.status == 'complete') {
      setIsSagwa(true);
    }
  }, [getSagawa]);

  const formik = useFormik<SubscriptionEmail>({
    initialValues: {
      deliverySchedule: user.data.subscriptionEmail.deliverySchedule,
      deliveryReservation: user.data.subscriptionEmail.deliveryReservation,
      deliveryReservationCancel:
        user.data.subscriptionEmail.deliveryReservationCancel,
      deliveryComplete: user.data.subscriptionEmail.deliveryComplete,
      deliveryReceive: user.data.subscriptionEmail.deliveryReceive,
      pickupRequest: user.data.subscriptionEmail.pickupRequest,
      pickupRequestCencel: user.data.subscriptionEmail.pickupRequestCencel,
      pickupAcceptance: user.data.subscriptionEmail.pickupAcceptance,
      pickupRejected: user.data.subscriptionEmail.pickupRejected,
      pickupComplete: user.data.subscriptionEmail.pickupComplete,
      hubReboot: user.data.subscriptionEmail.hubReboot,
      shareDeliveryReserve: user.data.subscriptionEmail.shareDeliveryReserve,
      shareDeliveryCancel: user.data.subscriptionEmail.shareDeliveryCancel,
      shareDeliveryComplete: user.data.subscriptionEmail.shareDeliveryComplete,
      shareDeliveryExpire: user.data.subscriptionEmail.shareDeliveryExpire,
      shareCollectReserve: user.data.subscriptionEmail.shareCollectReserve,
      shareCollectCancel: user.data.subscriptionEmail.shareCollectCancel,
      shareCollectComplete: user.data.subscriptionEmail.shareCollectComplete,
      shareCollectExpire: user.data.subscriptionEmail.shareCollectExpire,
      shareCollectNotPickupModeRemind:
        user.data.subscriptionEmail.shareCollectNotPickupModeRemind,
    },
    onSubmit: (values) => {
      updateUser.handleSubmit({ subscriptionEmail: values });
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (updateUser.isCompleted) {
      history.push('/settings');
    }
  }, [updateUser.isCompleted, history]);

  return (
    <GenericTemplate loading={!isLoaded} title="メール通知の設定">
      <Box mb={6}>
        <MessageBox color="initial">
          チェックボックスで設定されている場合、お知らせメールが送信されます。
          <br />
          不要な場合は、チェックボックスを外してください。
        </MessageBox>
        {user.isLoading || updateUser.isLoading ? (
          <Box mt={6}>
            <LoadingIndicator />
          </Box>
        ) : (
          <>
            {!user.isError && user.isCompleted && (
              <form onSubmit={formik.handleSubmit}>
                <Box mt={2}>
                  <Typography
                    component="h3"
                    variant="h6"
                    color="primary"
                    className={classes.bold}
                  >
                    あずけるボックスサービスのメール通知設定
                  </Typography>
                </Box>
                <FormGroup>
                  <FormCheckBox
                    label="サービス申し込みのお知らせ"
                    name="shareReserve"
                    value={
                      formik.values.shareDeliveryReserve &&
                      formik.values.shareCollectReserve
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        'shareDeliveryReserve',
                        event.target.checked,
                      );
                      formik.setFieldValue(
                        'shareCollectReserve',
                        event.target.checked,
                      );
                    }}
                    checked={
                      formik.values.shareDeliveryReserve &&
                      formik.values.shareCollectReserve
                    }
                  />
                  <FormCheckBox
                    label="集荷待ち設定忘れのお知らせ"
                    name="shareCollectNotPickupModeRemind"
                    value={formik.values.shareCollectNotPickupModeRemind}
                    onChange={formik.handleChange}
                    checked={formik.values.shareCollectNotPickupModeRemind}
                  />
                  <FormCheckBox
                    label="サービス取り消しのお知らせ"
                    name="shareCancel"
                    value={
                      formik.values.shareDeliveryCancel &&
                      formik.values.shareCollectCancel
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        'shareDeliveryCancel',
                        event.target.checked,
                      );
                      formik.setFieldValue(
                        'shareCollectCancel',
                        event.target.checked,
                      );
                    }}
                    checked={
                      formik.values.shareDeliveryCancel &&
                      formik.values.shareCollectCancel
                    }
                  />
                  <FormCheckBox
                    label="サービス完了のお知らせ"
                    name="shareComplete"
                    value={
                      formik.values.shareDeliveryComplete &&
                      formik.values.shareCollectComplete
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        'shareDeliveryComplete',
                        event.target.checked,
                      );
                      formik.setFieldValue(
                        'shareCollectComplete',
                        event.target.checked,
                      );
                    }}
                    checked={
                      formik.values.shareDeliveryComplete &&
                      formik.values.shareCollectComplete
                    }
                  />
                  <FormCheckBox
                    label="サービス期限切れのお知らせ"
                    name="shareExpire"
                    value={
                      formik.values.shareDeliveryExpire &&
                      formik.values.shareCollectExpire
                    }
                    onChange={(event) => {
                      formik.setFieldValue(
                        'shareDeliveryExpire',
                        event.target.checked,
                      );
                      formik.setFieldValue(
                        'shareCollectExpire',
                        event.target.checked,
                      );
                    }}
                    checked={
                      formik.values.shareDeliveryExpire &&
                      formik.values.shareCollectExpire
                    }
                  />
                </FormGroup>
                {isSagawa ? (
                  <>
                    <Box mt={2}>
                      <Typography
                        component="h3"
                        variant="h6"
                        color="primary"
                        className={classes.bold}
                      >
                        配達のメール通知設定
                      </Typography>
                    </Box>
                    <FormGroup>
                      <FormCheckBox
                        label="配達予定のお知らせ"
                        name="deliverySchedule"
                        value={formik.values.deliverySchedule}
                        onChange={formik.handleChange}
                        checked={formik.values.deliverySchedule}
                      />
                      <FormCheckBox
                        label="配達予約のお知らせ"
                        name="deliveryReservation"
                        value={formik.values.deliveryReservation}
                        onChange={formik.handleChange}
                        checked={formik.values.deliveryReservation}
                      />
                      <FormCheckBox
                        label="配達完了のお知らせ"
                        name="deliveryComplete"
                        value={formik.values.deliveryComplete}
                        onChange={formik.handleChange}
                        checked={formik.values.deliveryComplete}
                      />
                      <FormCheckBox
                        label="配達予約解除のお知らせ"
                        name="deliveryReservationCancel"
                        value={formik.values.deliveryReservationCancel}
                        onChange={formik.handleChange}
                        checked={formik.values.deliveryReservationCancel}
                      />
                      <FormCheckBox
                        label="受取完了のお知らせ"
                        name="deliveryReceive"
                        value={formik.values.deliveryReceive}
                        onChange={formik.handleChange}
                        checked={formik.values.deliveryReceive}
                      />
                    </FormGroup>
                  </>
                ) : (
                  <></>
                )}
                <Button
                  className={classes.submitButton + ' ' + classes.bold}
                  color="primary"
                  fullWidth
                  type="submit"
                  variant="contained"
                >
                  設定を保存する
                </Button>
              </form>
            )}
            <Button
              className={classes.cancelButton + ' ' + classes.bold}
              fullWidth
              variant="contained"
              component={Link}
              to="/settings"
            >
              キャンセル
            </Button>
          </>
        )}
        {updateUser.isError && (
          <NotificationBar severity="error" isOpen={updateUser.isError}>
            更新に失敗しました。
          </NotificationBar>
        )}
      </Box>
    </GenericTemplate>
  );
};

export default NotificationSettingsPage;
