import React, { useEffect } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { useListDevice } from '../../../hooks/useListDevice';
import DeviceList from '../../organisms/DeviceList';
import GenericTemplate from '../../templates/GenericTemplate';

const DevicePage: React.FC = () => {
  const listDeliveryBoxes = useListDevice();

  useEffect(() => {
    if (
      !listDeliveryBoxes.isCompleted &&
      !listDeliveryBoxes.isError &&
      !listDeliveryBoxes.isLoading
    ) {
      listDeliveryBoxes.getData();
    }
  }, [listDeliveryBoxes]);

  return (
    <GenericTemplate title="デバイス" loading={listDeliveryBoxes.isLoading}>
      {listDeliveryBoxes.isCompleted ? (
        <DeviceList devices={listDeliveryBoxes.data} />
      ) : (
        listDeliveryBoxes.isError && (
          <MuiAlert severity="error">
            デバイス一覧の読み込みに失敗しました。
          </MuiAlert>
        )
      )}
    </GenericTemplate>
  );
};

export default DevicePage;
