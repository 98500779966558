import React from 'react';
import { Link } from 'react-router-dom';
import { DateTime } from 'luxon';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import ListItem from '@material-ui/core/ListItem';

import { DeviceHistory } from '../../hooks/useGetDeviceHistory';

const RENTAL_SERVICE = process.env.REACT_APP_RENTAL_SERVICE_JANRAIN_CLIENT_ID!;
const SERVICES: { [key: string]: string } = {
  [RENTAL_SERVICE]: 'レンタルサービス',
};

interface DeviceHistoryListItemProps {
  history: DeviceHistory;
  nickName?: string;
  deviceId?: string;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    listItem: {
      padding: '14px 0',
      alignItems: 'flex-start',
    },
    createAt: {
      width: '10%',
      color: theme.palette.primary.main,
      fontSize: '0.9rem',
      marginRight: '1rem',
    },
    title: {
      flex: 1,
      fontSize: '0.9rem',
    },
    caution: {
      color: theme.palette.error.main,
    },
  }),
);

const DeviceHistoryListItem: React.FC<DeviceHistoryListItemProps> = ({
  history,
  nickName,
  deviceId,
}) => {
  const classes = useStyles();

  return (
    <ListItem disableGutters divider className={classes.listItem}>
      <Typography component="div" className={classes.createAt}>
        {DateTime.fromMillis(history.createAt)
          .toLocal()
          .toFormat('MM/dd HH:mm')}
      </Typography>
      <Typography component="div" className={classes.title}>
        {/* 発生事象 */}
        {history.type === 'delivery' && (
          <>{nickName && <>{nickName}に</>}荷物が届きました。</>
        )}
        {history.type === 'pickup' && (
          <>
            {nickName && <>{nickName}に</>}
            荷物が集荷されました。
          </>
        )}
        {history.type === 'retrieve' && (
          <>
            {nickName && <>{nickName}の</>}
            荷物が取り出されました。
          </>
        )}
        {history.type === 'openDoor' && (
          <>
            {nickName && <>{nickName}の</>}
            扉が開いています。ご確認ください。
          </>
        )}
        {history.type === 'reboot' && (
          <>
            {nickName && <>{nickName}で</>}
            ホームユニットの再起動を検知しました。
            {deviceId && (
              <>
                <br />
                <span className={classes.caution}>
                  「あなたの番号」で宅配ボックスを開閉し、
                  <Link to={`/devices/delivery-boxes/${deviceId}/edit`}>
                    再度解錠設定
                  </Link>
                  を行ってください。
                </span>
              </>
            )}
          </>
        )}
        {/* 外部API */}
        {history.type === 'pickupRequest' && (
          <>
            {SERVICES[history.oidcClientId] && (
              <>{SERVICES[history.oidcClientId]}で</>
            )}
            {nickName && <>{nickName}に</>}
            集荷申込み(
            {history.scheduledDate.replace(/-/g, '/')}
            )しました。
          </>
        )}
        {history.type === 'getUnlockNumber' && (
          <>
            {SERVICES[history.oidcClientId] && (
              <>{SERVICES[history.oidcClientId]}で</>
            )}
            {nickName && <>{nickName}の</>}
            解錠番号を取得しました。
          </>
        )}
        {history.type === 'getUnlockNumberFailed' && (
          <>
            {SERVICES[history.oidcClientId] && (
              <>{SERVICES[history.oidcClientId]}で</>
            )}
            {nickName && <>{nickName}の</>}
            解錠番号取得に失敗しました。
          </>
        )}
        {history.type === 'pickupComplete' && (
          <>
            {SERVICES[history.oidcClientId] && (
              <>{SERVICES[history.oidcClientId]}で</>
            )}
            {nickName && <>{nickName}の</>}
            荷物が集荷されました。
          </>
        )}
        {history.type === 'deliveryComplete' && (
          <>
            {SERVICES[history.oidcClientId] && (
              <>{SERVICES[history.oidcClientId]}で</>
            )}
            {nickName && <>{nickName}に</>}
            荷物が届きました。
          </>
        )}
      </Typography>
    </ListItem>
  );
};

export default DeviceHistoryListItem;
