import { useState, useCallback } from 'react';
import Axios from 'axios';

export const useUploadImage = (): {
  handleSubmit: (presignedUrl: string, file: File) => Promise<void>;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = useCallback(async (presignedUrl: string, file: File) => {
    setIsCompleted(false);
    setIsLoading(true);
    setIsError(false);

    try {
      await Axios.put(presignedUrl, file, {
        headers: {
          'Content-Type': file.type,
        },
      });

      setIsCompleted(true);
    } catch (e) {
      setIsError(true);
    }

    setIsLoading(false);
  }, []);

  return { handleSubmit, isCompleted, isLoading, isError };
};
