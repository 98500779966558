import React, { forwardRef } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import AppBar from '@material-ui/core/AppBar';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import Slide from '@material-ui/core/Slide';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import { TransitionProps } from '@material-ui/core/transitions';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';

import { ReactComponent as IconCleaning } from '../../assets/icon-cleaning.svg';
import { useDialog } from '../../hooks/useDialog';
import PageTitleBar from '../atoms/PageTitleBar';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      height: '50px',
      width: '50px',
      padding: '6px',
      marginTop: '-0.6rem',
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.grey[800],
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    button: {
      marginRight: '0.2rem',
      marginLeft: '0.2rem',
      padding: '1px 10px',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
    dialogTitle: {
      flex: 1,
      marginLeft: theme.spacing(2),
    },
    dialogBody: {
      padding: theme.spacing(2, 1),
    },
  }),
);

const Transition = forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="left" ref={ref} {...props} />;
});

const PickupRequestServiceHeader: React.FC<{ showMenu?: boolean }> = ({
  showMenu,
}) => {
  const classes = useStyles();

  const usageTopDialog = useDialog();

  return (
    <>
      <Box mb={2}>
        <PageTitleBar>サービス</PageTitleBar>
        <Box textAlign="center">
          <IconCleaning className={classes.icon} />
        </Box>
        <Box textAlign="center" mb={1}>
          <Typography className={classes.text}>クリーニング</Typography>
        </Box>
        {showMenu && (
          <Box textAlign="center">
            <Button
              className={classes.button}
              component={RouterLink}
              to="/services/pickup-requests/histories"
              color="primary"
              variant="outlined"
            >
              利用履歴
            </Button>
          </Box>
        )}
      </Box>
      <Dialog
        onClose={usageTopDialog.handleClose}
        open={usageTopDialog.open}
        TransitionComponent={Transition}
        fullScreen
      >
        <AppBar position="relative">
          <Toolbar>
            <IconButton
              onClick={usageTopDialog.handleClose}
              edge="start"
              color="inherit"
              aria-label="close"
            >
              <CloseIcon />
            </IconButton>
            <Typography variant="h6" className={classes.dialogTitle}>
              使い方
            </Typography>
          </Toolbar>
        </AppBar>
        <Grid container className={classes.dialogBody}>
          <Grid item xs={12} sm={10} md={8} lg={6} xl={4}>
            ここにクリーニングの使い方が入ります。
          </Grid>
        </Grid>
      </Dialog>
    </>
  );
};

export default PickupRequestServiceHeader;
