import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import InputLabel from '@material-ui/core/InputLabel';
import NativeSelect from '@material-ui/core/NativeSelect';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';

interface FormTextFieldProps {
  error: boolean;
  helperText?: string;
  label: string;
  name: string;
  options: { label: string; value: string }[];
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onChange?: (event: React.FocusEvent<HTMLSelectElement>) => void;
  defaultValue?: string;
  value?: string;
  required?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    formControl: {
      marginBottom: '1.2rem',
    },
    inputLabel: {
      position: 'relative',
      transform: 'none !important',
      marginBottom: theme.spacing(1),
      fontWeight: 'bold',
      '& .MuiInputLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
    nativeSelect: {
      marginTop: '0 !important',
    },
    helperText: {
      color: theme.palette.error.main,
      marginLeft: 0,
    },
  }),
);

const FormTextField: React.FC<FormTextFieldProps> = ({
  error,
  helperText,
  label,
  name,
  options,
  onBlur,
  onChange,
  defaultValue,
  value,
  required,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth variant="filled">
      <InputLabel
        className={classes.inputLabel}
        htmlFor={`${name}-native-label-placeholder`}
        error={error}
        required={required}
      >
        {label}
      </InputLabel>
      <NativeSelect
        className={classes.nativeSelect}
        inputProps={{
          name: name,
          id: `${name}-native-label-placeholder`,
        }}
        error={error}
        fullWidth
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        defaultValue={defaultValue}
        value={value}
        required={required}
      >
        {options.map((option, i) => (
          <option value={option.value} key={i}>
            {option.label}
          </option>
        ))}
      </NativeSelect>
      {error && (
        <FormHelperText className={classes.helperText}>
          {helperText}
        </FormHelperText>
      )}
    </FormControl>
  );
};

export default FormTextField;
