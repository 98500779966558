import { useState } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

interface UnlockSettings {
  yourPassword: number;
  deliveryPassword: number;
  collectionPassword: number;
  unlockMethodFirst: string;
  unlockMethodSecondOnward: string;
}

interface Address {
  firstName: string;
  lastName: string;
  firstNameKana: string;
  lastNameKana: string;
  phoneNumber: string;
  email?: string;
  zip: string;
  state: string;
  address1: string;
  address2: string;
  building?: string;
}

interface Other {
  nickName: string;
}

export interface Device {
  deviceId: string;
  unlockSettings?: UnlockSettings;
  address?: Address;
  other?: Other;
}

export const useUpdateDevice = (): {
  handleSubmit: (values: Device) => void;
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleSubmit = (values: Device) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        await Axios.patch(
          `${REACT_APP_API_ENDPOINT}/devices/${values.deviceId}`,
          {
            unlockSettings: values.unlockSettings,
            address: values.address,
            other: values.other,
          },
          {
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${idToken}`,
            },
          },
        );

        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { handleSubmit, isCompleted, isLoading, isError };
};
