import { useState, useEffect } from 'react';
import Axios, { AxiosResponse } from 'axios';
import { DateTime } from 'luxon';

const REACT_APP_NOTICE_API_ENDPOINT =
  process.env.REACT_APP_NOTICE_API_ENDPOINT!;

export interface Notice {
  date: Date;
  title: string;
  body: string;
}

export const useListNotice = (): {
  data: Notice[];
  isCompleted: boolean;
  isLoading: boolean;
  isError: boolean;
} => {
  const [data, setData] = useState<Notice[]>([]);
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isError, setIsError] = useState(false);

  useEffect(() => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      const timestamp = Math.ceil(DateTime.local().toSeconds());

      try {
        const response: AxiosResponse<Notice[]> = await Axios.get(
          `${REACT_APP_NOTICE_API_ENDPOINT}?${timestamp}`,
          {
            headers: {
              'Content-Type': 'application/json',
            },
          },
        );

        setData(
          response.data.map((notice: Notice) => {
            return {
              date: new Date(notice.date),
              title: notice.title,
              body: notice.body,
            };
          }),
        );
        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  }, []);

  return { data, isCompleted, isLoading, isError };
};
