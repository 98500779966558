import React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListSubheader from '@material-ui/core/ListSubheader';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import operation01 from '../../assets/delivery-box-add-operation-01.png';
import operation02 from '../../assets/delivery-box-add-operation-02.png';
import operation03 from '../../assets/delivery-box-add-operation-03.png';
import operation04 from '../../assets/delivery-box-add-operation-04.png';
import download from '../../assets/icon-back.png';
import LoadingIndicator from '../atoms/LoadingIndicator';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    summary: {
      marginBottom: theme.spacing(2),
    },
    dialogNumber: {
      fontWeight: 'bold',
      fontSize: theme.typography.h4.fontSize,
      margin: theme.spacing(2, 0),
      textAlign: 'center',
      letterSpacing: '5px',
    },
    list: {
      margin: theme.spacing(1, 0),
    },
    listItem: {
      alignItems: 'flex-start',
      [theme.breakpoints.down('sm')]: {
        display: 'block',
      },
    },
    exImg: {
      width: '50%',
      marginRight: theme.spacing(2),
      border: '1px solid #000',
      [theme.breakpoints.down('sm')]: {
        width: '100%',
        marginRight: 0,
      },
    },
    exImgText: {
      color: '#2fa3f3',
      fontWeight: 'bold',
      fontSize: theme.typography.h5.fontSize,
    },
  }),
);

interface DeliveryBoxAuthPasswordDialogProps {
  isError: boolean;
  isLoading: boolean;
  password: string;
  open: boolean;
  onCancel: () => void;
}

const DeliveryBoxAuthPasswordDialog: React.FC<DeliveryBoxAuthPasswordDialogProps> =
  ({ isError, isLoading, password, open, onCancel }) => {
    const classes = useStyles();

    return (
      <Dialog fullWidth open={open} disableBackdropClick>
        <DialogTitle disableTypography>
          <Typography variant="h5" component="h5" color="primary">
            パスワード認証
          </Typography>
        </DialogTitle>
        <DialogContent>
          {isLoading ? (
            <LoadingIndicator />
          ) : isError ? (
            <List
              subheader={
                <ListSubheader disableGutters disableSticky>
                  <Typography color="secondary" gutterBottom>
                    次の内容を確認してください。
                  </Typography>
                </ListSubheader>
              }
            >
              <ListItem disableGutters dense>
                ・ホームユニットはインターネットに正常につながっていますか？
              </ListItem>
              <ListItem disableGutters dense>
                ・スマホアプリの「クラウドサービスを利用する」の設定がOFFになっていませんか？
              </ListItem>
              <ListItem disableGutters dense>
                ・機器番号は正しいですか？
              </ListItem>
            </List>
          ) : (
            <>
              <Typography
                variant="body2"
                component="p"
                className={classes.summary}
              >
                入力されたホームユニット機器番号の確認を行います。
                <br />
                <br />
                以下の認証番号を、スマホの「ホームシステム」アプリで
                「あなたの設定番号」に設定してください。設定後、ダイアログが閉じ登録が完了します。
                設定は、
                <span style={{ color: '#f00' }}>5分以内</span>
                に行なってください。
              </Typography>
              <Typography variant="h6" component="p" color="primary">
                認証番号
              </Typography>
              <DialogContentText
                component="div"
                className={classes.dialogNumber}
              >
                {password}
              </DialogContentText>
              <Typography variant="h6" component="p" color="primary">
                設定手順
              </Typography>
              <List disablePadding className={classes.list}>
                <ListItem className={classes.listItem} disableGutters>
                  <img
                    src={operation01}
                    alt="「ホームシステム」アプリのホーム画面"
                    className={classes.exImg}
                  />
                  <Box>
                    「ホームシステム」アプリを起動し、「宅配ボックス」→「設定」→「あなたの設定番号」を表示
                  </Box>
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <img
                    src={operation02}
                    alt="あなたの設定番号画面"
                    className={classes.exImg}
                  />
                  <Box>
                    「あなたの設定番号」に「認証番号」入力し
                    <span className={classes.exImgText}>OK</span>をタップ
                  </Box>
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <img
                    src={operation03}
                    alt="設定画面"
                    className={classes.exImg}
                  />
                  <Box>
                    <img
                      src={download}
                      alt="戻るボタン"
                      width="25"
                      height="24"
                    />
                    &nbsp;を<span className={classes.exImgText}>2回</span>タップ
                    し、ホーム画面を表示
                  </Box>
                </ListItem>
                <ListItem className={classes.listItem} disableGutters>
                  <img
                    src={operation04}
                    alt="登録完了画面"
                    className={classes.exImg}
                  />
                  <Box>
                    設定後、「あずけるBOX
                    メンバーズサイト（本画面）」を開き、「宅配ボックスの状態」が表示されていれば、登録完了です。
                  </Box>
                </ListItem>
              </List>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={onCancel}>
            <Typography>パスワード認証をキャンセルする</Typography>
          </Button>
        </DialogActions>
      </Dialog>
    );
  };

export default DeliveryBoxAuthPasswordDialog;
