import React, { useCallback, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import Box from '@material-ui/core/Box';
import CircularProgress from '@material-ui/core/CircularProgress';
import IconButton from '@material-ui/core/IconButton';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import CancelIcon from '@material-ui/icons/Cancel';
import DescriptionIcon from '@material-ui/icons/Description';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { FormikErrors } from 'formik';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    dropzone: {
      width: '100%',
      height: 'auto',
      color: theme.palette.grey[500],
      fontSize: theme.typography.body2.fontSize,
      border: `2px dashed ${theme.palette.grey[400]}`,
      borderRadius: '5px',
      marginTop: theme.spacing(1),
      padding: theme.spacing(2),
    },
    list: {
      display: 'flex',
      flexFlow: 'row wrap',
      listStyle: 'none',
      margin: 0,
      padding: 0,
    },
    listItem: {
      position: 'relative',
      width: 120,
      height: 120,
      margin: theme.spacing(1),
    },
    fileDetail: {
      position: 'absolute',
      top: '50%',
      left: '50%',
      minWidth: '100%',
      maxWidth: '100%',
      color: '#000',
      fontSize: '0.75rem',
      transform: 'translate(-50%, -50%)',
      textAlign: 'center',
      padding: '5%',
      boxSizing: 'border-box',
    },
    filetext: {
      background: 'rgba(255, 255, 255, 0.7)',
      borderRadius: '3px',
      padding: theme.spacing(0.5),
    },
    filename: {
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
    },
    thumb: {
      width: '100%',
      height: 'inherit',
      borderRadius: 10,
      overflow: 'hidden',
      background: 'linear-gradient(to bottom, #eee, #ddd)',
      filter: 'blur(2px)',
    },
    icon: {
      position: 'absolute',
      top: '5px',
      left: '5px',
    },
    removeIcon: {
      position: 'absolute',
      top: -25,
      right: -25,
      zIndex: 1000,
    },
    progress: {
      position: 'absolute',
      top: 10,
      left: 0,
      right: 0,
    },
  }),
);

interface FileObj {
  file: File | null;
  uploadFileName: string;
  uploadUrl: string;
}

interface FileUploadComponentProps {
  files: [];
  addDataForUploadImage: (file: File) => Promise<FileObj>;
  setFieldValue: (
    field: string,
    // eslint-disable-next-line
    value: any,
    shouldValidate?: boolean,
    // eslint-disable-next-line
  ) => Promise<FormikErrors<any>> | Promise<void>;
  isLoading?: boolean;
}

const FileUploadComponent: React.FC<FileUploadComponentProps> = ({
  files,
  addDataForUploadImage,
  setFieldValue,
  isLoading,
}) => {
  const classes = useStyles();
  const maxSize = 10485760;

  const [acceptedFiles, setAcceptedFiles] = useState(1);
  const progress = Math.round((files.length / acceptedFiles) * 100);

  const removeFile = useCallback(
    (e, file) => {
      e.stopPropagation();

      const newFiles: FileObj[] = files;
      newFiles.splice(newFiles.indexOf(file), 1);
      setFieldValue('files', newFiles);
    },
    [files, setFieldValue],
  );

  const onDropAccepted = useCallback(
    async (acceptedFiles) => {
      setAcceptedFiles(acceptedFiles.length);

      setFieldValue('files', [
        ...files,
        ...(await Promise.all(
          acceptedFiles.map(async (file: File) => {
            return await addDataForUploadImage(file);
          }),
        )),
      ]);
    },
    [files, addDataForUploadImage, setFieldValue],
  );

  const { getRootProps, getInputProps, fileRejections } = useDropzone({
    onDropAccepted,
    accept:
      'image/*, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .pdf, .csv, .zip, .heic, .heif',
    multiple: true,
    maxSize,
  });

  const isFileTooLarge =
    fileRejections.length > 0 && fileRejections[0].file.size > maxSize;

  return (
    <div {...getRootProps({ className: classes.dropzone })}>
      <input name="files" {...getInputProps()} />
      {isLoading ? (
        <Box textAlign="center" position="relative">
          <CircularProgress />
          <Typography
            variant="caption"
            component="div"
            color="textSecondary"
            className={classes.progress}
          >
            {progress}%
          </Typography>
        </Box>
      ) : (
        <ul className={classes.list}>
          {files &&
            files.length > 0 &&
            files.map((fileObj: FileObj, index: number) => {
              return fileObj.file ? (
                <li
                  key={fileObj.file.name + index}
                  className={classes.listItem}
                >
                  {fileObj.file.type.includes('image') ? (
                    <img
                      src={URL.createObjectURL(fileObj.file)}
                      alt={fileObj.file.name}
                      className={classes.thumb}
                    />
                  ) : fileObj.file.type.includes('pdf') ? (
                    <>
                      <div className={classes.thumb}></div>
                      <PictureAsPdfIcon className={classes.icon} />
                    </>
                  ) : (
                    <>
                      <div className={classes.thumb}></div>
                      <DescriptionIcon className={classes.icon} />
                    </>
                  )}
                  <div className={classes.fileDetail}>
                    <span className={classes.filetext}>
                      {(fileObj.file.size / (1024 * 1024)).toFixed(2)} MB
                    </span>
                    <div className={classes.filename + ' ' + classes.filetext}>
                      {fileObj.file.name}
                    </div>
                  </div>
                  <IconButton
                    className={classes.removeIcon}
                    onClick={(e) => removeFile(e, fileObj.file)}
                  >
                    <CancelIcon fontSize="large" />
                  </IconButton>
                </li>
              ) : null;
            })}
        </ul>
      )}
      {files && files.length === 0 && (
        <span>
          ここにファイルをドラッグするか、クリックしてください。（1ファイルあたりの最大アップロードサイズ:&nbsp;10MB）
        </span>
      )}
      {isFileTooLarge && (
        <p style={{ color: 'red', margin: 0 }}>
          最大ファイルアップロードサイズを超えています。
        </p>
      )}
    </div>
  );
};

export default FileUploadComponent;
