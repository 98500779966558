import { DateTime } from 'luxon';
import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import MuiAlert from '@material-ui/lab/Alert';

import { useGetUser } from '../../../hooks/useGetUser';
import { useListDevice } from '../../../hooks/useListDevice';
import {
  useListPickupRequest,
  PickupRequestStatus,
} from '../../../hooks/useListPickupRequest';
import PageTitleBar from '../../atoms/PageTitleBar';
import MessageBox from '../../molecules/MessageBox';
import PickupRequestServiceHeader from '../../organisms/PickupRequestServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    list: {
      marginBottom: theme.spacing(2),
      '& .MuiButtonBase-root': {
        padding: 'unset',
      },
      '& .MuiListItem-root': {
        paddingBottom: theme.spacing(1),
      },
    },
    link: {
      position: 'relative',
      display: 'flex',
      width: '100%',
      color: 'inherit',
      textDecoration: 'none',
      padding: theme.spacing(0, 0.5),
    },
    status: {
      width: '28%',
      alignSelf: 'stretch',
      fontWeight: 'bold',
      borderRight: `2px solid ${theme.palette.primary.main}`,
      marginRight: '5%',
      paddingRight: '2%',
    },
  }),
);

const getStatusText = (status: PickupRequestStatus) => {
  if (status === 'postingWait') return '投函待ち';
  if (status === 'requested') return '申込済み';
  if (status === 'requestCanceled') return '申込キャンセル';
  if (status === 'approved') return '受付済み';
  if (status === 'approveRejected') return '受付キャンセル';
  if (status === 'pickedup') return '集荷済み';
  if (status === 'delivering') return '配達中';
  if (status === 'delivered') return '配達済み';

  return '不明';
};

const PickupRequestHistoryPage: React.FC = () => {
  const classes = useStyles();

  const listDeliveryBox = useListDevice();

  useEffect(() => {
    if (!listDeliveryBox.isCompleted && !listDeliveryBox.isLoading) {
      listDeliveryBox.getData();
    }
  }, [listDeliveryBox]);

  const listPickupRequest = useListPickupRequest();

  useEffect(() => {
    if (
      listDeliveryBox.data.length > 0 &&
      !listPickupRequest.isCompleted &&
      !listPickupRequest.isError &&
      !listPickupRequest.isLoading
    ) {
      listPickupRequest.getData(/*listDeliveryBox.data[0].deviceId*/);
    }
  }, [listPickupRequest, listDeliveryBox]);

  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const [canUseCleaning, setCanUseCleaning] = useState(false);

  useEffect(() => {
    if (getUser.data.scopes.includes('members_site_use_cleaning')) {
      setCanUseCleaning(true);
    }
  }, [getUser]);

  return (
    <GenericTemplate
      loading={
        getUser.isLoading ||
        listDeliveryBox.isLoading ||
        listPickupRequest.isLoading
      }
    >
      <PickupRequestServiceHeader showMenu={canUseCleaning} />
      <PageTitleBar>ご利用状況の一覧</PageTitleBar>
      {listPickupRequest.isError && (
        <MessageBox color="secondary">
          クリーニングサービスのご利用状況が取得出来ませんでした。
        </MessageBox>
      )}
      {listPickupRequest.isCompleted && (
        <>
          {listPickupRequest.data.length === 0 && (
            <MuiAlert severity="info">利用履歴はありません</MuiAlert>
          )}
          <List className={classes.list}>
            {listPickupRequest.data.map((pickupRequest, index) => (
              <ListItem button key={index}>
                <Link
                  to={`/services/pickup-requests/${pickupRequest.pickupRequestId}/view`}
                  className={classes.link}
                >
                  <Typography color="primary" className={classes.status}>
                    {getStatusText(pickupRequest.status)}
                  </Typography>
                  <Box>
                    <Typography className={classes.detail}>
                      申し込み番号: {pickupRequest.pickupRequestId}
                    </Typography>
                    <Typography className={classes.detail}>
                      {DateTime.fromMillis(pickupRequest.updateAt)
                        .toLocal()
                        .toFormat('yyyy/MM/dd')}
                    </Typography>
                  </Box>
                </Link>
              </ListItem>
            ))}
          </List>
        </>
      )}
    </GenericTemplate>
  );
};

export default PickupRequestHistoryPage;
