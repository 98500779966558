import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Link as RouterLink, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import SnackbarContent from '@material-ui/core/SnackbarContent';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import LaunchIcon from '@material-ui/icons/Launch';

import { useContact } from '../../hooks/useContact';
import {
  ContactUploadUrls,
  useCreateContactUploadUrls,
} from '../../hooks/useCreateContactUploadUrls';
import { useDialog } from '../../hooks/useDialog';
import { useUploadImage } from '../../hooks/useUploadImage';
import AuthService from '../../utils/AuthService';
import FileUploadComponent from '../atoms/FileUploadComponent';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    form: {
      marginTop: theme.spacing(2),
    },
    information: {
      background: '#eee',
      color: theme.palette.common.black,
      marginTop: theme.spacing(2),
      boxShadow: 'none',
    },
    filesButton: {
      color: 'rgba(0, 0, 0, 0.54)',
      marginTop: theme.spacing(1),
    },
  }),
);

interface FileObj {
  uploadFileName: string;
  uploadUrl: string;
  file: File | never;
}

interface ContactFormValues {
  name: string;
  email: string;
  subject: string;
  text: string;
  files: [];
}

const validateRules = (values: ContactFormValues) => {
  const errors: {
    [key: string]: string;
  } = {};

  if (!values.name) {
    errors.name = '必須項目です。';
  }

  if (!values.email) {
    errors.email = '必須項目です。';
  } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = '正しいメールアドレスを入力してください。';
  }

  if (!values.subject) {
    errors.subject = '必須項目です。';
  }

  if (!values.text) {
    errors.text = '必須項目です。';
  }

  return errors;
};

const ContactForm: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();

  const isAuthenticated = AuthService.isAuthenticated();
  const isContactTab =
    history.location.pathname === '/contact' ||
    history.location.hash === '#contact';

  const { handleSubmit, isError, isCompleted } = useContact();
  const {
    handleSubmit: createPresignedUrls,
    isLoading: createPresignedUrlsIsLoading,
    isError: createPresignedUrlsIsError,
  } = useCreateContactUploadUrls();
  const {
    handleSubmit: uploadImageToS3,
    isLoading: uploadImageToS3IsLoading,
    isError: uploadImageToS3IsError,
  } = useUploadImage();
  const consentDialog = useDialog();
  const confirmDialog = useDialog();

  const formik = useFormik<ContactFormValues>({
    initialValues: {
      name: '',
      email: '',
      subject: '',
      text: '',
      files: [],
    },
    validate: (values) => validateRules(values),
    onSubmit: (values) => {
      const isFileUploaded = values.files && values.files.length > 0;
      const fileNameMappings = isFileUploaded
        ? values.files.map((fileObj: FileObj) => {
            return {
              fileName: fileObj.file.name,
              uploadFileName: fileObj.uploadFileName,
            };
          })
        : [{ fileName: '', uploadFileName: '' }];

      handleSubmit({ ...values, fileNameMappings, isFileUploaded });
    },
  });

  const addDataForUploadImage = useCallback(
    async (file) => {
      const res: ContactUploadUrls = await createPresignedUrls(file);
      await uploadImageToS3(res.uploadUrl, file);

      return {
        file,
        uploadFileName: res.uploadFileName,
        uploadUrl: res.uploadUrl,
      };
    },
    [uploadImageToS3, createPresignedUrls],
  );

  const [isVisibleDropzone, setIsVisibleDropzone] = useState(false);
  const handleToggleDropzone = useCallback(() => {
    setIsVisibleDropzone((prevState) => !prevState);
  }, []);

  const handleSubmitAndClose = useCallback(() => {
    formik.submitForm();
    confirmDialog.handleClose();
  }, [formik, confirmDialog]);

  const [isConsented, setIsConsented] = useState(false);
  useEffect(() => {
    if (isContactTab && !isAuthenticated && !isConsented) {
      consentDialog.handleOpen();
    }
  }, [isContactTab, isAuthenticated, isConsented, consentDialog]);

  const handleClickConsentBtn = useCallback(() => {
    consentDialog.handleClose();
    setIsConsented(true);
  }, [consentDialog]);

  const handleClickDissentBtn = useCallback(() => {
    consentDialog.handleClose();
    setIsConsented(false);
    history.push('/');
  }, [consentDialog, history]);

  return isCompleted ? (
    <Box mt={3}>
      <Typography variant="subtitle2">
        お問い合わせありがとうございます。
        <br />
        内容確認してご連絡いたしますので、今しばらくお待ち頂きますようよろしくお願いします。
        <br />
        なお、お問い合わせが混み合っている場合、返信が遅くなる場合がありますので、
        <br />
        予めご了承のほどよろしくお願いします。
        <br />
        <br />
        スマートエクステリア サポートより
      </Typography>
    </Box>
  ) : (
    <>
      {isError && (
        <SnackbarContent
          className={classes.information}
          message={
            <Typography variant="subtitle2" color="secondary">
              エラーが発生しました
            </Typography>
          }
        />
      )}
      {(createPresignedUrlsIsError || uploadImageToS3IsError) && (
        <SnackbarContent
          className={classes.information}
          message={
            <Typography variant="subtitle2" color="secondary">
              画像のアップロードに失敗しました。
            </Typography>
          }
        />
      )}
      <form onSubmit={formik.handleSubmit} className={classes.form}>
        <FormControl fullWidth variant="filled">
          <TextField
            name="name"
            id="name"
            label="氏名"
            defaultValue={formik.values.name}
            placeholder="登録済みの方は登録されたお名前をご記入ください。"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.name && !!formik.touched.name}
            helperText={formik.errors.name}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="filled">
          <TextField
            name="email"
            id="email"
            label="メールアドレス"
            defaultValue={formik.values.email}
            placeholder="登録済みの方は登録されたメールアドレスをご記入ください。"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.email && !!formik.touched.email}
            helperText={formik.errors.email}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="filled">
          <TextField
            name="subject"
            id="subject"
            label="件名"
            defaultValue={formik.values.subject}
            placeholder="ご質問、ご相談内容の概要をご記入ください。"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.subject && !!formik.touched.subject}
            helperText={formik.errors.subject}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <FormControl fullWidth variant="filled">
          <TextField
            name="text"
            id="text"
            label="お問い合わせ内容"
            multiline
            rows="6"
            defaultValue={formik.values.text}
            placeholder="お問い合わせ内容をご記入ください。"
            fullWidth
            margin="dense"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            error={!!formik.errors.text && !!formik.touched.text}
            helperText={formik.errors.text}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </FormControl>
        <Button
          variant="outlined"
          size="small"
          startIcon={<CloudUploadIcon />}
          className={classes.filesButton}
          onClick={handleToggleDropzone}
        >
          添付ファイル（任意）
        </Button>
        {isVisibleDropzone ? (
          <FileUploadComponent
            files={formik.values.files}
            addDataForUploadImage={addDataForUploadImage}
            setFieldValue={formik.setFieldValue}
            isLoading={createPresignedUrlsIsLoading || uploadImageToS3IsLoading}
          />
        ) : null}
        <Box mt={2}>
          <Button
            onClick={confirmDialog.handleOpen}
            variant="contained"
            color="primary"
            className={classes.submitButton}
            disabled={createPresignedUrlsIsLoading || uploadImageToS3IsLoading}
          >
            送信する
          </Button>
          <Dialog
            open={confirmDialog.open}
            onClose={confirmDialog.handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">確認</DialogTitle>
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                入力したメールアドレス宛にサポートから返信が届きます。
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={confirmDialog.handleClose}
                variant="contained"
                color="default"
                autoFocus
              >
                修正する
              </Button>
              <Button
                onClick={handleSubmitAndClose}
                variant="contained"
                color="primary"
              >
                送信する
              </Button>
            </DialogActions>
          </Dialog>
          <Dialog
            open={consentDialog.open}
            onClose={handleClickDissentBtn}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogContent>
              <DialogContentText id="alert-dialog-description">
                お預かりする個人情報は、プライバシーポリシーに則り取り扱いさせていただきます。
                <br />
                <br />
                <RouterLink
                  color="textPrimary"
                  to={`/privacy`}
                  target="_blank"
                  style={{ display: 'flex', alignItems: 'center' }}
                >
                  プライバシーポリシー
                  <LaunchIcon color="inherit" fontSize="small" />
                </RouterLink>
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button
                onClick={handleClickDissentBtn}
                variant="contained"
                color="default"
                autoFocus
              >
                キャンセル
              </Button>
              <Button
                onClick={handleClickConsentBtn}
                variant="contained"
                color="primary"
              >
                同意する
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </form>
    </>
  );
};

export default ContactForm;
