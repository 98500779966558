import React, { useEffect, useState } from 'react';
import MuiAlert from '@material-ui/lab/Alert';

import { useInterval } from '../../hooks/useInterval';
import { useListDevice } from '../../hooks/useListDevice';
import PageTitleBar from '../atoms/PageTitleBar';
import DeviceList from '../organisms/DeviceList';
import ServiceList from '../organisms/ServiceList';
import TutorialDialog from '../organisms/TutorialDialog';
import GenericTemplate from '../templates/GenericTemplate';
import { useGetUser } from '../../hooks/useGetUser';
import { useUpdateUser } from '../../hooks/useUpdateUser';

const TopPage: React.FC = () => {
  const listDevices = useListDevice();
  const getUser = useGetUser();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const [open, setOpen] = useState<boolean>(false);

  useEffect(() => {
    if (getUser.isCompleted && !getUser.data.checkedTutorial) {
      setOpen(true);
    }
  }, [getUser.isCompleted, getUser.data, setOpen]);

  const updateUser = useUpdateUser();

  const onClick = () => {
    setOpen(false);
    updateUser.handleSubmit({
      checkedTutorial: true,
    });
  };

  useInterval(() => {
    listDevices.getData();
  }, 10000);

  const [isLoaded, setIsLoaded] = useState<boolean>(false);

  useEffect(() => {
    if (listDevices.isCompleted || listDevices.isError) {
      setIsLoaded(true);
    }
  }, [listDevices.isCompleted, listDevices.isError]);

  return (
    <GenericTemplate loading={!isLoaded}>
      {listDevices.isError ? (
        <MuiAlert severity="error">ページの読み込みに失敗しました。</MuiAlert>
      ) : (
        <>
          <ServiceList devices={listDevices.data} />
          <PageTitleBar>デバイス</PageTitleBar>
          <DeviceList devices={listDevices.data} />
        </>
      )}
      <TutorialDialog open={open} onClick={onClick} />
    </GenericTemplate>
  );
};

export default TopPage;
