import { useFormik } from 'formik';
import React, { useCallback, useEffect, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import * as colors from '@material-ui/core/colors';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormLabel from '@material-ui/core/FormLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';
import TodayIcon from '@material-ui/icons/Today';
import MuiAlert from '@material-ui/lab/Alert';

import { ReactComponent as IconMottekite } from '../../../assets/icon-mottekite.svg';
import { ReactComponent as IconMottette } from '../../../assets/icon-mottette.svg';
import { useCancelDeliveryBoxShare } from '../../../hooks/useCancelDeliveryBoxShare';
import {
  DeliveryBoxShare,
  useCreateDeliveryBoxShare,
} from '../../../hooks/useCreateDeliveryBoxShare';
import { useDialog } from '../../../hooks/useDialog';
import { useGetUser } from '../../../hooks/useGetUser';
import { useListDevice } from '../../../hooks/useListDevice';
import { useListDeliveryBoxShare } from '../../../hooks/useListDeliveryBoxShare';
// import FormSelectField from '../../molecules/FormSelectField';
import FormTextField from '../../molecules/FormTextField';
import MessageBox from '../../molecules/MessageBox';
import DeliveryBoxShareServiceHeader from '../../organisms/DeliveryBoxShareServiceHeader';
import GenericTemplate from '../../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    radioGroup: {
      marginBottom: '1.2rem',
      width: '100%',
      // maxWidth: '600px',
      display: 'block',
      '& .MuiFormLabel-root': {
        color: theme.palette.grey[700],
        fontWeight: 'bold',
        marginBottom: '0.6rem',
        '& .MuiFormLabel-asterisk': {
          color: theme.palette.error.main,
        },
      },
      '& .MuiFormGroup-root': {
        width: '100%',
        marginRight: '-4px',
        marginLeft: '-4px',
      },
      '& .MuiFormControlLabel-root': {
        marginRight: 'unset',
        marginLeft: 'unset',
        width: '50%',
        paddingRight: '6px',
        paddingLeft: '6px',
        position: 'relative',
        '& .MuiRadio-root': {
          borderColor: theme.palette.grey[500],
          borderStyle: 'solid',
          borderWidth: '2px',
          borderRadius: '3px',
          height: '60px',
          width: '100%',
          padding: '0',
          '& .MuiIconButton-label': {
            display: 'none',
          },
        },
        '& .Mui-checked': {
          borderColor: theme.palette.primary.main,
        },
        '& .MuiTypography-root': {
          color: theme.palette.grey[600],
          fontWeight: 'bold',
          whiteSpace: 'nowrap',
          position: 'absolute',
          transform: 'translate(-50%, -50%)',
          '-webkit-transform': 'translate(-50%, -50%)',
          '-ms-transform': 'translate(-50%, -50%)',
          top: '50%',
          left: '50%',
          display: 'flex',
          alignItems: 'center',
        },
        '& .Mui-checked + .MuiTypography-root': {
          color: theme.palette.primary.main,
        },
      },
    },
    balloon: {
      position: 'relative',
      width: '49%',
      display: 'inline-block',
      marginTop: '0.6rem',
      paddingTop: '6px',
      paddingRight: '12px',
      paddingBottom: '6px',
      paddingLeft: '12px',
      textAlign: 'center',
      color: colors.red[500],
      fontSize: '0.7rem',
      fontWeight: 'bold',
      background: colors.red[100],
      borderRadius: '8px',
      boxSizing: 'border-box',
      '&::before': {
        content: '""',
        position: 'absolute',
        top: '-12px',
        left: '40%',
        marginLeft: '-6px',
        borderBottomColor: colors.red[100],
        borderBottomWidth: '6px',
        borderBottomStyle: 'solid',
        borderColor: 'transparent',
        borderWidth: '6px',
        borderStyle: 'solid',
        zIndex: '0',
      },
    },
    cancelButton: {
      fontWeight: 'bold',
      display: 'block',
    },
  }),
);

const validateRules = (values: DeliveryBoxShare) => {
  const errors: {
    [key: string]: string;
  } = {};

  if (!values.deviceId) {
    errors.deviceId = '必須項目です。';
  }

  if (!values.destination) {
    errors.destination = '必須項目です。';
  }

  if (!values.duration) {
    errors.duration = '必須項目です。';
  }

  if (!values.type) {
    errors.type = '必須項目です。';
  }

  return errors;
};

const RadioGroupService: React.FC<{
  defaultValue: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}> = ({ defaultValue, onChange }) => {
  const classes = useStyles();

  const [value, setValue] = useState(defaultValue);

  const _onChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    value: string,
  ): void => {
    if (onChange) {
      onChange(event);
    }

    setValue(value);
  };

  return (
    <FormControl component="fieldset" className={classes.radioGroup}>
      <FormLabel component="legend" required>
        サービス選択
      </FormLabel>
      <RadioGroup
        aria-label="type"
        name="type"
        value={defaultValue}
        onChange={_onChange}
        row
      >
        <FormControlLabel
          value="pickup"
          control={<Radio color="default" />}
          label={
            <>
              <Box pr={1}>
                <IconMottette />
              </Box>
              もってって
            </>
          }
        />
        <FormControlLabel
          value="delivery"
          control={<Radio color="default" />}
          label={
            <>
              <Box pr={1}>
                <IconMottekite />
              </Box>
              もってきて
            </>
          }
        />
      </RadioGroup>
      {value === 'pickup' && (
        <Typography className={classes.balloon}>
          「集荷待ち」状態にしてください
        </Typography>
      )}
    </FormControl>
  );
};

const RadioGroupExpiration: React.FC<{
  defaultValue: string;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
}> = ({ defaultValue, onChange }) => {
  const classes = useStyles();

  return (
    <FormControl component="fieldset" className={classes.radioGroup}>
      <FormLabel component="legend" required>
        期限
      </FormLabel>
      <RadioGroup
        aria-label="duration"
        name="duration"
        value={defaultValue}
        onChange={onChange}
        row
      >
        <FormControlLabel
          value="today"
          control={<Radio color="default" />}
          label={
            <>
              <Box pr={1}>
                <TodayIcon style={{ verticalAlign: 'middle' }} />
              </Box>
              当日中
            </>
          }
        />
        <FormControlLabel
          value="tomorrow"
          control={<Radio color="default" />}
          label={
            <>
              <Box pr={1}>
                <TodayIcon style={{ verticalAlign: 'middle' }} />
              </Box>
              翌日中
            </>
          }
        />
      </RadioGroup>
    </FormControl>
  );
};

const DeliveryBoxSharePage: React.FC = () => {
  const classes = useStyles();
  const createDeliveryBoxShare = useCreateDeliveryBoxShare();
  const getUser = useGetUser();
  const listDeliveryBox = useListDevice();

  useEffect(() => {
    if (!getUser.isCompleted && !getUser.isLoading && !getUser.isError) {
      getUser.getData();
    }
  }, [getUser]);

  const formik = useFormik<DeliveryBoxShare>({
    initialValues: {
      deviceId:
        listDeliveryBox.data.length > 0 ? listDeliveryBox.data[0].deviceId : '',
      type: 'pickup',
      duration: 'today',
      destination:
        getUser.data.lastName + getUser.data.firstName
          ? getUser.data.lastName + getUser.data.firstName + 'からのお知らせ'
          : '',
    },
    validate: (values) => validateRules(values),
    onSubmit: (values) => {
      createDeliveryBoxShare.handleSubmit(values);
    },
    enableReinitialize: true,
  });

  useEffect(() => {
    if (
      !listDeliveryBox.isLoading &&
      !listDeliveryBox.isCompleted &&
      !listDeliveryBox.isError
    ) {
      listDeliveryBox.getData();
    }
  }, [listDeliveryBox]);

  const [isExistDeliveryBox, setIsExistDeliveryBox] = useState(false);
  const [isAvailableDeliveryBox, setIsAvailableDeliveryBox] = useState(false);
  const [isAvailableService, setIsAvailableService] = useState(false);
  const [isExistAddress, setIsExistAddress] = useState(false);
  const [isNotPickupMode, setIsNotPickupMoode] = useState(false);

  useEffect(() => {
    if (formik.values.deviceId) {
      setIsExistDeliveryBox(true);

      const deliveryBox = listDeliveryBox.data.find((item) => {
        return item.deviceId === formik.values.deviceId;
      });

      if (!deliveryBox) {
        setIsAvailableDeliveryBox(false);
        setIsAvailableService(false);
        setIsExistAddress(false);
      }

      if (deliveryBox && deliveryBox.isHubConnectAvailable) {
        setIsAvailableDeliveryBox(true);
      } else {
        setIsAvailableDeliveryBox(false);
      }

      if (deliveryBox && deliveryBox.service) {
        setIsAvailableService(false);
        if (
          deliveryBox.service === 'deliveryBoxSharePickup' &&
          deliveryBox.status === 'empty'
        ) {
          setIsNotPickupMoode(true);
        }
      } else {
        setIsAvailableService(true);
      }

      if (deliveryBox && deliveryBox.zip) {
        setIsExistAddress(true);
      } else {
        setIsExistAddress(false);
      }
    } else {
      setIsAvailableService(false);
      setIsExistAddress(false);
    }
  }, [formik.values.deviceId, listDeliveryBox.data]);

  const listDeliveryBoxShare = useListDeliveryBoxShare();

  useEffect(() => {
    if (
      formik.values.deviceId &&
      !isAvailableService &&
      !listDeliveryBoxShare.isLoading &&
      !listDeliveryBoxShare.isCompleted &&
      !listDeliveryBoxShare.isError
    ) {
      listDeliveryBoxShare.getData(formik.values.deviceId);
    }
  }, [formik.values.deviceId, listDeliveryBoxShare, isAvailableService]);

  const [deliveryBoxShareId, setDeliveryBoxShareId] = useState<
    undefined | string
  >();

  useEffect(() => {
    const deliveryBoxShare = listDeliveryBoxShare.data.find((d) => {
      return d.status === 'reserved';
    });

    if (deliveryBoxShare) {
      setDeliveryBoxShareId(deliveryBoxShare.deliveryBoxShareId);
    } else {
      setDeliveryBoxShareId(undefined);
    }
  }, [listDeliveryBoxShare.data]);

  const history = useHistory();

  useEffect(() => {
    if (createDeliveryBoxShare.isCompleted) {
      history.push(
        `/services/delivery-box-shares/${createDeliveryBoxShare.data.deliveryBoxShareId}/view`,
      );
    }
  }, [
    createDeliveryBoxShare.isCompleted,
    createDeliveryBoxShare.data.deliveryBoxShareId,
    history,
  ]);

  const cancelDeliveryBoxShare = useCancelDeliveryBoxShare();
  const cancelDialog = useDialog();

  const handleCancelDeliveryBoxShare = useCallback(() => {
    cancelDeliveryBoxShare.handleSubmit(deliveryBoxShareId!);
    cancelDialog.handleClose();
  }, [cancelDeliveryBoxShare, cancelDialog, deliveryBoxShareId]);

  useEffect(() => {
    if (cancelDeliveryBoxShare.isCompleted) {
      setIsAvailableService(true);
    }
  }, [cancelDeliveryBoxShare.isCompleted]);

  return (
    <GenericTemplate
      loading={
        listDeliveryBox.isLoading ||
        listDeliveryBoxShare.isLoading ||
        cancelDeliveryBoxShare.isLoading ||
        createDeliveryBoxShare.isLoading
      }
    >
      <DeliveryBoxShareServiceHeader />
      <Box mb={3}>
        <MessageBox>
          <Typography variant="body2">
            宅配ボックスをシェアして、お知り合いの方とものの受け渡しができます。
            <br />
            サービス申し込み後、宅配ボックスのシェア（解錠）用URLをお知り合いの方へ連絡してください。
            <br />
            <span style={{ color: 'red' }}>
              ※すべて選択・入力の上、URLを発行してください。
            </span>
          </Typography>
        </MessageBox>
      </Box>
      {createDeliveryBoxShare.isError && (
        <Box mb={2}>
          <MuiAlert severity="error" icon={false}>
            {createDeliveryBoxShare.errorMessage}
          </MuiAlert>
        </Box>
      )}
      {cancelDeliveryBoxShare.isError && (
        <Box mb={2}>
          <MuiAlert severity="error" icon={false}>
            {cancelDeliveryBoxShare.errorMessage}
          </MuiAlert>
        </Box>
      )}
      {cancelDeliveryBoxShare.isCompleted && (
        <Box mb={2}>
          <MuiAlert severity="success" icon={false}>
            シェアの取り消しが完了しました。
          </MuiAlert>
        </Box>
      )}
      {listDeliveryBox.isError ? (
        <Box mb={2}>
          <MuiAlert severity="error" icon={false}>
            宅配ボックス一覧の取得に失敗しました。
          </MuiAlert>
        </Box>
      ) : (
        listDeliveryBox.isCompleted && (
          <form onSubmit={formik.handleSubmit}>
            {/* <Box>
              <FormSelectField
                defaultValue={formik.values.deviceId}
                value={formik.values.deviceId}
                error={!!formik.errors.deviceId && !!formik.touched.deviceId}
                helperText={formik.errors.deviceId}
                label="宅配ボックス"
                name="deviceId"
                onBlur={formik.handleBlur}
                onChange={formik.handleChange}
                options={listDeliveryBox.data.map((deliveryBox) => ({
                  label: deliveryBox.nickName
                    ? deliveryBox.nickName
                    : 'マイ宅配ボックス',
                  value: deliveryBox.deviceId,
                }))}
                required
              />
            </Box> */}
            {isExistDeliveryBox &&
            isAvailableDeliveryBox &&
            isAvailableService &&
            isExistAddress ? (
              <>
                <RadioGroupService
                  defaultValue={formik.values.type}
                  onChange={formik.handleChange}
                />
                <RadioGroupExpiration
                  defaultValue={formik.values.duration}
                  onChange={formik.handleChange}
                />
                <FormTextField
                  value={formik.values.destination}
                  error={
                    !!formik.errors.destination && !!formik.touched.destination
                  }
                  helperText={formik.errors.destination}
                  label="用途・お知らせメッセージ"
                  name="destination"
                  onBlur={formik.handleBlur}
                  onChange={formik.handleChange}
                  placeholder="例）&#13;&#10;明日届けてくれる予定の本ですが、&#13;&#10;宅配ボックスに入れてくださいね。"
                  multiline
                  required
                />
                <Button
                  color="primary"
                  type="submit"
                  variant="contained"
                  fullWidth
                >
                  次へ進む
                </Button>
              </>
            ) : !isExistDeliveryBox ? (
              <>
                <Box mb={2}>
                  <MuiAlert severity="error" icon={false}>
                    サービスのご利用には宅配ボックスの登録が必要です。
                  </MuiAlert>
                </Box>
                <Button
                  component={Link}
                  to="/devices/delivery-boxes/add"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  宅配ボックスを登録する
                </Button>
              </>
            ) : !isAvailableDeliveryBox ? (
              <>
                <Box mb={2}>
                  <MuiAlert severity="error" icon={false}>
                    宅配ボックスが現在ご利用できません。ホームユニットがインターネットに接続できているかご確認ください。
                  </MuiAlert>
                </Box>
                <Button
                  component={Link}
                  to="/contact"
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  お問い合わせ
                </Button>
              </>
            ) : !isAvailableService ? (
              <>
                {deliveryBoxShareId ? (
                  <>
                    <Box mb={2}>
                      {isNotPickupMode && (
                        <Box mb={1}>
                          <MuiAlert severity="warning">
                            「集荷待ち」状態にしてください
                          </MuiAlert>
                        </Box>
                      )}
                      <MuiAlert severity="error">
                        宅配ボックスは現在シェア中です。
                      </MuiAlert>
                    </Box>
                    <Box mb={1}>
                      <Button
                        component={Link}
                        to={`/services/delivery-box-shares/${deliveryBoxShareId}/view`}
                        color="primary"
                        variant="contained"
                        fullWidth
                      >
                        現在のシェアの詳細を確認する
                      </Button>
                    </Box>
                    <Box mb={1}>
                      <Button
                        className={classes.cancelButton}
                        onClick={cancelDialog.handleOpen}
                        color="secondary"
                        variant="outlined"
                        fullWidth
                      >
                        現在のシェアを取り消す
                      </Button>
                    </Box>
                    <Dialog
                      open={cancelDialog.open}
                      onClose={cancelDialog.handleClose}
                    >
                      <DialogTitle>シェアの取り消し</DialogTitle>
                      <DialogContent>
                        <DialogContentText>
                          宅配ボックスのシェアを取り消します。
                        </DialogContentText>
                      </DialogContent>
                      <DialogActions>
                        <Button
                          onClick={cancelDialog.handleClose}
                          variant="contained"
                          autoFocus
                        >
                          キャンセル
                        </Button>
                        <Button
                          onClick={handleCancelDeliveryBoxShare}
                          variant="contained"
                          color="secondary"
                        >
                          シェアを取り消す
                        </Button>
                      </DialogActions>
                    </Dialog>
                  </>
                ) : (
                  <Box mb={2}>
                    <MuiAlert severity="error" icon={false}>
                      宅配ボックスは他のサービスで使用中です。
                    </MuiAlert>
                  </Box>
                )}
              </>
            ) : (
              <>
                <Box mb={2}>
                  <MuiAlert severity="error" icon={false}>
                    サービスのご利用には住所の登録が必要です。
                  </MuiAlert>
                </Box>
                <Button
                  component={Link}
                  to={`/devices/delivery-boxes/${formik.values.deviceId}/address/add?redirect=/services/delivery-box-shares`}
                  color="primary"
                  variant="contained"
                  fullWidth
                >
                  住所を登録する
                </Button>
              </>
            )}
          </form>
        )
      )}
    </GenericTemplate>
  );
};

export default DeliveryBoxSharePage;
