import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import StayCurrentPortraitIcon from '@material-ui/icons/StayCurrentPortrait';

import PageTitleBar from '../atoms/PageTitleBar';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    icon: {
      height: '50px',
      width: '50px',
      padding: '6px',
      marginTop: '-0.6rem',
      color: theme.palette.primary.main,
    },
    text: {
      color: theme.palette.grey[800],
      fontWeight: 'bold',
      fontSize: '0.9rem',
    },
    button: {
      marginRight: '0.2rem',
      marginLeft: '0.2rem',
      padding: '1px 10px',
      fontWeight: 'bold',
      fontSize: '0.7rem',
    },
  }),
);

const DemoServiceHeader: React.FC = () => {
  const classes = useStyles();

  return (
    <>
      <Box mb={2}>
        <PageTitleBar>サービス</PageTitleBar>
        <Box textAlign="center">
          <StayCurrentPortraitIcon className={classes.icon} />
        </Box>
        <Box textAlign="center" mb={1}>
          <Typography className={classes.text}>レンタルサービス</Typography>
        </Box>
        <Box textAlign="center">
          <Button
            className={classes.button}
            component={RouterLink}
            to="/services/demo-rental-service/histories"
            color="primary"
            variant="outlined"
          >
            利用履歴
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default DemoServiceHeader;
