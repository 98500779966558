import * as React from 'react';
import Box from '@material-ui/core/Box';
import Button from '@material-ui/core/Button';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

import MessageBox from '../molecules/MessageBox';
import GenericTemplate from '../templates/GenericTemplate';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    bold: {
      fontWeight: 'bold',
    },
    title: {
      [theme.breakpoints.down('sm')]: {
        fontSize: theme.typography.h6.fontSize,
      },
    },
    subText: {
      fontSize: theme.typography.h5.fontSize,
    },
  }),
);

const NotFoundPage: React.FC = () => {
  const classes = useStyles();

  return (
    <GenericTemplate>
      <Typography
        variant="h5"
        component="h2"
        className={classes.title + ' ' + classes.bold}
      >
        お探しのページが見つかりませんでした
      </Typography>
      <Box mt={2}>
        <Typography
          variant="h3"
          component="h2"
          color="primary"
          className={classes.bold}
        >
          404 <span className={classes.subText}>URL Not Found</span>
        </Typography>
      </Box>
      <Box mt={2}>
        <MessageBox>URLが間違っているか、ページが存在しません。</MessageBox>
      </Box>
      <Box mt={4} textAlign="center">
        <Button
          variant="outlined"
          color="primary"
          href="/"
          className={classes.bold}
        >
          トップページに戻る
        </Button>
      </Box>
    </GenericTemplate>
  );
};

export default NotFoundPage;
