import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import { makeStyles, StyleRules, Theme } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Typography from '@material-ui/core/Typography';

interface FormTextFieldProps {
  error: boolean;
  label: string;
  name: string;
  value?: string;
  defaultValue?: string;
  description?: string;
  helperText?: string;
  onBlur?: (
    event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  placeholder: string;
  type?: string;
  readOnly?: boolean;
  multiline?: boolean;
  required?: boolean;
}

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    formControl: {
      marginBottom: '1.2rem',
    },
    formTextField: {
      '& .MuiInputLabel-formControl': {
        position: 'relative',
        fontWeight: 'bold',
      },
      '& .MuiInputLabel-shrink': {
        transform: 'none',
      },
      '& label + .MuiInput-formControl': {
        marginTop: '0.2rem',
      },
      '& .MuiInputLabel-asterisk': {
        color: theme.palette.error.main,
      },
    },
    labelText: {
      fontSize: '0.9rem',
      fontWeight: 'bold',
    },
    descriptionText: {
      fontSize: '0.6rem',
    },
  }),
);

const FormTextField: React.FC<FormTextFieldProps> = ({
  error,
  label,
  name,
  value,
  defaultValue,
  description,
  helperText,
  onBlur,
  onChange,
  placeholder,
  type,
  readOnly,
  multiline,
  required,
}) => {
  const classes = useStyles();

  return (
    <FormControl className={classes.formControl} fullWidth variant="filled">
      <TextField
        className={classes.formTextField}
        value={value}
        defaultValue={defaultValue}
        error={error}
        fullWidth
        helperText={error ? helperText : ''}
        InputProps={{
          readOnly,
          disableUnderline: readOnly,
        }}
        InputLabelProps={{
          shrink: true,
        }}
        label={
          <>
            {description ? (
              <>
                <Typography component="p" className={classes.labelText}>
                  {label}
                </Typography>
                <Typography component="p" className={classes.descriptionText}>
                  {description}
                </Typography>
              </>
            ) : (
              <>{label}</>
            )}
          </>
        }
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        placeholder={placeholder}
        type={type ? type : 'text'}
        multiline={multiline}
        required={required}
      />
    </FormControl>
  );
};

export default FormTextField;
