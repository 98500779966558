import { useState } from 'react';
import Axios from 'axios';

import AuthService from '../utils/AuthService';

const REACT_APP_API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT!;

export const useDeleteDevice = (): {
  handleDeleteHub: (deviceId: string) => void;
  isLoading: boolean;
  isError: boolean;
  isCompleted: boolean;
} => {
  const [isCompleted, setIsCompleted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isError, setIsError] = useState(false);

  const handleDeleteHub = (deviceId: string) => {
    (async () => {
      setIsCompleted(false);
      setIsLoading(true);
      setIsError(false);

      try {
        const idToken = AuthService.getIdToken();

        await Axios.delete(`${REACT_APP_API_ENDPOINT}/devices/${deviceId}`, {
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${idToken}`,
          },
        });

        setIsCompleted(true);
      } catch (e) {
        setIsError(true);
      }

      setIsLoading(false);
    })();
  };

  return { handleDeleteHub, isLoading, isError, isCompleted };
};
