import React from 'react';
import List from '@material-ui/core/List';

import AuthService from '../../utils/AuthService';
import FaqListItem from '../molecules/FaqListItem';

const FaqList: React.FC = () => {
  const loginSettingsLink = AuthService.getLoginSettingsLink();
  const unSubscribeLink = AuthService.getUnSubscribeLink();

  return (
    <List disablePadding style={{ marginTop: '-0.75rem' }}>
      <FaqListItem
        title="クラウドサービスとはなんですか？"
        body="IoT機能を持つ住宅設備機器をクラウドを使って連携するサービスです。<br />
              スマートスピーカーとの連携や設置いただいた宅配ボックスの情報を、宅配事業者を含めた社会サービスを提供する方々、および登録していただいた個人に対し、社会サービスと連携するあずけるボックスサービスとして提供します。"
      />
      <FaqListItem
        title="連携する機器はスマート宅配ポストだけですか？"
        body="スマート宅配ポスト以外の住宅設備機器についても今後対応していきます。"
      />
      <FaqListItem
        title="誰が使うことが出来ますか？"
        body="宅配ボックスを設置した住宅にお住まいの本人及び同居の方（お客様とします）、本人または同居の方が許可した社会サービス提供者および個人の方が使うことが出来ます。"
      />
      <FaqListItem
        title="あずけるBOXのサービスでは具体的に何ができますか？"
        body="
            <ul>
              <li>宅配ボックスを使って、友人やご近所さんともののやり取りが可能です。</li>
              <li>お客様が複数のものをより便利に受け取ることを可能にします。</li>
              <li>お客様が宅配ボックスから直接発送することを可能にします。</li>
              <li>宅配事業者などの社会サービス提供者が宅配ボックスが空いているかどうかの情報をもとに、配送日時を調整できるようにします。</li>
              <li>宅配事業者などの社会サービス提供者において、配達伝票の受領印を紙管理から電子化することができるようにします。</li>
              <li>この他お客様のさらなる利便性の向上と社会問題となっている宅配事業者の負担軽減ができる機能を準備していきます。</li>
            </ul>
          "
      />
      <FaqListItem
        title="個人情報はどうなりますか？"
        body="お預かりする個人情報は、LIXILプライバシーポリシーに則って管理、運用します。<br />
              プライバシーポリシーについての詳細は、プライバシーポリシーを確認してください。"
      />
      <FaqListItem
        title="どのような情報が送られますか？"
        body="
            <ul>
              <li>各機器の作動情報の通知</li>
              <li>各機器の操作</li>
              <li>宅配ポストの記録映像</li>
              <li>スマートフォンのGPS情報<br />※GPS情報は、該当機器の盗難検知や機器連動の検出に使用します。</li>
            </ul>
          "
      />
      <FaqListItem
        title="退会するにはどうすればよいですか？"
        body={`画面下、または横の<a href="/settings">「その他」</a>から、<a href="${unSubscribeLink}">「退会」</a>をクリックして手続きしてください。退会後、スマホの「ホームシステム」アプリで、「クラウドサービスを利用する」をオフにしてください。`}
      />
      <FaqListItem
        title="各サービスはどんな内容ですか？"
        body={
          '<a href="/services">「サービス」</a>から、各サービスのアイコンをタップし、「使い方」ボタンをタップしてサービス内容を確認してください。'
        }
      />
      <FaqListItem
        title="引越して住所が変わったときは何をすればよいですか？"
        body={`お住いのご住所を変更されて、ホームユニットとスマート宅配ポストも移動された場合は、<a href="${loginSettingsLink}">MyLIXILのアカウント情報</a>の変更と、<a href="/devices">「デバイス」</a>から対象のデバイスをタップし、「情報」の設置住所を変更してください。`}
      />
      <FaqListItem
        title="家を売却など、スマート宅配ポストを手放すときはどうすればよいですか？"
        body={
          '登録してあるホームユニットとスマート宅配ポストを削除します。<a href="/devices">「デバイス」</a>から、対象デバイスを選択して「デバイス削除」をタップしてください。退会後、スマホの「ホームシステム」アプリで、「クラウドサービスを利用する」をオフにしてください。'
        }
      />
    </List>
  );
};

export default FaqList;
