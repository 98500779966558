import * as React from 'react';
import { StyleRules, Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Typography from '@material-ui/core/Typography';

const useStyles = makeStyles(
  (theme: Theme): StyleRules => ({
    title: {
      fontWeight: 'bold',
      marginBottom: theme.spacing(2),
    },
  }),
);

const PageTitleBar: React.FC = ({ children }) => {
  const classes = useStyles();

  return (
    <Typography
      variant="h6"
      component="h2"
      color="primary"
      className={classes.title}
    >
      {children}
    </Typography>
  );
};

export default PageTitleBar;
